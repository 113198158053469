import {
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import chroma from 'chroma-js';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetIntervalInMinutes } from '../../../../common/Utility';
import { timeStr } from '../SettingsDialog';
import { Referral, Room, Staff } from '../Types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function InterpretRow(props: {
	doctor: string;
	referrals: Array<{ referral: Referral; room: string }>;
	doctorColors: { [name: string]: string };
	referralTypes: { [name: string]: string };
}) {
	const [open, setOpen] = useState(true);

	const bgColor =
		props.doctorColors[props.doctor] !== undefined
			? props.doctorColors[props.doctor]
			: 'white';

	const color = chroma.contrast(bgColor, '#fff') < 4.5 ? '#000' : '#fff';
	return (
		<>
			<TableRow
				sx={{
					'&:last-child td, &:last-child th': { border: 0 },
					background: bgColor,
					color: color,
				}}
			>
				<TableCell sx={{ color: color }} colSpan={6}>
					<Box sx={{ display: 'flex', gap: '1rem' }}>
						<Typography variant="body2">{props.doctor}</Typography>
						<Typography
							sx={{ fontStyle: 'italic' }}
							variant="body2"
						>{`Antal remisser: ${props.referrals.length}`}</Typography>
						<Typography
							sx={{ fontStyle: 'italic' }}
							variant="body2"
						>{`Total tolkningstid: ${props.referrals.reduce<number>(
							(acc, cur) =>
								acc +
								(cur.referral.interpretDuration
									? cur.referral.interpretDuration
									: 0),
							0
						)} minuter`}</Typography>
					</Box>
				</TableCell>
				<TableCell align="right" sx={{ color: color }}>
					<IconButton onClick={() => setOpen(!open)}>
						<ExpandMoreIcon sx={{ color: color }} />
					</IconButton>
				</TableCell>
			</TableRow>

			{props.referrals.map((referralData) => {
				return (
					<TableRow
						key={referralData.referral.name}
						sx={{
							'&:last-child td, &:last-child th': { border: 0 },
							td: {
								color:
									referralData.referral.priority === 'pr1' ? 'red' : 'inherit',
							},
							opacity:
								GetIntervalInMinutes(
									props.referralTypes[
										referralData.referral.referralType as string
									]
								) === 0
									? 0.6
									: 1,
							display: open ? 'table-row' : 'none',
						}}
					>
						<TableCell>{referralData.referral.referralType}</TableCell>
						<TableCell align="left">
							{timeStr(referralData.referral.start)}
						</TableCell>
						<TableCell align="left">
							{timeStr(
								referralData.referral.start + referralData.referral.duration
							)}
						</TableCell>
						<TableCell align="left">
							{referralData.referral.priority.replace('pr', '')}
						</TableCell>
						<TableCell align="left">{referralData.room}</TableCell>
						<TableCell align="left">
							{referralData.referral.bma?.name}
						</TableCell>
						<TableCell align="right">
							{GetIntervalInMinutes(
								props.referralTypes[
									referralData.referral.referralType as string
								]
							)}
						</TableCell>
					</TableRow>
				);
			})}
		</>
	);
}

export function InterpretPanel(props: {
	children?: ReactNode;
	index: number;
	value: number;
	rooms: Array<Room>;
	referralTypes: { [name: string]: string };
	doctors: Array<Staff>;
	doctorColors: { [name: string]: string };
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const groupedReferrals: {
		[doctorName: string]: Array<{ referral: Referral; room: string }>;
	} = {};

	for (const room of props.rooms) {
		// In this room, get a list of referrals grouped by doctors
		const subgroups = Object.entries(
			room.referrals
				.filter((r) => r.name !== 'Lunch' && r.referralType !== undefined)
				.reduce<{ [doctorName: string]: Array<Referral> }>((acc, cur) => {
					if (cur.doctor !== undefined) {
						if (Object.keys(acc).includes(cur.doctor))
							acc[cur.doctor].push(cur);
						else acc[cur.doctor] = [cur];
					}
					return acc;
				}, {})
		);

		for (const subgroup of subgroups) {
			const f = subgroup[1].map((r) => ({
				referral: r,
				room: room.name,
			}));
			if (groupedReferrals[subgroup[0]] === undefined)
				groupedReferrals[subgroup[0]] = f;
			else groupedReferrals[subgroup[0]].push(...f);
		}
	}

	const doctors = Object.keys(groupedReferrals);
	doctors.forEach((doctorName) =>
		groupedReferrals[doctorName].sort(
			(a, b) => a.referral.start - b.referral.start
		)
	);

	return (
		<Box role="tabpanel" hidden={props.value !== props.index}>
			{props.value === props.index && (
				<TableContainer>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow sx={{ '.MuiTableCell-root': { fontWeight: 'bold' } }}>
								<TableCell>{t('referral')}</TableCell>
								<TableCell>{t('start')}</TableCell>
								<TableCell>{t('end')}</TableCell>
								<TableCell>{t('priority')}</TableCell>
								<TableCell>{t('room')}</TableCell>
								<TableCell>{t('MLS')}</TableCell>
								<TableCell>{t('est. interpretation time (minutes)')}</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{doctors.map((doctorName) => (
								<InterpretRow
									key={doctorName}
									doctor={doctorName}
									referrals={groupedReferrals[doctorName]}
									doctorColors={props.doctorColors}
									referralTypes={props.referralTypes}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	);
}

import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionSummary,
	Box,
	Typography,
	AccordionDetails,
	TextField,
	InputAdornment,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../AppTheme';
import { ReferralType } from './Types';

/**
 * Component draws Accordions and displays Referrals
 * @param props
 * @returns
 */
export function DrawReferralType(props: {
	referralType: ReferralType;
	translationBase: string;
	handleRemoveReferral: (id: number) => void;
	handleEditReferral: (referral: ReferralType) => void;
}) {
	const [referral, setReferral] = useState<ReferralType>({
		...props.referralType,
	});

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});
	const { paletteColors } = useContext(AppThemeContext);

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMore />} sx={{ display: 'flex' }}>
				<Box
					sx={{
						backgroundColor: paletteColors.green.light,
						height: (theme) => theme.spacing(4),
						width: props.referralType.bma,
					}}
				/>
				<Box
					sx={{
						backgroundColor: paletteColors.green.dark,
						height: (theme) => theme.spacing(4),
						width: props.referralType.interpret,
					}}
				/>
				<Typography
					variant="subtitle1"
					sx={{ fontWeight: 700, marginLeft: '1rem' }}
				>
					{props.referralType.abbrev}
				</Typography>
				<Typography
					variant="body2"
					sx={{
						marginLeft: '1rem',
						lineHeight: 1.9,
						color: (t) =>
							t.palette.mode === 'light'
								? t.palette.grey[600]
								: t.palette.grey[300],
					}}
				>{`${t('examination time')}: ${Math.max(
					props.referralType.bma,
					props.referralType.doctor
				)} ${t('minutes')}, ${t('interpreting time')}: ${
					props.referralType.interpret
				} ${t('minutes')}.`}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography variant="h6">{t('update referral type')}</Typography>
				<Box
					component="form"
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(3, 1fr)',
						gap: '1rem',
						marginTop: '0.5rem',
					}}
				>
					<TextField
						value={referral.bma}
						label={t('examination time for mls')}
						onChange={(event) =>
							setReferral({ ...referral, bma: Number(event.target.value) })
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">{t('minutes')}</InputAdornment>
							),
							inputProps: { min: 1 },
						}}
						type="number"
					/>
					<TextField
						value={referral.doctor}
						label={t('examination time for doctor')}
						onChange={(event) =>
							setReferral({ ...referral, doctor: Number(event.target.value) })
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">{t('minutes')}</InputAdornment>
							),
							inputProps: { min: 0 },
						}}
						type="number"
					/>
					<TextField
						value={referral.interpret}
						label={t('interpretation time')}
						onChange={(event) =>
							setReferral({
								...referral,
								interpret: Number(event.target.value),
							})
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">{t('minutes')}</InputAdornment>
							),
							inputProps: { min: 0 },
						}}
						type="number"
					/>
					<TextField
						value={referral.name}
						label={t('name')}
						onChange={(event) =>
							setReferral({
								...referral,
								name: event.target.value,
							})
						}
					/>
					<TextField
						value={referral.abbrev}
						label={t('abbreviation')}
						onChange={(event) =>
							setReferral({
								...referral,
								abbrev: event.target.value,
							})
						}
					/>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<Button
								sx={{ height: '100%' }}
								disabled={
									props.referralType.interpret === referral.interpret &&
									props.referralType.doctor === referral.doctor &&
									props.referralType.bma === referral.bma &&
									props.referralType.abbrev === referral.abbrev &&
									props.referralType.name === referral.name
								}
								onClick={() =>
									props.handleEditReferral({
										...referral,
									})
								}
							>
								{t('update')}
							</Button>
							<Button
								sx={{ height: '100%' }}
								disabled={
									props.referralType.interpret === referral.interpret &&
									props.referralType.doctor === referral.doctor &&
									props.referralType.bma === referral.bma &&
									props.referralType.abbrev === referral.abbrev &&
									props.referralType.name === referral.name
								}
								onClick={() =>
									setReferral({
										...props.referralType,
									})
								}
							>
								{t('reset')}
							</Button>
						</Box>
						<Button
							color="error"
							size="small"
							sx={{ whiteSpace: 'nowrap' }}
							onClick={() => setDeleteDialogOpen(true)}
						>
							{t('remove')}
						</Button>
					</Box>
					<Dialog
						open={deleteDialogOpen}
						onClose={() => setDeleteDialogOpen(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							{`${t('verify removal of referral')}: ${
								props.referralType.abbrev
							}`}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{t('remove referral text')}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={() => setDeleteDialogOpen(false)}>
								{t('cancel')}
							</Button>
							<Button
								variant="contained"
								color="error"
								onClick={() => {
									props.handleRemoveReferral(props.referralType.id);
									setDeleteDialogOpen(false);
								}}
							>
								{t('remove')}
							</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}

import { gql } from '@apollo/client';

export const OP_TEAMS_QUERY = gql`
	{
		op_Team {
			id
			description
			abbrev
			hexcolor
			isoperating
		}
		op_Staff(
			order_by: { name: asc }
			where: { Profession: { abbrev: { _nlike: "st" } } }
		) {
			name
			Profession {
				abbrev
			}
			StaffQualificationTeams {
				teamid
			}
			id
		}
	}
`;

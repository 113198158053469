import { useQuery } from '@apollo/client';
import { alpha, Box, Paper, styled, Tooltip, Typography } from '@mui/material';
import { Fragment, useContext, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../AppTheme';
import { PageBox } from '../../../common/Components';
import { Legend } from '../../../common/Legend';
import { DAILY_PLANNING_ROOM_QUERY } from './Queries';

const RoomContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateRows: 'min-content 1fr',
	gap: '0.5rem',
	marginTop: '1rem',
	textAlign: 'center',
}));

const BMAS = ['Kalle', 'Björn', 'Nicklas'];
const palette = ['#111d4a', '#00B591', '#055E60'];

/**
 * Creates vertical line with hours from 7 to 16
 */
export const TimeLine = () => {
	const HS = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
	const maxHeight = HS.length * 60;
	const axisColor = '#777';
	return (
		<svg
			height={600}
			style={{ overflow: 'visible' }}
			viewBox={`-50 -0.75 55 ${maxHeight}`}
		>
			<line
				x1={0}
				x2={0}
				y1={-0.75}
				y2={HS.length * 60 - 0.75}
				stroke={axisColor}
				strokeWidth={1.5}
			/>
			{HS.map((item, index) => (
				<Fragment key={`${item}-${index}`}>
					<line
						x1={-5}
						x2={0}
						y1={(item - 7) * 60}
						y2={(item - 7) * 60}
						stroke={axisColor}
						strokeWidth={1.5}
					/>
					<text
						fontSize="smaller"
						fill={axisColor}
						textAnchor="end"
						x={-10}
						y={(item - 7) * 60 + 5}
					>{`${item}:00`}</text>
				</Fragment>
			))}
		</svg>
	);
};

/**
 * Draws 1x column container, used for each room.
 */
function RoomBlock(props: { item: ExamBlock }) {
	const ref = useRef(null);
	const { t } = useTranslation();
	const { paletteColors, mode } = useContext(AppThemeContext);

	return (
		<Box sx={{ gridColumn: 1, gridRow: 1 }}>
			<Draggable
				nodeRef={ref}
				defaultPosition={{ x: 0, y: props.item.start }}
				axis="y"
				bounds="parent"
			>
				<Paper
					ref={ref}
					sx={{
						// backgroundColor: 'hsl(132, 70%, 85%)',
						backgroundColor:
							mode === 'light'
								? paletteColors.green.light
								: paletteColors.green.dark,
						border: '1px solid black',
						height: `${props.item.duration}px`,
					}}
				>
					<Tooltip
						arrow
						placement="right-start"
						title={
							<Box>
								<Typography component="table">
									<tbody>
										<tr>
											<td>{t('mls')}</td>
											<td>Kalle Svensson</td>
										</tr>

										<tr>
											<td>{t('patient')}</td>
											<td>Sven Svensson</td>
										</tr>
										<tr>
											<td>{t('social security number')}</td>
											<td>19691203-5617</td>
										</tr>
										<tr>
											<td>{t('type')}</td>
											<td>{props.item.name}</td>
										</tr>
										<tr>
											<td>{t('doctor')}</td>
											<td>Ellen Hansson</td>
										</tr>
										<tr>
											<td>{t('start time')}</td>
											<td>{`${String(
												Math.floor((props.item.start + 7 * 60) / 60)
											).padStart(2, '0')}:${String(
												(props.item.start + 7 * 60) % 60
											).padStart(2, '0')}`}</td>
										</tr>
										<tr>
											<td>{t('estimated time')}</td>
											<td>{props.item.duration} minuter</td>
										</tr>
									</tbody>
								</Typography>
							</Box>
						}
					>
						<Typography
							variant="caption"
							component="div"
							style={{
								float: 'right',
								margin: '0.15rem',
							}}
						>
							{props.item.duration >= 20 && props.item.name}
						</Typography>
					</Tooltip>
				</Paper>
			</Draggable>
		</Box>
	);
}

type ExamBlock = {
	id: number;
	start: number;
	duration: number;
	name: string;
};

type RoomWithBlock = {
	id: number;
	name: string;
	items: Array<ExamBlock>;
};

function DailyPlanningCP(props: { translationBase: string }) {
	const [rooms, setRooms] = useState<Array<RoomWithBlock>>([]);
	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.daily planning`,
	});

	useQuery<{
		room: Array<RoomWithBlock>;
		referraltype: Array<{
			abbrev: string;
			bma: string;
			id: number;
		}>;
	}>(DAILY_PLANNING_ROOM_QUERY, {
		onCompleted: (data) => {
			const roomList: Array<RoomWithBlock> = [];
			let id = 0;
			for (let i = 0; i < data.room.length; i++) {
				const blocks: Array<ExamBlock> = [];
				let offset = 0;
				for (let j = 0; j < Math.max(3, Math.floor(Math.random() * 10)); j++) {
					if (offset > 500) break;
					const BMA =
						data.referraltype[
							Math.floor(Math.random() * data.referraltype.length)
						];

					const parts = BMA.bma.split(':');
					blocks.push({
						id: id,
						name: BMA.abbrev,
						duration: Number(parts[0]) * 60 + Number(parts[1]),
						start: offset,
					});

					offset +=
						blocks[blocks.length - 1].duration + Math.floor(Math.random() * 45);
					offset += 15 - (offset % 15);
				}
				roomList.push({
					...data.room[i],
					items: blocks,
				});
			}

			setRooms(roomList);
		},
		fetchPolicy: 'no-cache',
	});

	return (
		<PageBox pageTitle={t('daily planning')}>
			<RoomContainer
				sx={{
					gridTemplateColumns: `min-content repeat(${rooms.length}, 1fr)`,
				}}
			>
				<Typography /> {/* Need one empty element for the grid */}
				{rooms.map((room) => (
					<Typography key={room.id}>{room.name}</Typography>
				))}
				{TimeLine()}
				{rooms.map((room) => (
					<Box
						key={`container${room.id}`}
						sx={{
							backgroundColor: alpha(
								palette[Math.floor(Math.random() * 3)],
								0.2
							),
							display: 'grid',
						}}
					>
						{room.items.map((item, index) => (
							<RoomBlock key={`${room.id}-${index}`} item={item} />
						))}
					</Box>
				))}
			</RoomContainer>

			<Legend
				items={BMAS.map((item, index) => ({
					name: item,
					color: alpha(palette[index], 0.2),
				}))}
			/>
		</PageBox>
	);
}

export default DailyPlanningCP;

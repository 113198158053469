import {
	Stack,
	Autocomplete,
	InputLabel,
	TextField,
	Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface QualificationsSelectProps {
	values: Array<string>;
	options: Array<string>;
	translationBase: string;
	updateSelection: (newValue: Array<string>) => void;
}

export function QualificationsSelect(props: QualificationsSelectProps) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Stack spacing={1}>
			<Autocomplete
				multiple
				disableCloseOnSelect
				id="multi-select-qualifications"
				options={[...props.options]}
				value={props.values}
				filterSelectedOptions
				onChange={(_, newValue) => props.updateSelection(newValue)}
				renderInput={(params) => (
					<>
						<InputLabel shrink>{t('qualifications list')}</InputLabel>
						<TextField
							{...params}
							variant="outlined"
							placeholder={t('qualifications')}
						/>
					</>
				)}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip label={option} {...getTagProps({ index })} />
					))
				}
				noOptionsText={t('no options')}
			/>
		</Stack>
	);
}

export type QueryType = {
	staff: Array<{
		id: string;
		name: string;
		staffqualifications: Array<{
			referraltype: {
				abbrev: string;
				bma: string;
			};
		}>;
		profession: {
			title: string;
		};
	}>;
	room: Array<{
		id: string;
		name: string;
		abbrev: string;
		roomreferraltypes: Array<{
			referraltype: {
				abbrev: string;
			};
		}>;
	}>;
	referraltype: Array<{
		abbrev: string;
		interpret: string;
	}>;
};

export type TimeSlot = 'MORNING' | 'AFTERNOON' | 'WHOLE_DAY';

export type Staff = {
	id: string;
	name: string;
	referralTypes: Array<string>;
	duration: number; // minutes
	workTime: TimeSlot;
};

export type ReferralType = {
	abbrev: string;
	bma: string;
};

type ReferralPriority = 'pr1' | 'pr2' | 'pr3' | 'pr4' | 'pr5';

export type DBReferral = {
	deadline: Date;
	id: string;
	incoming: Date;
	priority: ReferralPriority;
	referralType: string;
};

export type Referral = {
	name: string;
	referralType?: string;
	start: number;
	duration: number;
	schedulingType: ScheduleType;
	timeSlot?: TimeSlot;
	priority: ReferralPriority;
	deadline: Date;
	interpretDuration?: number;
	doctor?: string;
	bma?: Staff;
	incoming?: Date;
};

export type Room = {
	id: string;
	name: string;
	referralTypes: Array<string>;
	duration: number;
	referrals: Array<Referral>;
	active: boolean;
};

export type Template = {
	id: number;
	emergencySlots: { [roomName: string]: Array<Referral> };
	pastReferrals: { [roomName: string]: Array<Referral> };
	unavailableStaff: Array<string>;
};

export type MKPPayload = {
	bmaStaff: Array<Staff>;
	rooms: Array<Room>;
	referrals: Array<Referral>;
};

export enum ScheduleType {
	BOOKED = 'BOOKED',
	EMERGENCY = 'EMERGENCY',
	UNSCHEDULED = 'UNSCHEDULED',
	SCHEDULED = 'SCHEDULED',
	LUNCH = 'LUNCH',
}

export enum SimulationState {
	ERROR = -1,
	NONE = 0,
	LOADING_SIMULATION = 1,
	FINISHED_LOADING_SIMULATION = 2,
	LOADING_SCHEDULING = 3,
	FINISHED_LOADING_SCHEDULING = 4,
	LOADING_INTERPET = 5,
	FINISHED_LOADING_INTERPET = 6,
}

import { Box, Typography } from '@mui/material';
import chroma from 'chroma-js';
import { useRef } from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { getPercentage, HMToX, palette } from './DailyPlanningSO';
import { DrawingMode, Operation, SchedulingType } from './Types';

export function OperationBlock(props: {
	yPosition: number;
	animating: boolean;
	operation: Operation;
	drawingMode: DrawingMode;
	startPassed: boolean;
	translationBase: string;
	mainTeam: string;
	teamColors: { [name: string]: string };
	enableLunchTeam: boolean;
	parentWidth: number;
	dropHandle: (e: Object, data: DraggableData) => void;
	disabled: boolean;
}) {
	const ref = useRef(null);

	const draw =
		props.drawingMode === DrawingMode.ALWAYS ? true : props.startPassed;

	const blockPercentages = props.operation.teams.map(
		(t) =>
			((HMToX(t.start) - HMToX(props.operation.start)) /
				(HMToX(props.operation.end) - HMToX(props.operation.start))) *
			100
	);

	let gradient = '';

	if (!props.enableLunchTeam && props.operation.teams.length > 0)
		gradient = `linear-gradient(90deg, ${
			props.teamColors[props.mainTeam]
		} 0%, ${props.teamColors[props.mainTeam]} 100%)`;
	else {
		if (props.operation.teams.length === 1)
			gradient = `linear-gradient(90deg, ${
				props.teamColors[props.operation.teams[0].id]
			} 0%, ${props.teamColors[props.operation.teams[0].id]} 100%)`;
		else if (props.operation.teams.length > 1)
			gradient = `linear-gradient(90deg, ${props.operation.teams.reduce(
				(acc, cur, i, arr) =>
					(acc +=
						`${props.teamColors[cur.id]} ${blockPercentages[i]}%` +
						(i !== arr.length - 1
							? `, ${props.teamColors[cur.id]} ${blockPercentages[i + 1]}%, `
							: '')),
				''
			)})`;
	}

	return (
		<Draggable
			nodeRef={ref}
			axis="x"
			bounds="parent"
			disabled={props.disabled}
			position={{
				x: props.parentWidth * getPercentage(HMToX(props.operation.start)),
				y: props.yPosition,
			}}
			onStop={(e, data) => {
				props.dropHandle(e, data);
			}}
		>
			{/* <Tooltip
				
				title={
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'max-content 1fr',
							columnGap: '1rem',
						}}
					>
						<Typography>{t('name')}:</Typography>
						<Typography>{props.operation.name}</Typography>

						<Typography>{t('start')}:</Typography>
						<Typography>{getOffsetTimeStr(props.operation.start)}</Typography>

						<Typography>{t('critical start')}:</Typography>
						<Typography>
							{getOffsetTimeStr(props.operation.roomStart)}
						</Typography>

						<Typography>{t('critical end')}:</Typography>
						<Typography>{getOffsetTimeStr(props.operation.roomEnd)}</Typography>

						<Typography>{t('end')}:</Typography>
						<Typography>{getOffsetTimeStr(props.operation.end)}</Typography>
					</Box>
				}
			> */}
			<Box
				ref={ref}
				sx={{
					userSelect: 'none',
					position: 'absolute',
					borderRadius: '4px',
					my: '7.5px',
					height: 25,
					background:
						draw && gradient !== ''
							? gradient
							: props.operation.schedulingType === SchedulingType.EMERGENCY
							? palette.emergency
							: palette.elective,
					outline: 'gray solid 1px',
					paddingLeft: `${getPercentage(
						HMToX(props.operation.roomStart) - HMToX(props.operation.start),
						false
					)}%`,
					paddingRight: `${getPercentage(
						HMToX(props.operation.end) - HMToX(props.operation.roomEnd),
						false
					)}%`,
					width: `${getPercentage(
						HMToX(props.operation.end) - HMToX(props.operation.start),
						false
					)}%`,
					cursor: props.disabled ? undefined : 'move',
				}}
			>
				<Box
					sx={{
						textAlign: 'center',
						borderRadius: '2px',
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor:
							draw && gradient !== ''
								? 'transparent'
								: props.operation.schedulingType === SchedulingType.EMERGENCY
								? palette.emergencyCritical
								: palette.electiveCritical,

						outline: 'gray solid 1px',
						'.MuiTypography-root': {
							color:
								chroma.contrast(props.teamColors[props.mainTeam], '#fff') < 4.5
									? '#000'
									: '#fff',
						},
					}}
				>
					<Typography>{props.operation.name}</Typography>
				</Box>
			</Box>
			{/* </Tooltip> */}
		</Draggable>
	);
}

import { useQuery } from '@apollo/client';
import {
	Autocomplete,
	Box,
	Chip,
	LinearProgress,
	TextField,
	Typography,
} from '@mui/material';
import { addMonths } from 'date-fns';
import { useState } from 'react';
import { Datum } from '@nivo/line';
import chroma from 'chroma-js';
import { PageBox } from '../../../common/Components';
import { GET_PRIORITIES, GET_REFERRALS_ARRIVAL_AND_DEADLINE } from './Queries';
import ReferralLineGraph from './Graph';
import { useTranslation } from 'react-i18next';

interface PriorityQuery {
	id: number;
	name: string;
}

export interface QueryDataType {
	arrivalDate: string;
	pr1: number;
	pr2: number;
	pr3: number;
	pr4: number;
	pr5: number;
}
interface ReferralOverviewQueryType {
	deadlines: Array<QueryDataType>;
	arrivals: Array<QueryDataType>;
}
export interface GraphDataInterface {
	id: string;
	data: Array<Datum>;
}

export interface GraphState {
	arrivals: Array<GraphDataInterface>;
	deadlines: Array<GraphDataInterface>;
}

const PRIORITY_TRANSLATION = ['pr1', 'pr2', 'pr3', 'pr4', 'pr5'];

const a_to = new Date();
const a_from = addMonths(a_to, -1);
const d_to = addMonths(a_to, 1);
const d_from = a_to;

const graphPalette = ['#92140C', '#EF522E', '#00B591', '#ffcf99', '#111d4a'];

const LoadingBar = () => {
	return (
		<Box sx={{ width: '100%' }}>
			<LinearProgress />
		</Box>
	);
};

/**
 * Converts query data to Graphdata
 * @param data {data: { arrivals: Array<GraphDataInterface>}}
 * @returns
 */
const dataQueryConversion = (
	data: ReferralOverviewQueryType,
	prioritiesNames: Array<PriorityQuery>
): GraphState => {
	return {
		arrivals: PRIORITY_TRANSLATION.map((priority, index) => {
			return {
				id: prioritiesNames[index].name,
				data: data.arrivals.map((item: any) => ({
					x: item.date,
					y: item[priority],
				})),
			};
		}),
		deadlines: PRIORITY_TRANSLATION.map((priority, index) => {
			return {
				id: prioritiesNames[index].name,
				data: data.deadlines.map((item: any) => ({
					x: item.date,
					y: item[priority],
				})),
			};
		}),
	};
};

function ReferralOverviewCP(props: { translationBase: string }) {
	const [graphData, setGraphData] = useState<GraphState>({
		arrivals: [],
		deadlines: [],
	});

	const [userSelectedPriorities, setUserSelectedPriorities] = useState<
		Array<{ id: number; label: string }>
	>([]);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.referral overview`,
	});

	const { data: prioritiesDataFromQuery } = useQuery<{
		priority: Array<PriorityQuery>;
	}>(GET_PRIORITIES, {
		onCompleted: (data) => {
			setUserSelectedPriorities(
				data.priority.map((item) => ({ id: item.id, label: item.name }))
			);
		},
	});

	useQuery<ReferralOverviewQueryType>(GET_REFERRALS_ARRIVAL_AND_DEADLINE, {
		variables: {
			a_from: a_from,
			a_to: a_to,
			d_from: d_from,
			d_to: d_to,
		},
		skip: !prioritiesDataFromQuery, // depends on priorityQuery
		onCompleted: (data) => {
			if (prioritiesDataFromQuery) {
				const gd = dataQueryConversion(data, prioritiesDataFromQuery.priority);
				setGraphData(gd);
			}
		},
	});

	return (
		<PageBox pageTitle={t('referral overview')}>
			<Box>
				{prioritiesDataFromQuery &&
				prioritiesDataFromQuery?.priority.length > 0 ? (
					<Autocomplete
						multiple
						options={prioritiesDataFromQuery.priority.map((item) => ({
							id: item.id,
							label: item.name,
						}))}
						value={userSelectedPriorities}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						onChange={(_, newValue) =>
							setUserSelectedPriorities(
								newValue.sort((a, b) => (a.id < b.id ? -1 : 1))
							)
						}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									sx={{
										backgroundColor: chroma(graphPalette[option.id - 1])
											.alpha(0.8)
											.hex(),
										color:
											chroma.contrast(graphPalette[option.id - 1], '#000') <=
											4.5
												? '#fff'
												: '#000',
									}}
									label={option.label}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								label={t('priorities')}
							/>
						)}
					/>
				) : (
					<LoadingBar />
				)}
				{graphData.arrivals.length > 0 && graphData.deadlines.length > 0 ? (
					<>
						<Typography variant="h6">{t('referrals received')}</Typography>
						<Box
							sx={{
								margin: 'auto',
								width: (t) => t.breakpoints.values.lg,
								height: 300,
							}}
						>
							<ReferralLineGraph
								data={graphData.arrivals.filter((gd) => {
									return userSelectedPriorities
										.map((usp) => usp.label)
										.includes(gd.id);
								})}
								palette={graphPalette}
								translationBase={`${props.translationBase}.referral overview`}
							/>
						</Box>
						<Typography variant="h6">{t('deadlines')}</Typography>
						<Box
							sx={{
								margin: 'auto',
								width: (t) => t.breakpoints.values.lg,
								height: 300,
							}}
						>
							<ReferralLineGraph
								data={graphData.deadlines.filter((gd) => {
									return userSelectedPriorities
										.map((usp) => usp.label)
										.includes(gd.id);
								})}
								palette={graphPalette}
								translationBase={`${props.translationBase}.referral overview`}
							/>
						</Box>
					</>
				) : (
					<LoadingBar />
				)}
			</Box>
		</PageBox>
	);
}

export default ReferralOverviewCP;

import { Warning } from '@mui/icons-material';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { differenceInDays } from 'date-fns';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../../AppTheme';
import { Referral, Room } from '../Types';

const GRAPH_HEIGHT = 430; // Graph height for all graphs on this component.
const WHOLE_DAY_BMA = 420;

function countReferralsByType(
	rooms: Array<Room>,
	groupByProperty: 'bma' | 'referralType' | 'doctor'
) {
	return rooms.reduce<{ [name: string]: number }>((acc, cur) => {
		for (const referral of cur.referrals) {
			const type = referral[groupByProperty];
			if (type !== undefined) {
				if (acc[type.toString()] === undefined) acc[type.toString()] = 0;

				if (groupByProperty === 'bma')
					acc[type.toString()] += referral.duration;
				else if (groupByProperty === 'doctor')
					acc[type.toString()] += referral.interpretDuration as number;
				else acc[type.toString()] += 1;
			}
		}
		return acc;
	}, {});
}

function ReferralDistribution(props: {
	rooms: Array<Room>;
	referralTypes: Array<string>;
	barColor: string;
	translationBase: string;
}) {
	const groupedReferrals = countReferralsByType(props.rooms, 'referralType');

	for (const type of props.referralTypes)
		if (groupedReferrals[type] === undefined) groupedReferrals[type] = 0;

	const graphData = Object.keys(groupedReferrals).map((rt) => ({
		type: rt,
		[rt]: groupedReferrals[rt],
	}));

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Box sx={{ height: `${GRAPH_HEIGHT}px` }}>
			<Typography>{t('exam distribution')}</Typography>
			<ResponsiveBar
				data={graphData}
				keys={props.referralTypes}
				indexBy="type"
				margin={{ top: 35, right: 50, bottom: 150, left: 60 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				borderColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				colors={[props.barColor]}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 45,
				}}
				axisLeft={{
					format: (e) => Math.floor(e) === e && e, // whole numbers only
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: t('quantity'),
					legendPosition: 'middle',
					legendOffset: -40,
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				tooltip={() => <></>}
				label={() => ''}
			/>
		</Box>
	);
}

function ResourceUtilization(props: {
	rooms: Array<Room>;
	doctorColors: { [name: string]: string };
	barColor: string;
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});
	const groupedReferralsByDoctor = countReferralsByType(props.rooms, 'doctor');
	const graphDataDoctor = Object.keys(groupedReferralsByDoctor).map(
		(doctor) => ({
			type: doctor,
			[doctor]: groupedReferralsByDoctor[doctor],
		})
	);

	const bmaGraphData: Array<{ [key: string]: number | string }> = props.rooms
		.flatMap((r) => r.referrals) // all referrals in an array
		.reduce<Array<{ [key: string]: number | string }>>((acc, current) => {
			if (current.bma && current.bma.name) {
				const accIndex = acc.findIndex(
					(item) => Object.keys(item)[0] === current.bma?.name
				);
				if (accIndex === -1)
					acc.push({ [current.bma.name]: 0, type: current.bma.name });
				else {
					acc[accIndex][current.bma.name] =
						Number(acc[accIndex][current.bma.name]) + current.duration;
				}
			}
			return acc;
		}, []);

	const maxValueBMA = bmaGraphData.reduce<number>((acc, current) => {
		const key = Object.values(current)[0];
		if (key > acc) acc = Number(key);
		return acc;
	}, 0);

	return (
		<Box>
			<Typography>{t('resource utilization')}</Typography>
			<Box
				sx={{
					height: `${GRAPH_HEIGHT}px`,
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
				}}
			>
				<Box
					sx={{
						height: 'inherit',
					}}
				>
					<ResponsiveBar
						data={bmaGraphData}
						keys={bmaGraphData.map((item) => Object.keys(item)[0])}
						indexBy="type"
						margin={{ top: 8, right: 50, bottom: 100, left: 60 }}
						padding={0.6}
						valueScale={{ type: 'linear' }}
						colors={[props.barColor]}
						borderColor={{
							from: 'color',
							modifiers: [['darker', 1.6]],
						}}
						maxValue={Math.max(maxValueBMA * 1.1, WHOLE_DAY_BMA * 1.25)}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 45,
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: t('minutes'),
							legendPosition: 'middle',
							legendOffset: -40,
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{
							from: 'color',
							modifiers: [['darker', 1.6]],
						}}
						markers={[
							{
								axis: 'y',
								value: WHOLE_DAY_BMA,
								lineStyle: {
									stroke: '#000',
									strokeDasharray: '10, 5',
									strokeWidth: 1,
								},
								textStyle: {
									fill: '#000',
								},
								legend: `7 ${t('hours').toLowerCase()}`,
							},
						]}
						tooltip={() => <></>}
						label={() => ''}
					/>
				</Box>
				<Box
					sx={{
						height: 'inherit',
					}}
				>
					<ResponsiveBar
						data={graphDataDoctor}
						keys={Object.keys(groupedReferralsByDoctor)}
						indexBy="type"
						margin={{ top: 8, right: 50, bottom: 100, left: 60 }}
						padding={0.6}
						valueScale={{ type: 'linear' }}
						borderColor={{
							from: 'color',
							modifiers: [['darker', 1.6]],
						}}
						colors={(e) => props.doctorColors[e.id]}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 45,
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: t('minutes'),
							legendPosition: 'middle',
							legendOffset: -40,
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{
							from: 'color',
							modifiers: [['darker', 1.6]],
						}}
						markers={[
							{
								axis: 'y',
								value: WHOLE_DAY_BMA,
								lineStyle: {
									stroke: '#000',
									strokeDasharray: '10, 5',
									strokeWidth: 1,
								},
								textStyle: {
									fill: '#000',
								},
								legend: `7 ${t('hours').toLowerCase()}`,
							},
						]}
						tooltip={() => <></>}
						label={() => ''}
					/>
				</Box>
			</Box>
		</Box>
	);
}

/**
 * Draws scheduled text (locale) and a Warning-symbol if deadlineDate is less than now.
 * @param deadlineDate
 * @param scheduled
 */
function ScheduledCell(props: {
	scheduled: Date;
	deadline: Date;
	translationBase: string;
}) {
	const schedDateStr = props.scheduled.toLocaleDateString('sv-SE');
	const deadlineDateStr = props.deadline.toLocaleDateString('sv-SE');
	const warningColor = useTheme().palette.warning;
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
			{props.scheduled.toLocaleDateString()}
			{schedDateStr > deadlineDateStr && (
				<Tooltip
					title={`${differenceInDays(props.scheduled, props.deadline)} ${t(
						'days late'
					).toLowerCase()}`}
				>
					<Warning style={{ color: warningColor.main }} />
				</Tooltip>
			)}
		</Box>
	);
}

function ReferralTable(props: { rooms: Array<Room>; translationBase: string }) {
	let all_refs: Array<Referral> = [];
	props.rooms.forEach((item) => {
		all_refs = all_refs.concat(item.referrals.filter((ref) => ref.bma));
	});

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow sx={{ '.MuiTableCell-root': { fontWeight: 'bold' } }}>
						<TableCell>{t('referral')}</TableCell>
						<TableCell>{t('referral type')}</TableCell>
						<TableCell>{t('incoming')}</TableCell>
						<TableCell>{t('deadline')}</TableCell>
						<TableCell>{t('scheduled')}</TableCell>
						<TableCell>{t('priority')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{all_refs.map((ref) => (
						<TableRow key={ref.name}>
							<TableCell>{ref.name}</TableCell>
							<TableCell>{ref.referralType ? ref.referralType : ''}</TableCell>
							<TableCell>
								{ref.incoming !== undefined
									? ref.incoming.toLocaleDateString()
									: ''}
							</TableCell>
							<TableCell>
								{ref.deadline !== undefined
									? ref.deadline.toLocaleDateString()
									: ''}
							</TableCell>
							<TableCell>
								<ScheduledCell
									scheduled={new Date()}
									deadline={ref.deadline}
									translationBase={props.translationBase}
								/>
							</TableCell>
							<TableCell>{ref.priority.replace('pr', '')}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export function StatisticsPanel(props: {
	children?: ReactNode;
	index: number;
	value: number;
	rooms: Array<Room>;
	referralTypes: Array<string>;
	doctorColors: { [name: string]: string };
	translationBase: string;
}) {
	const barColor = useContext(AppThemeContext).paletteColors.green.light;
	const translationScope = `${props.translationBase}.statistics panel`;
	return (
		<Box role="tabpanel" hidden={props.value !== props.index}>
			{props.value === props.index && (
				<>
					<ResourceUtilization
						rooms={props.rooms}
						doctorColors={props.doctorColors}
						barColor={barColor}
						translationBase={translationScope}
					/>
					<ReferralDistribution
						rooms={props.rooms}
						referralTypes={props.referralTypes}
						barColor={barColor}
						translationBase={translationScope}
					/>
					<ReferralTable
						rooms={props.rooms}
						translationBase={translationScope}
					/>
				</>
			)}
		</Box>
	);
}

import { Staff, Room, Template, Referral, ScheduleType } from './Types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import {
	Autocomplete,
	Chip,
	DialogTitle,
	Divider,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableProps,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoomCalendarPalette } from './Panels/RoomCalendarPanel';

type ScenarioTemplate = {
	name: string;
	emergencySlots: { [roomName: string]: Array<Referral> };
	pastReferrals: { [roomName: string]: Array<Referral> };
	unavailableStaff: Array<string>;
};

const templateData: Array<ScenarioTemplate> = [
	{
		name: 'Standard (tom)',
		emergencySlots: {},
		pastReferrals: {},
		unavailableStaff: [],
	},
	{
		name: '4 veckor+',
		emergencySlots: {
			'Ultraljudsrum 1': [
				{
					name: 'Reserverad 1',
					referralType: 'Ekokardiografi',
					start: 210,
					duration: 60,
					schedulingType: ScheduleType.EMERGENCY,
					priority: 'pr1',
					deadline: new Date(),
					interpretDuration: 15,
					timeSlot: 'MORNING',
				},
			],
			'Ultraljudsrum 2': [
				{
					name: 'Reserverad 2',
					referralType: 'Carotisduplex',
					start: 210,
					duration: 60,
					schedulingType: ScheduleType.EMERGENCY,
					priority: 'pr1',
					deadline: new Date(),
					interpretDuration: 15,
					timeSlot: 'MORNING',
				},
				{
					name: 'Reserverad 3',
					referralType: 'Ekokardiografi',
					start: 390,
					duration: 60,
					schedulingType: ScheduleType.EMERGENCY,
					priority: 'pr1',
					deadline: new Date(),
					interpretDuration: 15,
					timeSlot: 'AFTERNOON',
				},
			],
			Spirometrirum: [
				{
					name: 'Reserverad 4',
					referralType: 'Spirometri',
					start: 330,
					duration: 60,
					schedulingType: ScheduleType.EMERGENCY,
					priority: 'pr1',
					deadline: new Date(),
					interpretDuration: 10,
					timeSlot: 'AFTERNOON',
				},
			],
		},
		pastReferrals: {
			'Ultraljudsrum 1': [
				{
					name: 'Planerad S-Eko-1',
					referralType: 'Stress EKO',
					start: 390,
					duration: 60,
					schedulingType: ScheduleType.BOOKED,
					priority: 'pr5',
					deadline: new Date(),
					interpretDuration: 90,
					timeSlot: 'AFTERNOON',
				},
			],
			Spirometrirum: [
				{
					name: 'Planerad AmB-1',
					referralType: 'Ambulatoriskt Blodtryck',
					start: 180,
					duration: 60,
					schedulingType: ScheduleType.BOOKED,
					priority: 'pr5',
					deadline: new Date(),
					interpretDuration: 15,
					timeSlot: 'MORNING',
				},
			],
		},
		unavailableStaff: ['5', '8'],
	},
];

export function timeStr(min: number): string {
	const paddedStart = 7 * 60 + 30 + min;
	const hours = Math.floor(paddedStart / 60);
	const minutes = paddedStart - hours * 60;
	return `${hours.toString().padStart(2, '0')}:${minutes
		.toString()
		.padStart(2, '0')}`;
}

const SettingsTable = styled(Table)<TableProps>(() => ({
	'& .MuiTableHead-root': {
		backgroundColor: '#666',

		'th.MuiTableCell-root': {
			color: '#fff',
			fontWeight: 600,
		},
	},
}));

export function SettingsDialog(props: {
	open: boolean;
	setOpen: (value: boolean) => void;
	rooms: Array<Room>;
	staff: Array<Staff>;
	selectedTemplate: Template;
	setSelectedTemplate: (template: Template) => void;
	translationBase: string;
	palette: RoomCalendarPalette;
}) {
	const [selectedStaff, setSelectedStaff] = useState<Array<string>>([]);
	const [selectedTemplateId, setSelectedTemplateId] = useState(
		props.selectedTemplate.id
	);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	// Needed to reset internal state when the simulation reset button is pressed
	useEffect(() => {
		setSelectedStaff([...props.selectedTemplate.unavailableStaff]);
		setSelectedTemplateId(props.selectedTemplate.id);
	}, [props.selectedTemplate.unavailableStaff, props.selectedTemplate.id]);

	const getStaffvalues = (ids: Array<string>) => {
		return props.staff
			.filter((item) => ids.includes(item.id))
			.map((staff) => {
				return {
					label: staff.name,
					id: staff.id,
				};
			});
	};

	const reset = () => {
		setSelectedTemplateId(props.selectedTemplate.id);
		setSelectedStaff(props.selectedTemplate.unavailableStaff);
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={props.open}
			onClose={(_event, reason) => {
				if (reason === 'backdropClick' || reason === 'escapeKeyDown') reset();
				props.setOpen(false);
			}}
		>
			<DialogTitle>{t('simulation settings')}</DialogTitle>
			<DialogContent sx={{ minHeight: '50vh' }} dividers>
				<Typography variant="subtitle1">{t('choose template')}</Typography>
				<Autocomplete
					disablePortal
					value={{
						label: templateData[selectedTemplateId].name,
						id: props.selectedTemplate.id,
					}}
					options={templateData.map((data, index) => ({
						label: data.name,
						id: index,
					}))}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					sx={{ margin: (t) => t.spacing(2, 0) }}
					renderInput={(params) => (
						<TextField {...params} label={t('predefined templates')} />
					)}
					onChange={(_e, newValue) => {
						if (newValue !== null) {
							setSelectedTemplateId(newValue.id);
							setSelectedStaff([...templateData[newValue.id].unavailableStaff]);
						}
					}}
				/>
				<Divider />
				<Autocomplete
					multiple
					disablePortal
					defaultValue={getStaffvalues(props.selectedTemplate.unavailableStaff)}
					value={getStaffvalues(selectedStaff)}
					options={props.staff.map((staff) => {
						return {
							label: staff.name,
							id: staff.id,
						};
					})}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					onChange={(_e, newValues) =>
						setSelectedStaff(
							newValues !== null ? newValues.map((val) => val.id) : []
						)
					}
					sx={{ margin: (t) => t.spacing(2, 0) }}
					renderInput={(params) => (
						<TextField label={t('unavailable staff')} {...params} />
					)}
					renderOption={(autoCompleteProps, option) => {
						const staff = props.staff.find((item) => item.id === option.id);
						let chips: Array<JSX.Element> | undefined = undefined;

						if (staff !== undefined)
							chips = staff.referralTypes.map((referral) => (
								<Chip
									key={referral}
									sx={{ margin: '0 0.1rem' }}
									label={referral}
								/>
							));
						return (
							<li
								style={{ justifyContent: 'space-between' }}
								{...autoCompleteProps}
							>
								<Box>{option.label}</Box>
								<Box sx={{ marginLeft: 'auto' }}>{chips}</Box>
							</li>
						);
					}}
				/>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						gap: '0 1rem',
					}}
				>
					<Typography variant="subtitle1" sx={{ borderBottom: 0 }}>
						{t('reserved emergency')}
					</Typography>
					<Typography variant="subtitle1" sx={{ borderBottom: 0 }}>
						{t('reserved referrals')}
					</Typography>
					<TableContainer component={Paper}>
						<SettingsTable>
							<TableHead>
								<TableRow>
									<TableCell>{t('type')}</TableCell>
									<TableCell align="right">{t('room')}</TableCell>
									<TableCell align="right">{t('start')}</TableCell>
									<TableCell align="right">{t('end')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.entries(
									templateData[selectedTemplateId].emergencySlots
								).map((templateData) =>
									templateData[1].map((referral) => {
										return (
											<TableRow
												key={referral.name}
												sx={{ backgroundColor: props.palette.emergency }}
											>
												<TableCell>{referral.referralType}</TableCell>
												<TableCell align="right">{templateData[0]}</TableCell>
												<TableCell align="right">
													{timeStr(referral.start)}
												</TableCell>
												<TableCell align="right">
													{timeStr(referral.start + referral.duration)}
												</TableCell>
											</TableRow>
										);
									})
								)}
							</TableBody>
						</SettingsTable>
					</TableContainer>

					<TableContainer component={Paper} sx={{ height: 'inherit' }}>
						<SettingsTable>
							<TableHead>
								<TableRow>
									<TableCell>{t('type')}</TableCell>
									<TableCell align="right">{t('room')}</TableCell>
									<TableCell align="right">{t('start')}</TableCell>
									<TableCell align="right">{t('end')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.entries(
									templateData[selectedTemplateId].pastReferrals
								).map((templateData) =>
									templateData[1].map((referral) => {
										return (
											<TableRow
												key={referral.name}
												sx={{ backgroundColor: props.palette.emergency }}
											>
												<TableCell>{referral.referralType}</TableCell>
												<TableCell align="right">{templateData[0]}</TableCell>
												<TableCell align="right">
													{timeStr(referral.start)}
												</TableCell>
												<TableCell align="right">
													{timeStr(referral.start + referral.duration)}
												</TableCell>
											</TableRow>
										);
									})
								)}
							</TableBody>
						</SettingsTable>
					</TableContainer>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.setSelectedTemplate({
							id: selectedTemplateId,
							emergencySlots: templateData[selectedTemplateId].emergencySlots,
							pastReferrals: templateData[selectedTemplateId].pastReferrals,
							unavailableStaff: selectedStaff,
						});
						props.setOpen(false);
					}}
				>
					{t('confirm')}
				</Button>
				<Button
					onClick={() => {
						reset();
						props.setOpen(false);
					}}
				>
					{t('close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

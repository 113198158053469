import { useLazyQuery, useQuery } from '@apollo/client';
import {
	Autocomplete,
	Box,
	Chip,
	FormControlLabel,
	Switch,
	TextField,
	useTheme,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { startOfWeek, endOfWeek } from 'date-fns';
import svSE from 'date-fns/locale/sv';
import enUS from 'date-fns/locale/en-US';
import { PageBox } from '../../../common/Components';
import { padSingleDigit } from '../../../common/Utility';
import { WeekPicker } from '../../../common/Weekpicker';
import { GET_ROOMS, GET_START_TIMES } from './Queries';
import { Room, RelativeBarData } from './Types';
import chroma from 'chroma-js';

export function RelativeGraph(props: {
	isRelative: boolean;
	relativeOffset: number;
	baseOffset: number;
	data: Array<{ [name: string]: string }>;
	colors?: { [name: string]: string };
	keys: Array<string>;
	translateXaxis: boolean;
	maxValue?: number;
	margin?: {
		top: number;
		right: number;
		bottom: number;
		left: number;
	};
	secondMarker?: number;
	noBarLabel?: boolean;
	translationBase: string;
}) {
	const theme = useTheme();
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const combinedOffset =
		props.baseOffset + (props.isRelative ? props.relativeOffset : 0);

	const convertToTime = (val: number): string => {
		const combined = val + combinedOffset;
		const rounded = Math.abs(
			combined > 0 ? Math.floor(combined) : Math.ceil(combined)
		);
		const minutes = Math.round(((combined - rounded) * 60) % 60);
		return `${padSingleDigit(rounded)}:${padSingleDigit(Math.abs(minutes))}`;
	};

	let maxValue = 12;
	if (props.maxValue !== undefined) maxValue = props.maxValue;
	else {
		maxValue = 0;
		props.data.forEach((data) => {
			for (let key of Object.keys(data))
				if (key !== 'bar' && Number(data[key]) > maxValue)
					maxValue = Number(data[key]);
		});
	}

	return (
		<ResponsiveBar
			minValue={props.isRelative ? -props.relativeOffset : 0}
			maxValue={
				maxValue -
				(props.baseOffset + (props.isRelative ? 0 : -props.relativeOffset))
			}
			data={props.data.map((dayData) => {
				const copy = { ...dayData };
				for (let prop in copy)
					if (prop === 'bar')
						copy[prop] = props.translateXaxis ? t(copy[prop]) : copy[prop];
					else copy[prop] = (Number(copy[prop]) - combinedOffset).toString();
				return copy;
			})}
			colors={(bar) => {
				if (props.colors) {
					if (bar.id.toString().includes('Hist'))
						return chroma(props.colors[bar.indexValue]).alpha(0.25).hex();
					else return props.colors[bar.indexValue];
				} else {
					const val =
						bar.value === null
							? 0
							: bar.value - (props.isRelative ? 0 : props.relativeOffset);
					return chroma.hsl(val > 0 ? 0 : 200, Math.abs(val) / 2, 0.75).hex();
				}
			}}
			keys={props.keys}
			indexBy="bar"
			margin={props.margin}
			padding={0.2}
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			innerPadding={1}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'start',
				legendPosition: 'middle',
				legendOffset: -60,
				tickValues: 10,
				format: convertToTime,
			}}
			label={props.noBarLabel ? '' : undefined}
			labelSkipWidth={maxValue}
			labelSkipHeight={maxValue}
			labelTextColor={(e) => {
				if (props.colors !== undefined) {
					const light =
						chroma.contrast(props.colors[e.data.indexValue], '#fff') < 4.5;
					const color = chroma(props.colors[e.data.indexValue]);

					return (light ? color.darken(2) : color.brighten(6)).hex();
				}
				return 'black';
			}}
			role="application"
			groupMode="grouped"
			valueFormat={(val) =>
				convertToTime(val - (props.isRelative ? combinedOffset : 0))
			}
			markers={[
				{
					axis: 'y',
					value: props.isRelative ? 0 : props.relativeOffset,
					lineStyle: {
						stroke: theme.palette.mode === 'light' ? '#000' : '#fff',
						strokeDasharray: '10, 5',
						strokeWidth: 1,
					},
					textStyle: {
						fill: theme.palette.mode === 'light' ? '#000' : '#fff',
						pointerEvents: 'none',
					},
					legend: t('guide value'),
					legendOrientation: 'vertical',
				},
				{
					axis: 'y',
					value:
						(props.secondMarker === undefined ? 0 : props.secondMarker) +
						(props.isRelative ? 0 : props.relativeOffset),
					lineStyle:
						props.secondMarker === undefined
							? { stroke: 'transparent' }
							: {
									stroke: theme.palette.mode === 'light' ? '#000' : '#fff',
									strokeDasharray: '10, 5',
									strokeWidth: 1,
							  },
					textStyle: {
						fill:
							props.secondMarker === undefined
								? 'transparent'
								: theme.palette.mode === 'light'
								? '#000'
								: '#fff',
					},
					legendOrientation: 'vertical',
				},
			]}
			tooltipLabel={(label) => label.id.toString()}
			theme={{
				axis: {
					legend: {
						text: {
							fontSize: 14,
							fill: theme.palette.mode === 'light' ? '#000' : '#fff',
						},
					},
					ticks: {
						text: {
							fontSize: 14,
							fill: theme.palette.mode === 'light' ? '#000' : '#fff',
						},
					},
				},
				grid: {
					line: {
						stroke: theme.palette.mode === 'light' ? '#eee' : '#555',
					},
				},
			}}
		/>
	);
}

function StartOverview(props: { translationBase: string }) {
	const [relativeBar, setRelativeBar] = useState(false);
	const [rooms, setRooms] = useState<Array<Room>>([]);
	const [selectedRooms, setSelectedRooms] = useState<Array<Room>>([]);
	const [weekDate, setWeekDate] = useState(new Date());
	const [data, setData] = useState<Array<{ [name: string]: string }>>([
		{ bar: 'monday' },
		{ bar: 'tuesday' },
		{ bar: 'wednesday' },
		{ bar: 'thursday' },
		{ bar: 'friday' },
		{ bar: 'saturday' },
		{ bar: 'sunday' },
	]);

	const baseOffset = 7;

	const { t, i18n } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.start overview`,
	});

	const locale = i18n.language === 'sv' ? svSE : enUS;

	useQuery<{ rooms: Array<Room> }>(GET_ROOMS, {
		onCompleted: (data) => {
			setRooms(data.rooms);
			setSelectedRooms(data.rooms);
		},
	});

	useEffect(() => {
		if (selectedRooms.length > 0) {
			const start = startOfWeek(weekDate, { locale: locale });
			const end = endOfWeek(weekDate, { locale: locale });

			CallGetStartTimes({
				variables: {
					rooms: selectedRooms.map((room) => room.id),
					from: new Date(
						start.getTime() - start.getTimezoneOffset() * 60000
					).toISOString(),
					to: new Date(
						end.getTime() - end.getTimezoneOffset() * 60000
					).toISOString(),
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRooms, weekDate]);

	const [CallGetStartTimes] = useLazyQuery<{
		startTimes: Array<{ id: number; roomId: number; start: string }>;
	}>(GET_START_TIMES, {
		onCompleted: (data) => {
			// First group times by day
			const newWeekData: RelativeBarData = {
				0: [],
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
			};

			for (const startTime of data.startTimes) {
				const d = new Date(startTime.start);
				newWeekData[d.getDay()].push({
					id: startTime.id,
					barId: startTime.roomId,
					start: d,
				});
			}

			const graphData: Array<{ [name: string]: string }> = [
				{ bar: 'monday' },
				{ bar: 'tuesday' },
				{ bar: 'wednesday' },
				{ bar: 'thursday' },
				{ bar: 'friday' },
				{ bar: 'saturday' },
				{ bar: 'sunday' },
			];

			// Then save the grouped times
			for (let day = 0; day < 7; day++) {
				const times = newWeekData[day];
				for (let i = 0; i < times.length; i++) {
					const roomName = rooms.find(
						(room) => room.id === times[i].barId
					)?.name;
					if (roomName !== undefined)
						graphData[day][roomName] = (
							times[i].start.getHours() +
							times[i].start.getMinutes() / 60 +
							times[i].start.getTimezoneOffset() / 60
						).toString();
				}
			}
			setData(graphData);
		},
	});

	return (
		<PageBox pageTitle={t('start overview')}>
			<Box sx={{ display: 'grid', gridTemplateColumns: 'max-content 1fr' }}>
				<FormControlLabel
					control={
						<Switch
							checked={relativeBar}
							onClick={() => setRelativeBar(!relativeBar)}
						/>
					}
					label={t('relative view').toString()}
					sx={{ pt: (t) => t.spacing(2) }}
				/>
				<Autocomplete
					multiple
					options={rooms}
					getOptionLabel={(option) => option.name}
					value={selectedRooms}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					onChange={(_, newlySelectedRooms) =>
						setSelectedRooms(newlySelectedRooms)
					}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip label={option.name} {...getTagProps({ index })} />
						))
					}
					renderInput={(params) => (
						<TextField
							variant="outlined"
							{...params}
							label={t('choose rooms')}
						/>
					)}
					sx={{ mt: (t) => t.spacing(2) }}
				/>
			</Box>

			<WeekPicker
				weekDate={weekDate}
				onChange={(newDate) => {
					if (newDate !== null) setWeekDate(newDate);
				}}
				locale={locale}
				sx={{
					mx: 'auto',
					width: 'max-content',
					mt: '1rem',
				}}
			/>

			<Box sx={{ height: 500 }}>
				<RelativeGraph
					baseOffset={baseOffset}
					relativeOffset={1.5}
					isRelative={relativeBar}
					data={data}
					keys={selectedRooms.map((room) => room.name)}
					translateXaxis={true}
					maxValue={12}
					margin={{ top: 50, right: 50, bottom: 50, left: 75 }}
					translationBase={`${props.translationBase}.start overview`}
				/>
			</Box>
		</PageBox>
	);
}

export default StartOverview;

import {
	Autocomplete,
	Box,
	Chip,
	SxProps,
	TextField,
	Theme,
	Typography,
} from '@mui/material';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { Staff } from './Types';
interface StaffSelectorProps {
	availableStaff: Array<Staff>;
	unavailableStaff: Array<string>;
	selectedStaff: Array<Staff>;
	setSelectedStaff: (arr: Array<Staff>) => void;
	sx?: SxProps<Theme>;
	translationBase: string;
	titleTranslation: string;
	labelTranslation: string;
	colors?: string[];
}

/**
 * StaffSelector Multiselect componen for selection of Staff
 * @param props
 * @returns
 */
export default function StaffSelector(props: StaffSelectorProps) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Box sx={{ ...props.sx }}>
			<Typography variant="subtitle1">
				{t(`${props.titleTranslation}`)}
			</Typography>
			<Autocomplete
				multiple
				options={props.availableStaff.map((item) => ({
					id: item.id,
					label: item.name,
				}))}
				getOptionDisabled={(option) =>
					props.unavailableStaff.includes(option.id)
				}
				value={props.selectedStaff.map((item) => ({
					id: item.id,
					label: item.name,
				}))}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(_, updatedSelectedStaff) => {
					const newStaff = updatedSelectedStaff
						.map((updatedStaff) =>
							props.availableStaff.find((s) => s.id === updatedStaff.id)
						)
						.filter((s) => s !== undefined) as Staff[];
					props.setSelectedStaff(newStaff);
				}}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => {
						const bgColor =
							props.colors && index < props.colors.length
								? props.colors[index]
								: undefined;
						const color = bgColor
							? chroma.contrast(bgColor, '#000') < 4.5
								? '#fff'
								: '#000'
							: '#000';
						return (
							<Chip
								sx={{
									backgroundColor: bgColor,
									color: color,
									'svg.MuiSvgIcon-root': {
										color: color,
										opacity: 0.5,
									},
								}}
								label={option.label}
								{...getTagProps({ index })}
							/>
						);
					})
				}
				renderInput={(params) => (
					<TextField
						variant="outlined"
						{...params}
						placeholder={
							props.selectedStaff.length === 0
								? t(`${props.labelTranslation}`)
								: ''
						}
						sx={{ background: '#fff' }}
					/>
				)}
				renderOption={(autoCompleteProps, option) => {
					const staff = props.availableStaff.find(
						(item) => item.id === option.id
					);
					let chips: Array<JSX.Element> | undefined = undefined;

					if (staff !== undefined)
						chips = staff.referralTypes.map((referral) => (
							<Chip
								key={referral}
								sx={{ margin: '0 0.1rem' }}
								label={referral}
							/>
						));
					return (
						<li
							style={{ justifyContent: 'space-between' }}
							{...autoCompleteProps}
						>
							<Box>{option.label}</Box>
							<Box sx={{ marginLeft: 'auto' }}>{chips}</Box>
						</li>
					);
				}}
				noOptionsText={t('no options')}
			/>
		</Box>
	);
}

import { useEffect, useState, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
	Box,
	CircularProgress,
	Button,
	useTheme,
	Tabs,
	Tab,
} from '@mui/material';
import { GET_SIMULATION_INPUT } from './Queries';
import {
	ScheduleType,
	Staff,
	DBReferral,
	QueryType,
	Referral,
	ReferralType,
	Room,
	Template,
} from './Types';
import StaffSelector from './StaffSelector';
import ReferralSelector from './ReferralSelector';
import RoomCalendarPanel, {
	RoomCalendarPalette,
} from './Panels/RoomCalendarPanel';
import { SettingsDialog } from './SettingsDialog';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import { PageBox } from '../../../common/Components';
import { AppThemeContext } from '../../../../AppTheme';
import { SchedulingDialog } from './SchedulingDialog';
import { GetIntervalInMinutes } from '../../../common/Utility';
import { InterpretPanel } from './Panels/InterpretPanel';
import { StatisticsPanel } from './Panels/StatisticsPanel';
import { StaffViewPanel } from './Panels/StaffViewPanel';

const DEFAULT_DOCTORS = ['Oskar Petersson', 'Sonja Larsson'];
const DEFAULT_ACTIVE_ROOMS = [
	'Ultraljudsrum 1',
	'Ultraljudsrum 2',
	'Sköterskerum 1',
	'Arbetsprovsrum',
];

function SimulationCP(props: { translationBase: string }) {
	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.simulation`,
	});
	const { paletteColors } = useContext(AppThemeContext);
	const theme = useTheme();

	const [availableBMAS, setAvailableBMAS] = useState<Array<Staff>>([]);
	const [selectedBMA, setSelectedBMA] = useState<Array<Staff>>([]);
	const [availableDoctors, setAvailableDoctors] = useState<Array<Staff>>([]);
	const [selectedDoctors, setSelectedDoctors] = useState<Array<Staff>>([]);

	const [filterByDoctors, setFilterByDoctors] = useState(false);
	const [showDoctorInterpret, setShowDoctorInterpret] = useState(true);
	const [selectedTab, setSelectedTab] = useState(0);

	const [allRooms, setAllRooms] = useState<Array<Room>>([]);

	const [DBReferrals, setDBReferrals] = useState<Array<DBReferral>>([]);

	const [staffAvailableReferralTypes, setStaffAvailableReferralTypes] =
		useState<{
			doctor: Array<ReferralType>;
			bma: Array<ReferralType>;
		}>({ doctor: [], bma: [] });

	const [referralTypes, setReferralTypes] = useState<{
		[name: string]: string;
	}>({});

	const [selectedTemplate, setSelectedTemplate] = useState<Template>({
		id: 0,
		unavailableStaff: [],
		emergencySlots: {},
		pastReferrals: {},
	});

	const tm = useContext(AppThemeContext);
	const [schedulingDialogOpen, setSchedulingDialogOpen] = useState(false);
	const [settingDialogOpen, setSettingDialogOpen] = useState(false);

	const palette: RoomCalendarPalette = {
		emergency: '#f0d1d1',
		planned: '#e4e4e4',
		lunch: theme.palette.grey[200],
		scheduled: paletteColors.green.light,
	};

	const doctorPalette = [
		tm.paletteColors.red.light,
		tm.paletteColors.purple.light,
		tm.paletteColors.yellow.light,
		tm.paletteColors.blue.light,
		tm.paletteColors.red.dark,
		tm.paletteColors.purple.dark,
		tm.paletteColors.yellow.dark,
		tm.paletteColors.blue.dark,
	];

	const activeRooms = useMemo(
		() => allRooms.filter((r) => r.active),
		[allRooms]
	);

	useQuery<QueryType>(GET_SIMULATION_INPUT, {
		onCompleted: (data) => {
			// set staff and referral types
			const newBMAAvailableReferralTypes: Array<ReferralType> = [];
			const newDoctorAvailableReferralTypes: Array<ReferralType> = [];
			const newBMAStaffList: Array<Staff> = [];
			const newDoctorStaffList: Array<Staff> = [];

			const lunchBlock: Referral = {
				start: 270,
				duration: 60,
				name: 'Lunch',
				schedulingType: ScheduleType.LUNCH,
				priority: 'pr5',
				deadline: new Date(),
				timeSlot: 'MORNING',
			};

			for (const staff of data.staff) {
				const staffReferralTypes = staff.staffqualifications.map((item) => ({
					abbrev: item.referraltype.abbrev,
					bma: item.referraltype.bma,
				}));

				const newStaff: Staff = {
					id: staff.id,
					name: staff.name,
					referralTypes: staffReferralTypes.map((rt) => rt.abbrev),
					duration: 480,
					workTime: 'WHOLE_DAY',
				};

				if (staff.profession.title === 'BMA') {
					newBMAStaffList.push(newStaff);
					newBMAAvailableReferralTypes.push(
						...staffReferralTypes.filter(
							(item) =>
								!newBMAAvailableReferralTypes
									.map((newItem) => newItem.abbrev)
									.includes(item.abbrev)
						)
					);
				} else {
					newDoctorStaffList.push(newStaff);
					newDoctorAvailableReferralTypes.push(
						...staffReferralTypes.filter(
							(item) =>
								!newDoctorAvailableReferralTypes
									.map((newItem) => newItem.abbrev)
									.includes(item.abbrev)
						)
					);
				}
			}

			const cleanedUpRooms = data.room.map((room) => ({
				id: room.id,
				name: room.name,
				duration: 480,
				referralTypes: room.roomreferraltypes.map(
					(type) => type.referraltype.abbrev
				),
				active: DEFAULT_ACTIVE_ROOMS.includes(room.name),
				referrals: [{ ...lunchBlock }],
			}));

			cleanedUpRooms.sort((a, b) => Number(b.active) - Number(a.active));

			setSelectedBMA([]);
			setAvailableBMAS(newBMAStaffList);
			setSelectedDoctors(
				newDoctorStaffList.filter((doctor) =>
					DEFAULT_DOCTORS.includes(doctor.name)
				)
			);
			setAvailableDoctors(newDoctorStaffList);
			setStaffAvailableReferralTypes({
				doctor: newDoctorAvailableReferralTypes,
				bma: newBMAAvailableReferralTypes,
			});
			setAllRooms([...cleanedUpRooms]);

			let rt: { [name: string]: string } = {};
			data.referraltype.forEach((referralType) => {
				if (!Object.keys(rt).includes(referralType.abbrev))
					rt[referralType.abbrev] = referralType.interpret;
			});
			setReferralTypes(rt);
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		setSelectedBMA(
			selectedBMA.filter(
				(item) => !selectedTemplate.unavailableStaff.includes(item.id)
			)
		);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTemplate]);

	const Reset = () => {
		setSelectedBMA([]);
		setAllRooms(
			allRooms.map((r) => ({
				...r,
				active: DEFAULT_ACTIVE_ROOMS.includes(r.name),
				referrals: r.referrals.filter((ref) => ref.schedulingType === 'LUNCH'), // only keeping Lunches
			}))
		);
		setSelectedTemplate({
			id: 0,
			unavailableStaff: [],
			emergencySlots: {},
			pastReferrals: {},
		});

		setFilterByDoctors(false);
		setShowDoctorInterpret(true);
		setSelectedDoctors(
			availableDoctors.filter((doctor) => DEFAULT_DOCTORS.includes(doctor.name))
		);
		setSelectedTab(0);
	};

	const doctorColors: { [name: string]: string } = {};
	for (let i = 0; i < selectedDoctors.length && i < doctorPalette.length; i++)
		doctorColors[selectedDoctors[i].name] = doctorPalette[i];

	return (
		<PageBox pageTitle={t('simulation')}>
			<ReferralSelector
				referrals={DBReferrals}
				setReferrals={setDBReferrals}
				filterByDoctors={filterByDoctors}
				setFilterByDoctors={(val) => setFilterByDoctors(val)}
				filterByDoctorsCheckboxDisabled={selectedDoctors.length === 0}
				translationBase={`${props.translationBase}.simulation`}
				sx={{ marginTop: (t) => t.spacing(2) }}
			/>

			<StaffSelector
				availableStaff={availableBMAS}
				unavailableStaff={selectedTemplate.unavailableStaff}
				selectedStaff={selectedBMA}
				setSelectedStaff={setSelectedBMA}
				translationBase={`${props.translationBase}.simulation`}
				titleTranslation="choose MLS"
				labelTranslation="MLS staff"
				sx={{ my: (t) => t.spacing(2) }}
			/>

			<StaffSelector
				availableStaff={availableDoctors}
				unavailableStaff={[]}
				selectedStaff={selectedDoctors}
				setSelectedStaff={setSelectedDoctors}
				translationBase={`${props.translationBase}.simulation`}
				titleTranslation="choose doctors"
				labelTranslation="doctor staff"
				colors={showDoctorInterpret ? doctorPalette : []}
			/>

			<Tabs
				value={selectedTab}
				onChange={(_e, newValue) => setSelectedTab(Number(newValue))}
				sx={{ mt: (t) => t.spacing(2) }}
				TabIndicatorProps={{
					sx: { background: 'white', height: '0px' },
				}}
			>
				<Tab
					disableRipple
					sx={{
						background: 'white',
						borderTopLeftRadius: '4px',
						border: '1px solid rgb(204, 204, 204)',
						borderBottomColor:
							selectedTab === 0 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('room view')}
				/>
				<Tab
					disableRipple
					disabled={allRooms.every(
						(r) =>
							r.active === false ||
							r.referrals.filter((r) => r.schedulingType !== ScheduleType.LUNCH)
								.length === 0
					)}
					sx={{
						background: 'white',
						border: '1px solid rgb(204, 204, 204)',
						borderLeftWidth: '0px',
						borderBottomColor:
							selectedTab === 1 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('interpret view')}
				/>
				<Tab
					disableRipple
					disabled={allRooms.every(
						(r) =>
							r.active === false ||
							r.referrals.filter((r) => r.schedulingType !== ScheduleType.LUNCH)
								.length === 0
					)}
					sx={{
						background: 'white',
						border: '1px solid rgb(204, 204, 204)',
						borderLeftWidth: '0px',
						borderBottomColor:
							selectedTab === 2 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('statistics view')}
				/>
				<Tab
					disableRipple
					disabled={allRooms.every(
						(r) =>
							r.active === false ||
							r.referrals.filter((r) => r.schedulingType !== ScheduleType.LUNCH)
								.length === 0
					)}
					sx={{
						background: 'white',
						border: '1px solid rgb(204, 204, 204)',
						borderLeftWidth: '0px',
						borderBottomColor:
							selectedTab === 3 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('staff view')}
				/>
			</Tabs>

			<Box
				sx={{
					border: '1px solid rgb(204, 204, 204)',
					marginTop: '-1px',
					borderRadius: '4px',
					borderTopLeftRadius: '0px',
					background: 'white',
					p: '1rem 1rem',
				}}
			>
				<RoomCalendarPanel
					updateRooms={setAllRooms}
					allRooms={allRooms}
					translationBase={`${props.translationBase}.simulation`}
					palette={palette}
					doctorColors={doctorColors}
					showDoctorInterpret={showDoctorInterpret}
					setShowDoctorInterpret={(newVal) => setShowDoctorInterpret(newVal)}
					value={selectedTab}
					index={0}
				/>

				<InterpretPanel
					value={selectedTab}
					index={1}
					rooms={activeRooms}
					referralTypes={referralTypes}
					doctors={selectedDoctors}
					doctorColors={doctorColors}
					translationBase={`${props.translationBase}.simulation`}
				/>
				<StatisticsPanel
					value={selectedTab}
					index={2}
					rooms={activeRooms}
					referralTypes={Object.keys(referralTypes)}
					doctorColors={doctorColors}
					translationBase={`${props.translationBase}.simulation`}
				/>
				<StaffViewPanel
					value={selectedTab}
					index={3}
					rooms={activeRooms}
					allBMA={availableBMAS}
					palette={palette}
					translationBase={`${props.translationBase}.simulation`}
				/>
			</Box>
			<Box sx={{ display: 'flex', marginBottom: (t) => t.spacing(4) }}>
				<Box sx={{ margin: 'auto' }}>
					<Button
						disabled={
							selectedBMA.length === 0 &&
							activeRooms.length === 3 &&
							selectedTemplate.id === 0 &&
							selectedTemplate.unavailableStaff.length === 0 &&
							!filterByDoctors &&
							showDoctorInterpret &&
							selectedDoctors.length === 2 &&
							selectedDoctors[0].name === DEFAULT_DOCTORS[0] &&
							selectedDoctors[1].name === DEFAULT_DOCTORS[1]
						}
						onClick={Reset}
					>
						{t('reset')}
					</Button>
					<Button
						disabled={selectedBMA.length === 0 || DBReferrals.length === 0}
						onClick={() => {
							setAllRooms(
								allRooms.map((room) => ({
									...room,
									referrals: room.referrals.filter(
										(r) =>
											r.schedulingType === ScheduleType.BOOKED ||
											r.schedulingType === ScheduleType.EMERGENCY ||
											r.schedulingType === ScheduleType.LUNCH
									),
								}))
							);
							setSchedulingDialogOpen(true);
						}}
					>
						{schedulingDialogOpen && (
							<CircularProgress
								size="1rem"
								sx={{ marginRight: (t) => t.spacing(1) }}
							/>
						)}
						{t('simulate')}
					</Button>
					<SchedulingDialog
						open={schedulingDialogOpen}
						setOpen={() => setSchedulingDialogOpen(false)}
						palette={palette}
						rooms={allRooms}
						setRooms={(rooms) => setAllRooms(rooms)}
						payload={{
							bmaStaff: selectedBMA,
							rooms: activeRooms,
							referrals: DBReferrals.filter(
								(referral) =>
									!filterByDoctors ||
									selectedDoctors.length === 0 ||
									staffAvailableReferralTypes.doctor.some(
										(availableReferral) =>
											availableReferral.abbrev === referral.referralType
									)
							).map((referral) => {
								const type = staffAvailableReferralTypes.bma.find(
									(type) => type.abbrev === referral.referralType
								);

								return {
									name: `${referral.referralType}-${referral.id}`,
									referralType: referral.referralType,
									duration:
										type !== undefined ? GetIntervalInMinutes(type.bma) : 0,
									schedulingType: ScheduleType.UNSCHEDULED,
									priority: referral.priority,
									deadline: referral.deadline,
									incoming: referral.incoming,
									start: 0,
									interpretDuration:
										referralTypes[referral.referralType] !== undefined
											? GetIntervalInMinutes(
													referralTypes[referral.referralType]
											  )
											: undefined,
								};
							}),
						}}
						selectedDoctors={selectedDoctors}
						translationBase={`${props.translationBase}.simulation`}
					/>
					<Button
						sx={{ padding: 0, minWidth: 'initial' }}
						variant="text"
						onClick={() => setSettingDialogOpen(true)}
					>
						<SettingsIcon sx={{ fontSize: '1.5rem' }} />
					</Button>
					<SettingsDialog
						open={settingDialogOpen}
						setOpen={setSettingDialogOpen}
						rooms={allRooms}
						staff={availableBMAS}
						selectedTemplate={selectedTemplate}
						setSelectedTemplate={setSelectedTemplate}
						translationBase={`${props.translationBase}.simulation`}
						palette={palette}
					/>
				</Box>
			</Box>
		</PageBox>
	);
}

export default SimulationCP;

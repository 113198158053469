import { Box, BoxProps, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface MainBoxWTitleProp extends BoxProps {
	pageTitle: string;
	rightContent?: ReactNode;
}

export const PageBox = styled((props: MainBoxWTitleProp) => (
	<Box
		sx={{ marginTop: (t) => t.spacing(16), paddingBottom: (t) => t.spacing(8) }}
		{...props}
	>
		<Box sx={{ display: 'grid', gridTemplateColumns: 'max-content 1fr' }}>
			<Typography gutterBottom variant="h4">
				{props.pageTitle}
			</Typography>
			<Box sx={{ ml: 'auto' }}>{props.rightContent}</Box>
		</Box>
		{props.children}
	</Box>
))(({ theme }) => ({
	marginBottom: theme.spacing(4),
}));

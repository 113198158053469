import React, { useState, SyntheticEvent, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	Tooltip,
	SelectChangeEvent,
	Rating,
	Box,
} from '@mui/material';
import { WatchLater } from '@mui/icons-material';
import {
	OperationPostOP,
	OperatingUnit,
	OperatingUnits,
	Urgencies,
	Genders,
	Location,
} from './Models';

const reType1 = new RegExp('[A-Z]{2}[0-9]{3}');
const reType2 = new RegExp('[A-Z]{3}[0-9]{2}');

export function OperationDialog(props: {
	open: boolean;
	/** Data sent, in form of OperationCard object. */
	op: OperationPostOP;
	/** Callback functions, that can be used to alter OperationCard that is being sent in. */
	onClose: (newOp: OperationPostOP, update: boolean) => void;
}) {
	const { open, op, onClose } = props;
	const [selections, setSelections] = useState({
		operatingUnit: op.operatingUnit,
		urgency: op.urgency,
		gender: op.gender,
		asa: op.asa,
		name: op.name,
		age: op.age,
		opDuration: op.opDuration,
		postOpDuration: op.postOpDuration,
		action: op.action,
		destinationAfterOp: op.destinationAfterOp,
		destinationAfterPostOp: op.destinationAfterPostOp,
		change: false,
	});

	const [errors, setErrors] = useState<{
		/** A bunch of booleans, true = set error, false = no error */
		age: boolean;
		action: boolean;
		opDuration: boolean;
		postOpDuration: boolean;
	}>({
		/** Initialize to false */
		age: false,
		action: false,
		opDuration: false,
		postOpDuration: false,
	});

	/**
	 * Updates state variables according to event value and key, when user is using Select component.
	 * @param event ChangeEvent
	 * @param key state variable key to change
	 */
	const handleSelectChange = (
		// event: React.ChangeEvent<{ value: unknown }>,
		event: SelectChangeEvent<unknown>,
		key: string
	) => {
		setSelections({
			...selections,
			[key]: event.target.value as number,
			change: true,
		});
	};

	const handleTextFieldChange = (
		event: React.ChangeEvent<{ value: unknown }>,
		key: string
	) => {
		setSelections({
			...selections,
			[key]: event.target.value as string,
			change: true,
		});
	};

	const handleClickStar = (_: React.SyntheticEvent, value: number | null) => {
		if (value && value !== selections.asa) {
			setSelections({ ...selections, asa: value, change: true });
		}
	};

	/**
	 * This functions reads the new `age` value and validates the number
	 * sets the errors state variables to true if `age` does not pass the validation, true otherwise
	 * @param event SyntheticEvent
	 * @param key string which error key to update
	 */
	const onChangeNumber = (event: SyntheticEvent, key: string) => {
		const vValue: string = (event.target as HTMLInputElement).value;
		if (vValue === '') setErrors({ ...errors, [key]: true });
		else {
			if (parseInt(vValue) < 0) setErrors({ ...errors, [key]: true });
			else {
				setErrors({ ...errors, [key]: false });
				setSelections({ ...selections, [key]: Number(vValue), change: true });
			}
		}
	};

	/**
	 * Validates the action code. Sets error state-variables
	 * @param event SyntheticEvent
	 */
	const onChangeActionCode = (event: SyntheticEvent) => {
		const vValue: string = (event.target as HTMLInputElement).value;

		if (vValue.search(reType1) === 0 || vValue.search(reType2) === 0) {
			setErrors({ ...errors, action: false });
			setSelections({ ...selections, action: vValue });
		} else setErrors({ ...errors, action: true });
	};

	/**
	 * useEffect to copy props.op to selections. This needs to be done every time the window "opens".
	 */
	useEffect(() => {
		setSelections({
			operatingUnit: op.operatingUnit,
			urgency: op.urgency,
			gender: op.gender,
			asa: op.asa,
			name: op.name,
			age: op.age,
			opDuration: op.opDuration,
			postOpDuration: op.postOpDuration,
			action: op.action,
			destinationAfterOp: op.destinationAfterOp,
			destinationAfterPostOp: op.destinationAfterPostOp,
			change: false,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<Dialog onClose={() => onClose(op, false)} open={open}>
			<DialogTitle>Ändra operation</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Här kan du granska och ändra operations- och patientdata.
				</DialogContentText>
				<form
					className="dialog-content-grid"
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						gap: '1rem',
					}}
				>
					<Box>
						<InputLabel>Operations ID</InputLabel>
						<TextField value={op.id} disabled fullWidth />
					</Box>

					<Box>
						<InputLabel>Patientnamn</InputLabel>
						<TextField
							value={selections.name}
							fullWidth
							onChange={(e) => handleTextFieldChange(e, 'name')}
						/>
					</Box>

					<Box>
						<InputLabel>Patientålder</InputLabel>
						<TextField
							id="age"
							value={selections.age}
							type="number"
							fullWidth
							onChange={(e) => onChangeNumber(e, 'age')}
							error={errors.age}
							inputProps={{ min: 0, max: 200 }}
						/>
					</Box>

					<Box>
						<InputLabel id="operating-gender-input">Patientkön</InputLabel>
						<Select
							labelId="operating-gender-input"
							fullWidth
							value={selections.gender}
							onChange={(e) => handleSelectChange(e, 'gender')}
						>
							{Genders.map((label, index) => (
								<MenuItem key={`g-${index}`} value={index}>
									{label}
								</MenuItem>
							))}
						</Select>
					</Box>

					<Box>
						<InputLabel id="ASA">ASA klassificering</InputLabel>
						<Rating
							name="simple-controlled"
							value={selections.asa}
							onChange={handleClickStar}
						/>
					</Box>

					<Box>
						<InputLabel>Åtgärdskod</InputLabel>
						<TextField
							value={selections.action}
							fullWidth
							onChange={onChangeActionCode}
							error={errors.action}
							onInput={(e) => {
								(e.target as HTMLInputElement).value = (
									e.target as HTMLInputElement
								).value.slice(0, 5);
							}}
						/>
					</Box>

					<Box>
						<InputLabel id="operating-input">Opererande enhet</InputLabel>
						<Select
							labelId="operating-input"
							value={selections.operatingUnit}
							onChange={(e) => handleSelectChange(e, 'operatingUnit')}
							fullWidth
						>
							{OperatingUnits.map((label, index) => (
								<MenuItem key={`o${index}`} value={index}>
									{label}
								</MenuItem>
							))}
						</Select>
					</Box>
					<Box id="urgency-input">
						<InputLabel id="urgency-id">Angelägenhetsgrad</InputLabel>
						<Select
							labelId="urgency-input"
							value={selections.urgency}
							onChange={(e) => handleSelectChange(e, 'urgency')}
							fullWidth
						>
							{Urgencies.map((label, index) => (
								<MenuItem key={`u${index}`} value={index}>
									{label}
								</MenuItem>
							))}
						</Select>
					</Box>

					<Box>
						<InputLabel>Operationslängd (minuter)</InputLabel>
						<TextField
							type="number"
							value={selections.opDuration}
							fullWidth
							error={errors.opDuration}
							onChange={(e) => onChangeNumber(e, 'opDuration')}
							inputProps={{ min: 0 }}
						/>
						<Tooltip title="Använder maskininlärning för att estimera tidsåtgången">
							<span>
								<Button disabled={true} startIcon={<WatchLater />} size="small">
									Tidsestimera
								</Button>
							</span>
						</Tooltip>
					</Box>

					<Box>
						<InputLabel>Post-Op. tidslängd (minuter)</InputLabel>
						<TextField
							type="number"
							value={selections.postOpDuration}
							fullWidth
							error={errors.postOpDuration}
							onChange={(e) => onChangeNumber(e, 'postOpDuration')}
							inputProps={{ min: 0 }}
						/>

						<Tooltip title="Använder maskininlärning för att estimera tidsåtgången">
							<span>
								<Button disabled={true} startIcon={<WatchLater />} size="small">
									Tidsestimera
								</Button>
							</span>
						</Tooltip>
					</Box>

					<Box>
						<InputLabel id="destination-after-op">
							Destination efter op.
						</InputLabel>
						<Select
							disabled={selections.operatingUnit === OperatingUnit.Underhall}
							labelId="destination-after-op"
							value={selections.destinationAfterOp}
							onChange={(e) => handleSelectChange(e, 'destinationAfterOp')}
							fullWidth
						>
							{Object.keys(Location).map((location, index) => (
								<MenuItem key={`loc-${index}`} value={index}>
									{location}
								</MenuItem>
							))}
						</Select>
					</Box>

					<Box>
						<InputLabel id="destination-after-post-op">
							Destination efter post-op.
						</InputLabel>
						<Select
							disabled={
								selections.destinationAfterOp !== Location.PostOp ||
								selections.operatingUnit === OperatingUnit.Underhall
							}
							labelId="destination-after-post-op"
							value={selections.destinationAfterPostOp}
							onChange={(e) => handleSelectChange(e, 'destinationAfterPostOp')}
							fullWidth
						>
							{Object.keys(Location).map((location, index) => (
								<MenuItem
									key={`loc-${index}`}
									value={index}
									disabled={location === 'Post op'}
								>
									{location}
								</MenuItem>
							))}
						</Select>
					</Box>
				</form>
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={() => onClose(op, false)}>
					Avbryt
				</Button>
				<Button
					color="primary"
					disabled={!selections.change}
					onClick={() =>
						onClose(
							new OperationPostOP(
								op.id,
								selections.action,
								selections.opDuration,
								selections.operatingUnit,
								selections.urgency,
								selections.age,
								selections.asa,
								op.opStart,
								op.opEnd,
								selections.postOpDuration,
								selections.gender,
								selections.name,
								selections.destinationAfterOp,
								selections.destinationAfterPostOp
							),
							true
						)
					}
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}

import { ResponsiveSankey } from '@nivo/sankey';
import { Links, Location, OperatingUnit, OperationPostOP } from './Models';

export const PatientFlowGraph = (props: {
	data: Array<OperationPostOP>;
	palette: Array<string>;
}) => {
	const data: { nodes: Array<{ id: string }>; links: Array<Links> } = {
		nodes: [{ id: 'Operation' }],
		links: [],
	};

	// Enum key as value and value as key
	const locationNamesDict: { [name: string]: string } = {};
	Object.entries(Location).forEach((locData) => {
		if (!Object.keys(locationNamesDict).includes(locData[1]))
			locationNamesDict[locData[1]] = locData[0];
	});

	/** Creating links from props.data */
	const linksAfterOp: Array<Links> = [];
	props.data
		.filter((op) => op.operatingUnit !== OperatingUnit.Underhall)
		.forEach((op) => {
			const index = linksAfterOp.findIndex(
				(item) => item.target === locationNamesDict[op.destinationAfterOp]
			);

			if (index !== -1) linksAfterOp[index].value += 1;
			else
				linksAfterOp.push({
					source: 'Operation',
					target: locationNamesDict[op.destinationAfterOp],
					value: 1,
				});

			if (
				data.nodes.findIndex(
					(n) => n.id === locationNamesDict[op.destinationAfterOp]
				) === -1
			)
				data.nodes.push({ id: locationNamesDict[op.destinationAfterOp] });
		});

	const linksAfterPostOp: Array<Links> = [];
	props.data
		.filter((op) => op.operatingUnit !== OperatingUnit.Underhall)
		.forEach((op) => {
			if (op.destinationAfterOp === Location.PostOp) {
				const index = linksAfterPostOp.findIndex(
					(item) => item.target === locationNamesDict[op.destinationAfterPostOp]
				);

				if (index >= 0) linksAfterPostOp[index].value += 1;
				else
					linksAfterPostOp.push({
						source: locationNamesDict[Location.PostOp],
						target: locationNamesDict[op.destinationAfterPostOp],
						value: 1,
					});
				if (
					data.nodes.findIndex(
						(n) => n.id === locationNamesDict[op.destinationAfterPostOp]
					) === -1
				)
					data.nodes.push({ id: locationNamesDict[op.destinationAfterPostOp] });
			}
		});

	data.links = [...linksAfterOp, ...linksAfterPostOp];

	return (
		<ResponsiveSankey
			data={data}
			margin={{ top: 10, right: 160, bottom: 10, left: 160 }}
			align="justify"
			colors={props.palette}
			nodeOpacity={1}
			nodeThickness={18}
			nodeInnerPadding={3}
			nodeSpacing={24}
			nodeBorderWidth={0}
			nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
			linkOpacity={0.7}
			linkHoverOpacity={1}
			linkHoverOthersOpacity={0.1}
			linkBlendMode="darken"
			enableLinkGradient={true}
			labelPosition="outside"
			labelOrientation="horizontal"
			labelPadding={4}
			labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
			theme={{
				tooltip: {
					container: {
						background: '#333',
						color: '#fff',
						fontSize: 'inherit',
						borderRadius: '2px',
						boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
						padding: '5px 9px',
					},
				},
				axis: {
					ticks: {
						text: {
							color: '#eee',
							fontSize: 14,
						},
					},
					legend: {
						text: {
							color: '#eee',
							fontSize: 14,
						},
					},
				},
			}}
			animate={true}
		/>
	);
};

import { styled, alpha } from '@mui/material/styles';
import {
	Box,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	InputBase,
	FormGroup,
	FormControlLabel,
	Checkbox,
	FormControl,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { PageBox } from '../../../common/Components';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	GET_WORK_SCHEDULE,
	STAFF_CREATE,
	STAFF_DELETE,
	STAFF_EDIT,
	STAFF_QUERY,
} from './Queries';
import { QualificationsSelect } from './SharedComponents';
import { StaffTableRow } from './StaffRow';
import { useTranslation } from 'react-i18next';
import { StaffQueryType, Staff } from './Types';

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));

const tableFilterFunction = (
	allStaff: Array<Staff>,
	professionFilter: Array<number>,
	referralsSelected: Array<number>,
	searchString: string
) => {
	const res = allStaff.filter(
		(s) =>
			s.name.toLowerCase().includes(searchString.toLowerCase()) &&
			professionFilter.includes(s.professionid) &&
			referralsSelected.every((selectedReferral) =>
				s.qualifications.includes(selectedReferral)
			)
	);

	return res;
};

export type staffworkData = {
	start: Date;
	end: Date;
};

function AddUserDialog(props: {
	addUser: (newStaff: Staff) => void;
	open: boolean;
	setOpen: (open: boolean) => void;
	professionOptions: Array<{ id: number; title: string }>;
	qualificationOptions: Array<{ abbrev: string; id: number }>;
	translationBase: string;
}) {
	const [newStaff, setNewStaff] = useState<Staff>({
		id: -1,
		name: '',
		abbrev: '',
		professionid: -1,
		qualifications: [],
		service: 0,
	});

	useEffect(() => {
		if (props.open)
			setNewStaff({
				id: -1,
				name: '',
				abbrev: '',
				qualifications: [],
				professionid: -1,
				service: 0,
			});
	}, [props.open]);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog
			open={props.open}
			onClose={() => props.setOpen(false)}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>{t('add new staff')}</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					autoFocus
					margin="dense"
					label={t('name')}
					variant="standard"
					sx={{ mb: '1rem' }}
					onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
				/>
				<TextField
					fullWidth
					margin="dense"
					label={t('abbreviation')}
					variant="standard"
					sx={{ mb: '1rem' }}
					onChange={(e) => setNewStaff({ ...newStaff, abbrev: e.target.value })}
					inputProps={{ maxLength: 8 }}
				/>
				<FormControl variant="standard" sx={{ mb: '1rem' }} fullWidth>
					<InputLabel>{t('profession')}</InputLabel>
					<Select
						onChange={(event) =>
							setNewStaff({
								...newStaff,
								professionid: event.target.value as number,
							})
						}
						value={newStaff.professionid}
					>
						<MenuItem value={-1}>{t('choose a profession')}</MenuItem>
						{props.professionOptions.map((profession) => {
							return (
								<MenuItem key={profession.id} value={profession.id}>
									{t(profession.title)}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<QualificationsSelect
					options={props.qualificationOptions.map((q) => q.abbrev)}
					values={
						newStaff.qualifications
							.map(
								(q) =>
									props.qualificationOptions.find((qo) => qo.id === q)?.abbrev
							)
							.filter((q) => q !== undefined) as string[]
					}
					translationBase={props.translationBase}
					updateSelection={(newValues) =>
						setNewStaff({
							...newStaff,
							qualifications: newValues
								.map(
									(newValue) =>
										props.qualificationOptions.find(
											(q) => q.abbrev === newValue
										)?.id
								)
								.filter((newValue) => newValue !== undefined) as number[],
						})
					}
				/>
				<Typography variant="body2">{t('degree of service')}</Typography>
				<Box sx={{ mx: '0.125rem' }}>
					<Slider
						marks={[0, 25, 50, 75, 100].map((val) => ({
							value: val,
							label: `${val}%`,
						}))}
						value={newStaff.service}
						step={null}
						onChange={(_, value) => {
							if (typeof value === 'number')
								setNewStaff({ ...newStaff, service: value });
						}}
						valueLabelDisplay="auto"
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.setOpen(false)}>{t('cancel')}</Button>
				<Button
					disabled={
						newStaff.name === '' ||
						newStaff.abbrev === '' ||
						newStaff.professionid === -1 ||
						newStaff.qualifications.length === 0
					}
					onClick={() => {
						props.addUser(newStaff);
						props.setOpen(false);
					}}
				>
					{t('add new staff')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function StaffCP(props: { translationBase: string }) {
	const [staff, setStaff] = useState<Array<Staff>>([]);
	const [professions, setProfessions] = useState<
		Array<{ id: number; title: string }>
	>([]);
	const [referralTypes, setReferralTypes] = useState<
		Array<{ id: number; abbrev: string }>
	>([]);

	const [searchString, setSearchString] = useState<string>('');
	const [referralsSelected, setReferralsSelected] = useState<Array<number>>([]);
	const [professionSelected, setProfessionSelected] = useState<Array<number>>(
		[]
	);

	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.staff`,
	});

	useQuery<StaffQueryType>(STAFF_QUERY, {
		onCompleted: (data) => {
			const newStaffList: Array<Staff> = [];

			for (const staff of data.staff) {
				const newStaff = { ...staff };

				newStaff.qualifications = data.staffqualifications
					.filter((qualification) => qualification.staffid === staff.id)
					.map((q) => q.referraltypeid);

				newStaffList.push(newStaff);
			}
			setStaff(newStaffList);
			setProfessions(data.profession);
			setProfessionSelected(data.profession.map((p) => p.id));
			setReferralTypes(data.referraltype);
		},
		fetchPolicy: 'no-cache',
	});

	const [updateStaffQuery] = useMutation<{
		update_staff_by_pk: Staff;
		delete_staffqualifications: {
			returning: Array<{
				referraltypeid: number;
			}>;
		};
		insert_staffqualifications: {
			returning: Array<{
				referraltypeid: number;
			}>;
		};
	}>(STAFF_EDIT, {
		onCompleted: (data) => {
			const currentStaff = [...staff];
			const index = currentStaff.findIndex(
				(item) => item.id === data.update_staff_by_pk.id
			);

			if (index !== -1) {
				const removedQualIds = data.delete_staffqualifications.returning.map(
					(q) => q.referraltypeid
				);
				const addedQualIds = data.insert_staffqualifications.returning.map(
					(q) => q.referraltypeid
				);

				currentStaff.splice(index, 1, {
					...data.update_staff_by_pk,
					qualifications: currentStaff[index].qualifications
						.filter((q) => !removedQualIds.includes(q))
						.concat(addedQualIds),
				} as Staff);
				setStaff(currentStaff);
			}
		},
	});

	const [createStaffQuery] = useMutation<{
		insertstaffwithqualification: [{ staffid: number }];
	}>(STAFF_CREATE);

	const [deleteStaffQuery] = useMutation<{
		delete_staff_by_pk: { id: number };
	}>(STAFF_DELETE, {
		onCompleted: (data) =>
			setStaff(staff.filter((s) => s.id !== data.delete_staff_by_pk.id)),
	});

	const [getWorkWeeks] = useLazyQuery<{ workschedule: Array<staffworkData> }>(
		GET_WORK_SCHEDULE
	);

	const CallGetWorkWeek = async (staffId: number, from: Date, to: Date) => {
		const result = await getWorkWeeks({
			variables: {
				staffid: staffId,
				from: from,
				to: to,
			},
		});

		return result.data !== undefined
			? result.data.workschedule.map((date) => ({
					start: new Date(date.start),
					end: new Date(date.end),
			  }))
			: [];
	};

	return (
		<PageBox
			pageTitle={t('staff view')}
			rightContent={
				<Button variant="outlined" onClick={() => setAddUserDialogOpen(true)}>
					{t('add staff')}
				</Button>
			}
		>
			<AddUserDialog
				open={addUserDialogOpen}
				setOpen={(open) => setAddUserDialogOpen(open)}
				addUser={(newStaff) =>
					createStaffQuery({
						variables: {
							name: newStaff.name,
							abbrev: newStaff.abbrev,
							professionid: newStaff.professionid,
							service: newStaff.service,

							// Reason for string https://stackoverflow.com/a/71442250
							qualifications: newStaff.qualifications.reduce<string>(
								(acc, cur, index, arr) =>
									`${acc}${cur}${index < arr.length - 1 ? ',' : '}'}`,
								'{'
							),
						},
						onCompleted: (data) => {
							if (data.insertstaffwithqualification.length > 0) {
								const createdStaff = { ...newStaff };
								createdStaff.id = data.insertstaffwithqualification[0].staffid;
								setStaff([...staff, createdStaff]);
							}
						},
					})
				}
				professionOptions={professions}
				qualificationOptions={referralTypes}
				translationBase={`${props.translationBase}.staff`}
			/>
			<Paper
				sx={{
					marginBottom: 2,
					paddingTop: 1,
					paddingLeft: 1,
					paddingRight: 1,
				}}
			>
				<QualificationsSelect
					options={referralTypes.map((r) => r.abbrev)}
					values={
						referralsSelected
							.map((rs) => referralTypes.find((rt) => rt.id === rs)?.abbrev)
							.filter((rs) => rs !== undefined) as string[]
					}
					translationBase={`${props.translationBase}.staff`}
					updateSelection={(newValues) => {
						setReferralsSelected(
							newValues
								.map(
									(newValue) =>
										referralTypes.find((rt) => rt.abbrev === newValue)?.id
								)
								.filter((newValue) => newValue !== undefined) as number[]
						);
					}}
				/>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<FormControl>
						<FormGroup aria-label="Profession" row>
							{professions.map((profession) => {
								return (
									<FormControlLabel
										key={profession.id}
										value={profession.title}
										control={<Checkbox />}
										checked={professionSelected.includes(profession.id)}
										onChange={() => {
											const selectedProfessionsCopy = [...professionSelected];
											const index = selectedProfessionsCopy.findIndex(
												(selectedProfession) =>
													selectedProfession === profession.id
											);
											if (index === -1)
												selectedProfessionsCopy.push(profession.id);
											else selectedProfessionsCopy.splice(index, 1);

											setProfessionSelected(selectedProfessionsCopy);
										}}
										labelPlacement="start"
										label={
											<Typography variant="body2">
												{t(`${profession.title}`)}
											</Typography>
										}
									/>
								);
							})}
						</FormGroup>
					</FormControl>
					<Search sx={{ backgroundColor: 'inherit' }}>
						<SearchIconWrapper>
							<SearchIcon />
						</SearchIconWrapper>
						<StyledInputBase
							value={searchString}
							onChange={(e) => setSearchString(e.target.value)}
							placeholder={`${t('search')}...`}
							inputProps={{ 'aria-label': 'search' }}
						/>
					</Search>
				</Box>
			</Paper>

			<TableContainer component={Paper}>
				<Table
					sx={{
						'.MuiTableCell-head': {
							backgroundColor: (t) => t.palette.primary.main,
						},
						'th.MuiTableCell-head, MuiTableSortLabel-root': {
							color: '#fff',
							textTransform: 'capitalize',
							fontWeight: 700,
						},
						'tr.MuiTableRow-root:nth-of-type(4n+1)': {
							backgroundColor: (t) =>
								t.palette.mode === 'light'
									? t.palette.grey[200]
									: t.palette.grey[700],
						},
					}}
				>
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell>id</TableCell>
							<TableCell>{t('name')}</TableCell>
							<TableCell>{t('profession')}</TableCell>
							<TableCell>{t('qualifications')}</TableCell>
							<TableCell>{t('degree of service')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{staff &&
							staff.length > 0 &&
							tableFilterFunction(
								staff,
								professionSelected,
								referralsSelected,
								searchString
							).map((item) => (
								<StaffTableRow
									key={item.id}
									professions={professions}
									staff={item}
									translationBase={`${props.translationBase}.staff`}
									qualificationOptions={referralTypes}
									getStaffSchedule={CallGetWorkWeek}
									updateStaff={(ns) => {
										const staffToUpdate = staff.find((s) => s.id === ns.id);
										if (staffToUpdate !== undefined) {
											updateStaffQuery({
												variables: {
													id: ns.id,
													name: ns.name,
													abbrev: ns.abbrev,
													professionid: ns.professionid,
													service: ns.service,
													qualificationsToRemove:
														staffToUpdate.qualifications.filter(
															(q) => !ns.qualifications.includes(q)
														),
													qualificationsToAdd: ns.qualifications
														.filter(
															(q) => !staffToUpdate.qualifications.includes(q)
														)
														.map((q) => ({
															referraltypeid: q,
															staffid: staffToUpdate.id,
														})),
												},
											});
										}
									}}
									deleteStaff={() =>
										deleteStaffQuery({ variables: { id: item.id } })
									}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</PageBox>
	);
}

export default StaffCP;

import { gql } from '@apollo/client';

export const OP_STAFF_QUERY = gql`
	{
		op_Profession {
			id
			abbrev
			title
		}
		op_Staff(order_by: { professionid: asc }) {
			abbrev
			id
			name
			service
			professionid
		}
	}
`;

export const OP_STAFF_CREATE = gql`
	mutation (
		$name: String!
		$abbrev: String!
		$professionid: Int!
		$service: Int!
	) {
		insert_op_Staff_one(
			object: {
				abbrev: $abbrev
				name: $name
				professionid: $professionid
				service: $service
			}
		) {
			abbrev
			id
			name
			service
			professionid
		}
	}
`;

export const OP_STAFF_EDIT = gql`
	mutation (
		$id: Int!
		$name: String!
		$abbrev: String!
		$professionid: Int!
		$service: Int!
	) {
		update_op_Staff_by_pk(
			pk_columns: { id: $id }
			_set: {
				name: $name
				abbrev: $abbrev
				professionid: $professionid
				service: $service
			}
		) {
			id
			name
			abbrev
			service
			professionid
		}
	}
`;

export const OP_STAFF_DELETE = gql`
	mutation ($id: Int!) {
		delete_op_Staff_by_pk(id: $id) {
			id
		}
	}
`;

import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageBox } from '../../../common/Components';
import { GetIntervalString } from '../../../common/Utility';
import { NewReferral } from './NewReferral';
import {
	ADD_REFERRAL_TYPE,
	DELETE_REFERRAL_TYPE,
	EDIT_REFERRAL_TYPE,
	REFERRAL_TYPES_QUERY,
} from './Queries';
import { DrawReferralType } from './ReferralType';
import { ReferralType, ReferalTypeQuery } from './Types';

/**
 * Converts ReferalTypeQuery to ReferralType
 * @param queryType
 * @returns ReferralType
 */
function ConvertQueryType(queryType: ReferalTypeQuery): ReferralType {
	const bmaParts = queryType.bma.split(':');
	const doctorParts = queryType.doctor.split(':');
	const interpretParts = queryType.interpret.split(':');
	return {
		id: queryType.id,
		name: queryType.name,
		abbrev: queryType.abbrev,
		bma: Number(bmaParts[0]) * 60 + Number(bmaParts[1]),
		doctor: Number(doctorParts[0]) * 60 + Number(doctorParts[1]),
		interpret: Number(interpretParts[0]) * 60 + Number(interpretParts[1]),
	} as ReferralType;
}

function ReferralCP(props: { translationBase: string }) {
	const [referrals, setReferrals] = useState<Array<ReferralType>>([]);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.referral types`,
	});

	useQuery<{ referraltype: Array<ReferalTypeQuery> }>(REFERRAL_TYPES_QUERY, {
		onCompleted: (data) => {
			setReferrals(
				data.referraltype.map((referralTypeDB) =>
					ConvertQueryType(referralTypeDB)
				)
			);
		},
		fetchPolicy: 'no-cache',
	});

	const handleAddReferral = (newReferral: ReferralType) => {
		addReferralQuery({
			variables: {
				name: newReferral.name,
				abbrev: newReferral.abbrev,
				bma: GetIntervalString(0, newReferral.bma, 0),
				doctor: GetIntervalString(0, newReferral.doctor, 0),
				interpret: GetIntervalString(0, newReferral.interpret, 0),
			},
		});
	};

	const [addReferralQuery] = useMutation<{
		insert_referraltype_one: ReferalTypeQuery;
	}>(ADD_REFERRAL_TYPE, {
		onCompleted: (data) => {
			setReferrals([
				...referrals,
				ConvertQueryType(data.insert_referraltype_one),
			]);
		},
	});

	function handleRemoveReferral(id: number) {
		deleteReferralQuery({
			variables: {
				id: id,
			},
		});
	}

	const [deleteReferralQuery] = useMutation<{
		delete_referraltype_by_pk: { id: Number };
	}>(DELETE_REFERRAL_TYPE, {
		onCompleted: (data) => {
			const currentReferrals = [...referrals];
			const index = currentReferrals.findIndex(
				(item) => item.id === data.delete_referraltype_by_pk.id
			);
			if (index !== -1) {
				currentReferrals.splice(index, 1);
				setReferrals(currentReferrals);
			}
		},
	});

	function handleEditReferral(referral: ReferralType) {
		editReferralQuery({
			variables: {
				id: referral.id,
				abbrev: referral.abbrev,
				name: referral.name,
				bma: GetIntervalString(0, referral.bma, 0),
				doctor: GetIntervalString(0, referral.doctor, 0),
				interpret: GetIntervalString(0, referral.interpret, 0),
			},
		});
	}

	const [editReferralQuery] = useMutation<{
		update_referraltype_by_pk: ReferalTypeQuery;
	}>(EDIT_REFERRAL_TYPE, {
		onCompleted: (data) => {
			const currentReferrals = [...referrals];
			const index = currentReferrals.findIndex(
				(item) => item.id === data.update_referraltype_by_pk.id
			);
			if (index !== -1) {
				currentReferrals.splice(
					index,
					1,
					ConvertQueryType(data.update_referraltype_by_pk)
				);
				setReferrals(currentReferrals);
			}
		},
	});

	return (
		<PageBox pageTitle={t('referral types')}>
			<Box>
				{referrals.map((item) => (
					<Box key={item.id} sx={{ '& div': { marginBottom: 0.5 } }}>
						<DrawReferralType
							referralType={item}
							translationBase={`${props.translationBase}.referral types`}
							handleRemoveReferral={handleRemoveReferral}
							handleEditReferral={handleEditReferral}
						/>
					</Box>
				))}
				<NewReferral
					translationBase={`${props.translationBase}.referral types`}
					handleAddReferral={handleAddReferral}
				/>
			</Box>
		</PageBox>
	);
}

export default ReferralCP;

import { Box } from '@mui/system';
import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { PropsWithChildren, useContext } from 'react';
import { TFunction } from 'react-i18next';
import { AppThemeContext } from '../../../../AppTheme';

const showTooltip = (
	dataObject: PropsWithChildren<PointTooltipProps>,
	limit: number
) => {
	const date = dataObject.point.data.xFormatted.toString().split(' ')[1]; // extract hh:mm
	const yValue = dataObject.point.data.y;
	return (
		<Box
			sx={{
				backgroundColor: '#333',
				color: '#fff',
				padding: '0.5rem',
				borderRadius: '8px',
			}}
		>
			{date} : {Number(yValue) + limit}
		</Box>
	);
};

const yValueOffsetOver = (value: number, limit: number) => {
	let newValue = 0;
	if (value > limit) newValue = value - limit;
	else if (value < limit) return null;
	return newValue;
};

const yValueOffsetUnder = (value: number, limit: number) => {
	let newValue = 0;
	if (value < limit) return value - limit;
	else if (value > limit) return null;
	return newValue;
};

const dataConversion = (
	data: {
		id: string;
		data: Array<{
			x: string;
			y: number;
		}>;
	},
	limit: number
) => {
	const result: Array<{
		id: string;
		data: Array<{ x: string; y: number | null }>;
	}> = [
		{
			id: 'over',
			data: [],
		},
		{
			id: 'under',
			data: [],
		},
	];

	data.data.forEach((item) => {
		const val = yValueOffsetOver(item.y, limit);
		if (result[0].data.length === 0) result[0].data.push({ x: item.x, y: val });
		else {
			if (result[0].data[result[0].data.length - 1].y !== val)
				result[0].data.push({ x: item.x, y: val });
		}
	});

	data.data.forEach((item) => {
		const val = yValueOffsetUnder(item.y, limit);
		if (result[1].data.length === 0) result[1].data.push({ x: item.x, y: val });
		else {
			if (val === null && result[1].data[result[1].data.length - 1].y !== null)
				result[1].data.push({
					x: item.x,
					y: result[1].data[result[1].data.length - 1].y,
				});
			if (result[1].data[result[1].data.length - 1].y !== val) {
				result[1].data.push({ x: item.x, y: val });
			}
		}
	});

	return [result[1], result[0]];
};

export const PostOpGraph = (props: {
	translationBase: string;
	palette: Array<string>;
	limit: number;
	t: TFunction;
	data: { id: string; data: Array<{ x: string; y: number }> };
}) => {
	const { mode } = useContext(AppThemeContext);
	const limit = props.limit;
	const max = Math.max(
		Math.max(...props.data.data.map((item) => item['y'])),
		limit
	);
	const tickValues: Array<number> = [];
	for (let i = -limit; i < max + 1; i++) tickValues.push(i);

	return (
		<ResponsiveLine
			data={dataConversion(props.data, limit)}
			margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
			xScale={{ type: 'time', format: '%Y-%m-%d %H:%M', precision: 'minute' }}
			xFormat="time:%Y-%m-%d %H:%M"
			yScale={{
				type: 'linear',
				min: -limit,
				max: max + 1 - limit,
				stacked: false,
				reverse: false,
			}}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: props.t('time'),
				legendOffset: 36,
				legendPosition: 'middle',
				format: '%H:%M',
			}}
			axisLeft={{
				tickValues: tickValues,
				format: (value) => `${value + limit}`,
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: props.t('frequency'),
				legendOffset: -40,
				legendPosition: 'middle',
			}}
			colors={[props.palette[0], props.palette[1]]}
			curve="stepAfter"
			pointSize={0}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabel="y"
			pointLabelYOffset={-12}
			enableArea={true}
			useMesh={true}
			markers={[
				{
					axis: 'y',
					value: 0,
					legend: props.t('max load'),
					legendPosition: 'top-right',
					lineStyle: { stroke: 'transparent' },
					textStyle: {
						fill: mode === 'light' ? '#000' : '#fff',
					},
					legendOrientation: 'horizontal',
				},
				{
					axis: 'y',
					value: 0,
					legend: props.t('below'),
					legendPosition: 'bottom-right',
					lineStyle: {
						stroke: '#999',
						strokeWidth: 2,
						strokeDasharray: '2 2',
					},
					textStyle: {
						fill: mode === 'light' ? '#000' : '#fff',
					},
					legendOrientation: 'horizontal',
				},
			]}
			tooltip={(v) => showTooltip(v, limit)}
			theme={{
				axis: {
					legend: {
						text: {
							fontSize: '12px',
							color: '#eee',
							fill: mode === 'light' ? '#000' : '#fff',
						},
					},
					ticks: {
						text: {
							fontSize: '12px',
							color: '#eee',
							fill: mode === 'light' ? '#000' : '#fff',
						},
					},
				},
				grid: { line: { stroke: mode === 'light' ? '#eee' : '#555' } },
			}}
		/>
	);
};

import { Box, SxProps, Typography } from '@mui/material';

type LegendProps = {
	items: Array<{ name: string; color: string }>;
	spaceLeft?: string;
	spaceRight?: string;
	sx?: SxProps;
};

export function Legend(props: LegendProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'right',
				marginLeft: 'auto',
				...props.sx,
			}}
		>
			{props.items.map((item, index) => (
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'min-content min-content',
						marginTop: '0.5rem',
					}}
					key={`legend${index}`}
				>
					<Box
						sx={{
							width: '0.75rem',
							height: '0.75rem',
							margin: `auto ${
								props.spaceRight === undefined ? '0.5rem' : props.spaceRight
							} auto ${
								props.spaceLeft === undefined ? '1.5rem' : props.spaceLeft
							}`,
							backgroundColor: item.color,
						}}
					/>
					<Typography sx={{ whiteSpace: 'nowrap' }}>{item.name}</Typography>
				</Box>
			))}
		</Box>
	);
}

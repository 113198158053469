import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	Box,
	Tooltip,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import Keycloak from 'keycloak-js';
import UserDialog from './Topbar/UserDialog';
import { useTranslation } from 'react-i18next';

type LinkType = {
	name: string;
	url: string;
	sublinks: Array<{
		name: string;
		url: string;
	}>;
};

export const LINKS: Array<LinkType> = [
	{
		name: 'home',
		url: '',
		sublinks: [],
	},
	{
		name: 'clinical physiology',
		url: 'clinical-physiology',
		sublinks: [
			{ name: 'referral overview', url: 'referral-overview' },
			{ name: 'planning', url: 'planning' },
			{ name: 'daily planning', url: 'daily-planning' },
			{ name: 'staff', url: 'staff' },
			{ name: 'room', url: 'room' },
			{ name: 'referral types', url: 'referral' },
			{ name: 'simulation', url: 'simulation' },
		],
	},
	{
		name: 'operation',
		url: 'surgery',
		sublinks: [
			{ name: 'waitlist', url: 'wait-list' },
			{ name: 'planning', url: 'planning' },
			{ name: 'daily planning', url: 'daily-planning' },
			{ name: 'postop', url: 'post-op' },
			{ name: 'staff', url: 'staff' },
			{ name: 'teams', url: 'teams' },
			{ name: 'operation room', url: 'room' },
			{ name: 'start overview', url: 'start-overview' },
		],
	},
];

const FirstToolbar = styled(Toolbar)(({ theme }) => ({
	margin: 'auto',
	display: 'flex',
	justifyContent: 'space-between',
	minWidth: theme.breakpoints.values.lg,

	'& div:first-of-type': {
		display: 'flex',
		gap: theme.spacing(4),
		alignItems: 'center',

		a: {
			textDecoration: 'none',

			'.MuiTypography-root': {
				color: '#fff',
			},

			'.superlink-active, .MuiTypography-root:hover': {
				textDecoration: 'underline',
				transition: '0.5s ease',
			},
		},
	},

	'& div:nth-of-type(2)': {
		display: 'flex',
		alignItems: 'center',
	},

	// Color for icons
	svg: {
		color: theme.palette.common.white,
	},
}));

const SecondToolbar = styled(Toolbar)(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'light'
			? theme.palette.background.paper
			: theme.palette.grey[700],

	'& div': {
		margin: 'auto auto 0 auto',
		display: 'flex',
		minWidth: theme.breakpoints.values.lg,
		a: {
			textDecoration: 'none',

			'.MuiTypography-root': {
				display: 'flex',
				alignItems: 'flex-end',
				justifyContent: 'center',
				height: 48,
				color: theme.palette.primary.main,
				textDecoration: 'none',
			},

			'.sublink-active, .MuiTypography-root:hover': {
				color: '#fff',
				backgroundColor: theme.palette.primary.main,
				fontWeight: 700,
				'&:hover': {
					backgroundColor: 'rgba(0, 181, 145, 0.8)',
					transition: '0.5s ease',
				},
			},
		},
	},
}));

const longestName = (index: number) =>
	LINKS[index].sublinks.reduce<number>((acc, item) => {
		return item.name.length > acc ? item.name.length : acc;
	}, 0);

function Topbar(props: {
	keycloak: Keycloak.KeycloakInstance;
	translationBase: string;
}) {
	const [userSelection, setUserSelection] = useState(0);
	const [openUserDialog, setOpenUserDialog] = useState(false);

	const location = useLocation();
	const path = location.pathname
		.substring(1, location.pathname.length)
		.split('/');
	const activeSubLinkName = path.length === 2 ? path[1] : '';
	const subLinkWidth = longestName(userSelection); // Max character length of all links

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<>
			<UserDialog
				open={openUserDialog}
				translationBase={props.translationBase}
				handleClose={setOpenUserDialog}
				keycloak={props.keycloak}
			/>
			<AppBar
				elevation={1}
				position="fixed"
				sx={{
					backgroundColor: '#055E60',
				}}
			>
				<FirstToolbar variant="dense">
					<div>
						{LINKS.map((item, index) =>
							index === 0 ? (
								<Link to="/" key={index}>
									<IconButton
										disableRipple
										aria-label="Home"
										onClick={() => setUserSelection(0)}
									>
										<HomeIcon />
									</IconButton>
								</Link>
							) : (
								<Link to="/#" key={index}>
									<Typography
										className={
											index === userSelection ? 'superlink-active' : undefined
										}
										variant="h6"
										onClick={() => setUserSelection(index)}
									>
										{t(item.name)}
									</Typography>
								</Link>
							)
						)}
					</div>
					<Box
						onClick={() => setOpenUserDialog(true)}
						sx={{ cursor: 'pointer' }}
					>
						<IconButton aria-label="user settings">
							<AccountCircleIcon />
						</IconButton>
						<Tooltip
							title={`${t('logged in as')} ${
								props.keycloak.profile?.firstName
							} ${props.keycloak.profile?.lastName}`}
						>
							<Typography variant="body2" component="div">
								{props.keycloak.profile?.username}
							</Typography>
						</Tooltip>
					</Box>
				</FirstToolbar>

				<SecondToolbar variant="dense">
					<Box>
						{LINKS[userSelection].sublinks.map((item, index) => (
							<Link
								key={index}
								to={`/${LINKS[userSelection].url}/${LINKS[userSelection].sublinks[index].url}`}
							>
								<Typography
									className={
										LINKS[userSelection].sublinks[index].url ===
										activeSubLinkName
											? 'sublink-active'
											: undefined
									}
									variant="subtitle1"
									sx={{ width: `${subLinkWidth + 2}ch` }}
								>
									{t(item.name)}
								</Typography>
							</Link>
						))}
					</Box>
				</SecondToolbar>
			</AppBar>
		</>
	);
}

export default Topbar;

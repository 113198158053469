import { ReactNode, useEffect, useRef } from 'react';
import {
	DragLayerMonitor,
	DragSourceMonitor,
	useDrag,
	useDragLayer,
	useDrop,
	XYCoord,
} from 'react-dnd';
import { Box, Paper, SxProps, Typography } from '@mui/material';
import chroma from 'chroma-js';
import { OperationPostOP, Urgency, DragOperation } from './Models';
import { getEmptyImage } from 'react-dnd-html5-backend';

function getItemStyles(
	initialOffset: XYCoord | null,
	currentOffset: XYCoord | null
) {
	if (!initialOffset || !currentOffset) return { display: 'none' };

	const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
	return { transform, WebkitTransform: transform };
}

export const CustomOperationDragLayer = () => {
	const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
		(monitor: DragLayerMonitor<DragOperation>) => ({
			item: monitor.getItem() as DragOperation,
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
			isDragging: monitor.isDragging(),
		})
	);

	if (!isDragging) return null;

	return (
		<Box
			sx={{
				...getItemStyles(initialOffset, currentOffset),
				position: 'fixed',
				pointerEvents: 'none',
				zIndex: 100000,
				left: 0,
				top: 0,
			}}
		>
			<Paper
				elevation={3}
				sx={{
					background:
						item.operation.urgency === Urgency.Urgent
							? 'repeating-linear-gradient(45deg,transparent,transparent 20px,rgba(51, 51, 51, 0.4) 0,rgba(51, 51, 51, 0.4) 40px)'
							: '',
					transition: 'transform 0.18s ease',
					transform: 'rotate(5deg)',
					overflow: 'hidden',
					opacity: isDragging ? 1 : 0,
					width: item.sourceWidth,
					height: item.sourceHeight,
					backgroundColor: item.color,
					border: '1px solid black',
					color: chroma.contrast(item.color, '#000') < 4.5 ? '#fff' : '#000',
				}}
			>
				<Typography variant="subtitle1">{item.operation.action}</Typography>
			</Paper>
		</Box>
	);
};

const OperationCard = (props: {
	op: OperationPostOP;
	handleClick: () => void;
	moveOperation: (sourceKey: string, sourceIndex: number) => void;
	vertical?: boolean;
	width?: number;
	index: number;
	containerKey: string;
	palette: Array<string>;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [{ isDragging }, drag, preview] = useDrag(
		() => ({
			type: 'operation',
			item: () => ({
				sourceIndex: props.index,
				operation: props.op,
				sourceKey: props.containerKey,
				sourceWidth: ref.current
					? ref.current.getBoundingClientRect().width
					: 0,
				sourceHeight: ref.current
					? ref.current.getBoundingClientRect().height
					: 0,
				color: props.palette[props.op.operatingUnit],
			}),
			collect: (monitor: DragSourceMonitor<DragOperation, void>) => ({
				isDragging: monitor.isDragging(),
			}),
			isDragging: (monitor: DragSourceMonitor<DragOperation, void>) =>
				monitor.getItem().operation.id === props.op.id,
		}),
		[props, ref]
	);

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [, drop] = useDrop<DragOperation, void>(
		{
			accept: 'operation',
			hover: (item: DragOperation, monitor) => {
				if (!ref.current) return;

				const dragIndex = item.sourceIndex;
				const hoverIndex = props.index;

				if (props.containerKey !== item.sourceKey) {
					props.moveOperation(item.sourceKey, dragIndex);
					item.sourceKey = props.containerKey;
					item.sourceIndex = hoverIndex;
					return;
				}

				if (dragIndex === hoverIndex) return;

				// Determine rectangle on screen
				const hoverBoundingRect = ref.current?.getBoundingClientRect();

				// Get middle
				const hoverMiddle =
					(props.vertical
						? hoverBoundingRect.bottom - hoverBoundingRect.top
						: hoverBoundingRect.right - hoverBoundingRect.left) / 2;

				// Determine mouse position
				const clientOffset = monitor.getClientOffset() as XYCoord;

				const hoverClient = props.vertical
					? clientOffset.y - hoverBoundingRect.top
					: clientOffset.x - hoverBoundingRect.left;

				if (
					(dragIndex < hoverIndex && hoverClient < hoverMiddle) ||
					(dragIndex > hoverIndex && hoverClient > hoverMiddle)
				)
					return;

				props.moveOperation(item.sourceKey, dragIndex);

				item.sourceKey = props.containerKey;
				item.sourceIndex = hoverIndex;
			},
		},
		[props]
	);

	drop(drag(ref));
	return (
		<Paper
			ref={ref}
			elevation={isDragging ? 0 : 3}
			sx={{
				...(props.vertical
					? { margin: '0.1rem', height: '40px' }
					: {
							width: props.width,
							overflow: 'hidden',
							marginRight: '10px',
					  }),
				background:
					props.op.urgency === Urgency.Urgent && !isDragging
						? 'repeating-linear-gradient(45deg,transparent,transparent 20px,rgba(51, 51, 51, 0.4) 0,rgba(51, 51, 51, 0.4) 40px)'
						: '',
				backgroundColor: isDragging
					? 'transparent'
					: props.palette[props.op.operatingUnit],
				border: isDragging ? '1px dashed #abc' : '1px solid black',
				color: isDragging
					? 'transparent'
					: chroma.contrast(props.palette[props.op.operatingUnit], '#000') < 4.5
					? '#fff'
					: '#000',
				cursor: 'move',
			}}
			onClick={props.handleClick}
		>
			<Typography variant="subtitle1">{props.op.action}</Typography>
		</Paper>
	);
};

export function OperationCardContainer(props: {
	operations: Array<OperationPostOP>;
	containerkey: string;
	palette: Array<string>;
	vertical: boolean;
	moveOperation: (
		sourceKey: string,
		sourceIndex: number,
		targetKey: string,
		targetIndex: number
	) => void;
	operationClick: (op: OperationPostOP, index: number) => void;
	sx?: SxProps;
	widthBase?: number;
	translationBase: string;
	children?: ReactNode;
}) {
	const [, drop] = useDrop(
		() => ({
			accept: 'operation',
			drop: (item: DragOperation) => {
				if (item.sourceKey !== props.containerkey)
					props.moveOperation(
						item.sourceKey,
						item.sourceIndex,
						props.containerkey,
						props.operations.length - 1
					);
			},
		}),
		[props]
	);

	return (
		<Box ref={drop} sx={{ ...props.sx }}>
			{props.operations.map((item, index) => (
				<OperationCard
					key={item.id}
					containerKey={props.containerkey}
					index={index}
					width={
						props.widthBase === undefined
							? undefined
							: item.opDuration * props.widthBase - 8
					}
					vertical={props.vertical}
					palette={props.palette}
					op={item}
					handleClick={() => props.operationClick(item, index)}
					moveOperation={(sourceKey, sourceIndex) =>
						props.moveOperation(
							sourceKey,
							sourceIndex,
							props.containerkey,
							index
						)
					}
				/>
			))}
			{props.children}
		</Box>
	);
}

import { ExpandMore } from '@mui/icons-material';
import {
	Box,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	TextField,
	InputAdornment,
	Button,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralType } from './Types';

/**
 * Component draws UI Forms (Accordion) to create new Referrals
 * @param props update callback function
 * @returns
 */
export function NewReferral(props: {
	translationBase: string;
	handleAddReferral: (newR: ReferralType) => void;
}) {
	const [newReferral, setNewReferral] = useState<ReferralType>({
		name: '',
		abbrev: '',
		bma: 15,
		doctor: 5,
		interpret: 15,
	} as ReferralType);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Box sx={{ '& div': { marginBottom: 0.5 }, marginTop: 5, opacity: 0.65 }}>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMore />} sx={{ display: 'flex' }}>
					<Typography
						variant="subtitle1"
						sx={{ fontWeight: 700, marginLeft: '1rem' }}
					>
						{t('create new referral')}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box
						component="form"
						sx={{
							display: 'grid',
							gridTemplateColumns: 'repeat(5, 1fr) min-content',
							gap: '1rem',
						}}
					>
						<TextField
							value={newReferral.name}
							label={t('name')}
							onChange={(event) =>
								setNewReferral({
									...newReferral,
									name: event.target.value,
								})
							}
						/>
						<TextField
							value={newReferral.abbrev}
							label={t('abbreviation')}
							onChange={(event) =>
								setNewReferral({
									...newReferral,
									abbrev: event.target.value,
								})
							}
						/>
						<TextField
							value={newReferral.bma}
							label={t('examination time for mls')}
							onChange={(event) =>
								setNewReferral({
									...newReferral,
									bma: Number(event.target.value),
								})
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">{t('minutes')}</InputAdornment>
								),
								inputProps: { min: 1 },
							}}
							type="number"
						/>
						<TextField
							value={newReferral.doctor}
							label={t('examination time for doctor')}
							onChange={(event) =>
								setNewReferral({
									...newReferral,
									doctor: Number(event.target.value),
								})
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">{t('minutes')}</InputAdornment>
								),
								inputProps: { min: 1 },
							}}
							type="number"
						/>
						<TextField
							value={newReferral.interpret}
							label={t('interpretation time')}
							onChange={(event) =>
								setNewReferral({
									...newReferral,
									interpret: Number(event.target.value),
								})
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">{t('minutes')}</InputAdornment>
								),
								inputProps: { min: 0 },
							}}
							type="number"
						/>
						<Button
							disabled={
								newReferral.name === '' ||
								newReferral.abbrev === '' ||
								newReferral.bma < 1 ||
								newReferral.interpret < 0
							}
							sx={{ whiteSpace: 'nowrap' }}
							onClick={() => {
								setNewReferral({
									name: '',
									abbrev: '',
									bma: 15,
									doctor: 5,
									interpret: 15,
								} as ReferralType);
								props.handleAddReferral(newReferral);
							}}
						>
							{t('create referral')}
						</Button>
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}

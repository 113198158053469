import { useTranslation } from 'react-i18next';
import { PageBox } from '../../common/Components';

function PlanningSO(props: { translationBase: string }) {
	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.planning`,
	});

	return <PageBox pageTitle={t('planning')}></PageBox>;
}

export default PlanningSO;

import { useState } from 'react';
import {
	Box,
	Tabs,
	Tab,
	Typography,
	Chip,
	Tooltip,
	TabsProps,
	styled,
	Paper,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { PageBox } from '../../../common/Components';
import { useQuery } from '@apollo/client';
import { OP_ROOM_QUERY } from './Queries';
import { IdName, OperationRoom, OP_ROOM_QUERY_TYPE } from './Types';

interface TabPanelProps {
	index: number;
	value: number;
	translationBase: string;
	room: OperationRoom;
}

/**
 * Displays info for Operating room selected
 * @param props TabPanelProps
 * @returns Element
 */
function TabPanel(props: TabPanelProps) {
	const { room, value, index } = props;

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<div role="tabpanel" hidden={value !== index} style={{ margin: '1rem' }}>
			{value === index && (
				<>
					<Typography variant="subtitle1">{t('available for')}</Typography>
					<Box sx={{ '& .MuiChip-root': { marginRight: '1rem' } }}>
						{room.types.map((type) => (
							<Chip key={type.id} label={type.name} />
						))}
					</Box>

					<Typography sx={{ marginTop: '1rem' }} variant="subtitle1">
						{t('equipment')}
					</Typography>
					<Box sx={{ '& .MuiChip-root': { marginRight: '1rem' } }}>
						{room.equipment.map((item, index) => (
							<Tooltip
								key={index}
								title={t(
									'equipment description is not yet available'
								).toString()}
							>
								<Chip label={item.name} />
							</Tooltip>
						))}
					</Box>
				</>
			)}
		</div>
	);
}

const StyledTabs = styled((props: TabsProps) => (
	<Tabs
		sx={{ borderRight: 1, borderColor: 'divider' }}
		orientation="vertical"
		{...props}
	/>
))(({ theme }) => ({
	width: '20ch',
	'& .MuiTab-textColorPrimary.Mui-selected': {
		color: theme.palette.common.white,
	},
	'& .Mui-selected': {
		backgroundColor: theme.palette.primary.main,
	},
	'& .MuiButtonBase-root': {
		alignItems: 'flex-start',
		textTransform: 'none',

		'& .MuiBox-root': {
			display: 'flex',
			marginTop: theme.spacing(1),
			gap: theme.spacing(1),
		},
	},
}));

/**
 * Main component, displays Operating Room
 * @returns
 */
function RoomSO(props: { translationBase: string }) {
	const [value, setValue] = useState<number>(0);

	const [rooms, setRooms] = useState<Array<OperationRoom>>([]);
	const [equipment, setEquipment] = useState<Array<IdName>>([]);

	useQuery<OP_ROOM_QUERY_TYPE>(OP_ROOM_QUERY, {
		onCompleted: (data) => {
			setRooms(
				data.rooms.map((room) => {
					return {
						id: room.id,
						name: room.name,
						types: room.roomTypes
							.map((type) => data.types.find((t) => t.id === type.typeId))
							.filter((type) => type !== undefined),
						equipment: room.roomEquipment
							.map((equipment) =>
								data.equipment.find((e) => e.id === equipment.equipmentid)
							)
							.filter((equipment) => equipment !== undefined),
						roomTypes: room.RoomOpRoomTypes.map((type) =>
							data.roomTypes.find((t) => t.id === type.oproomtypeid)
						).filter((type) => type !== undefined),
					} as OperationRoom;
				})
			);
			setEquipment([...data.equipment]);
		},
	});

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.operation room`,
	});

	return (
		<PageBox pageTitle={t('operation rooms')}>
			<Paper
				sx={{
					border: 1,
					borderColor: 'divider',
					flexGrow: 1,
					display: 'flex',
				}}
			>
				<StyledTabs
					value={value}
					onChange={(_e, newValue) => setValue(newValue)}
				>
					{rooms.map((room, index) => (
						<Tab
							disableRipple
							key={index}
							label={
								<>
									{room.name}
									<Box>
										{room.roomTypes.find((item) => item.name === 'Elektiv') && (
											<Chip
												label={t('elective')}
												sx={{ backgroundColor: '#00B591' }}
											/>
										)}
										{room.roomTypes.find((item) => item.name === 'Akut') && (
											<Chip
												label={t('acute')}
												sx={{ backgroundColor: '#EF522E', color: '#fff' }}
											/>
										)}
									</Box>
								</>
							}
						/>
					))}
				</StyledTabs>

				{rooms.map((room, index) => (
					<TabPanel
						translationBase={`${props.translationBase}.operation room`}
						key={index}
						value={value}
						index={index}
						room={room}
					/>
				))}
			</Paper>

			<Typography sx={{ marginTop: '2rem' }} variant="h4">
				{t('equipment')}
			</Typography>
			<Box>
				{equipment.map((item, index) => (
					<Tooltip
						key={index}
						title={t('equipment description is not yet available').toString()}
					>
						<Chip
							sx={{ marginRight: '1rem', marginBottom: '0.5rem' }}
							label={item.name}
						/>
					</Tooltip>
				))}
			</Box>
		</PageBox>
	);
}

export default RoomSO;

import { gql } from '@apollo/client';

export const ROOM_QUERY = gql`
	{
		room(order_by: { name: asc }) {
			id
			name
			abbrev
			roomequipments {
				equipment {
					abbrev
					name
				}
			}
			roomreferraltypes {
				referraltype {
					id
				}
			}
		}
		equipment(where: { _not: { roomequipments: { id: {} } } }) {
			id
			name
			abbrev
		}
		referraltype {
			id
			abbrev
			name
		}
	}
`;

export const CREATE_ROOM = gql`
	mutation ($abbrev: String!, $name: String!) {
		insert_room_one(object: { abbrev: $abbrev, name: $name }) {
			id
			name
			abbrev
		}
	}
`;

export const DELETE_ROOM = gql`
	mutation ($id: Int!) {
		delete_room_by_pk(id: $id) {
			id
		}
	}
`;

export const UPDATE_ROOM = gql`
	mutation ($id: Int!, $name: String!, $abbrev: String!) {
		update_room_by_pk(
			pk_columns: { id: $id }
			_set: { abbrev: $abbrev, name: $name }
		) {
			id
			name
			abbrev
			roomequipments {
				equipment {
					abbrev
					name
				}
			}
			roomreferraltypes {
				referraltype {
					abbrev
					name
				}
			}
		}
	}
`;

export enum Urgency {
	Elective = 0,
	Urgent = 1,
}

export const Urgencies = ['Elektiv', 'Akut'];

export enum Gender {
	Male = 0,
	Female = 1,
}

export const Genders = ['Man', 'Kvinna'];

export enum OperatingUnit {
	Gynekologi = 0,
	Kirurgi = 1,
	Ortopedi = 2,
	Urologi = 3,
	Underhall = 4,
}

export const OperatingUnits = [
	'gynecology',
	'surgery',
	'orthopedics',
	'urology',
	'maintenance',
];

export enum Location {
	PostOp = 'POST_OP',
	ICU = 'ICU',
	Home = 'HOME',
	Ward = 'WARD',
	Hek = 'HEK', // TODO: FIX?
}

export type Links = {
	source: string;
	target: string;
	value: number;
};

export class OperationPostOP {
	id: number;
	action: string;
	opDuration: number;
	urgency: Urgency;
	operatingUnit: number;
	age: number;
	asa: number;
	opStart: number;
	opEnd: number;
	postOpDuration: number;
	gender: Gender;
	name: string;
	destinationAfterOp: Location;
	destinationAfterPostOp: Location;

	constructor(
		id: number,
		action: string,
		opDuration: number,
		operatingUnit: number,
		urgency: number,
		age: number = 0,
		asa: number = 0,
		opStart: number = 0,
		opEnd: number = opStart + opDuration,
		postOpDuration: number = 180,
		gender: Gender = Gender.Female,
		name: string = 'N.N',
		destinationAfterOp: Location = Location.PostOp,
		destinationAfterPostOp: Location = Location.Home
	) {
		this.id = id;
		this.action = action;
		this.opDuration = opDuration;
		this.urgency = urgency;
		this.operatingUnit = operatingUnit;
		this.age = age;
		this.asa = asa;
		this.opStart = opStart;
		this.opEnd = opEnd;
		this.postOpDuration = postOpDuration;
		this.gender = gender;
		this.name = name;
		this.destinationAfterOp = destinationAfterOp;
		this.destinationAfterPostOp = destinationAfterPostOp;
	}
}

export type DragOperation = {
	operation: OperationPostOP;
	sourceKey: string;
	sourceIndex: number;
	sourceWidth: number;
	sourceHeight: number;
	color: string;
};

import { Box, Typography } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { GRID_GAP_SIZE } from './DailyPlanningSO';

export function Timeline(props: {
	start: number;
	end: number;
	numHours: number;
	gridGapSize: string;
}) {
	const times = [];
	for (let i = props.start; i <= props.end; i++) {
		const h = Math.floor(i);
		const m = Math.round((i % 1) * 60);
		times.push(
			`${String(h).padStart(2, '0')}:${m.toString().padStart(2, '0')}`
		);
	}

	const blockWidth = 100 / (1 + props.numHours);
	return (
		<Box
			sx={{
				mt: `-${props.gridGapSize}`,
				marginLeft: `${-100 / props.numHours / 2}%`,
				gridColumn: '2',
				whiteSpace: 'nowrap',
			}}
		>
			{times.map((t) => (
				<Box
					key={t}
					style={{
						display: 'inline-block',
						width: `${blockWidth + blockWidth / (1 + 2 * props.numHours)}%`,
						position: 'relative',
					}}
				>
					<Box
						sx={{
							position: 'absolute',
							top: 'calc(-50% + 1px)',
							left: '50%',
							borderLeft: '1px solid black',
							display: 'inline-block',
							height: '8px',
						}}
					/>
					<Typography key={t} align="center" variant="subtitle2">
						{t}
					</Typography>
				</Box>
			))}
		</Box>
	);
}

export function Marker(props: {
	duration: number;
	height: string;
	parentWidth: number;

	animating: boolean;
	setAnimating: (val: boolean) => void;
	markerPositionPercentage: number;
	setMarkerPositionPercentage: (val: number) => void;
}) {
	const [previousTime, setPreviousTime] = useState<Date | null>(null);
	const [currentTime, setCurrentTime] = useState<Date | null>(null);
	const requestRef = useRef(0);

	const ref = useRef(null);
	const animate = () => {
		setCurrentTime((cur) => {
			setPreviousTime(cur);
			return new Date();
		});
		requestRef.current = requestAnimationFrame(animate);
	};

	// automatically starts and stops animation
	useEffect(() => {
		if (props.animating && currentTime === null) {
			setCurrentTime(new Date());
			requestRef.current = requestAnimationFrame(animate);
		} else if (!props.animating || props.markerPositionPercentage === 1) {
			cancelAnimationFrame(requestRef.current);
			setPreviousTime(null);
			setCurrentTime(null);
			props.setAnimating(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.animating, props.markerPositionPercentage]);

	useEffect(() => {
		if (props.animating && previousTime !== null && currentTime !== null)
			props.setMarkerPositionPercentage(
				Math.min(
					(currentTime.getTime() - previousTime.getTime()) / props.duration +
						props.markerPositionPercentage,
					1
				)
			);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTime]);

	return (
		<Box
			sx={{
				borderTop: '1px solid black',
				marginBottom: GRID_GAP_SIZE,
				height: 0,
			}}
		>
			<Draggable
				nodeRef={ref}
				position={{
					x: props.markerPositionPercentage * props.parentWidth,
					y: 0,
				}}
				disabled
			>
				<Box
					ref={ref}
					sx={{
						display: 'inline-block',
						outline: 'black dashed 2px',
						opacity: 0.5,
						marginTop: '-100%',
						marginBottom: '20px',
						backgroundColor: 'transparent',
						height: props.height,
					}}
				/>
			</Draggable>
		</Box>
	);
}

import {
	Gender,
	OperatingUnit,
	OperationPostOP,
	Urgency,
	Location,
} from './Models';

export function initContainerData(): { [key: string]: OperationPostOP[] } {
	let id = 0;
	return {
		c1: [
			new OperationPostOP(
				id++,
				'ZZ999',
				34,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				0,
				34,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'JKA21',
				46,
				OperatingUnit.Kirurgi,
				Urgency.Elective,
				48,
				2,
				34,
				80,
				202,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				160 - 80,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				80,
				160,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'JAD61',
				81,
				OperatingUnit.Kirurgi,
				Urgency.Elective,
				77,
				2,
				160,
				241,
				278,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				358 - 241,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				241,
				358,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'JDF97',
				35,
				OperatingUnit.Kirurgi,
				Urgency.Elective,
				23,
				2,
				358,
				393,
				229,
				Gender.Male,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				622 - 393,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				393,
				622,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NCJ69',
				140,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				79,
				2,
				622,
				762,
				209,
				Gender.Male,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
		],
		c2: [
			new OperationPostOP(
				id++,
				'ZZ999',
				30,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				0,
				30,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'JAB30',
				49,
				OperatingUnit.Kirurgi,
				Urgency.Elective,
				65,
				2,
				30,
				79,
				230,
				Gender.Male,
				'N.N',
				Location.PostOp,
				Location.Home
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				135 - 79,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				79,
				135,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'TPX10',
				61,
				OperatingUnit.Kirurgi,
				Urgency.Elective,
				77,
				3,
				135,
				196,
				67,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				339 - 196,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				196,
				339,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NHJ62',
				190,
				OperatingUnit.Ortopedi,
				Urgency.Urgent,
				80,
				2,
				339,
				529,
				956,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
		],
		c3: [
			new OperationPostOP(
				id++,
				'ZZ999',
				33,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				0,
				33,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NGB49',
				86,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				74,
				3,
				33,
				119,
				130,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				200 - 119,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				119,
				200,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NFB49',
				87,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				88,
				3,
				200,
				287,
				226,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				391 - 287,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				287,
				391,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NFB49',
				84,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				74,
				2,
				391,
				475,
				238,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Ward
			),
		],
		c4: [
			new OperationPostOP(
				id++,
				'ZZ999',
				55,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				0,
				55,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NBH71',
				92,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				20,
				1,
				55,
				147,
				276,
				Gender.Male,
				'N.N',
				Location.PostOp,
				Location.Home
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				278 - 147,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				147,
				278,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NBA11',
				54,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				39,
				2,
				278,
				332,
				306,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Home
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				423 - 332,
				OperatingUnit.Underhall,
				Urgency.Elective,
				0,
				1,
				332,
				423,
				0,
				Gender.Male,
				'Paus',
				Location.Home
			),
			new OperationPostOP(
				id++,
				'NHG46',
				62,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				85,
				3,
				423,
				485,
				160,
				Gender.Female,
				'N.N',
				Location.PostOp,
				Location.Home
			),
		],
		gyn: [
			new OperationPostOP(
				id++,
				'GG121',
				120,
				OperatingUnit.Gynekologi,
				Urgency.Urgent,
				38,
				2,
				0
			),
			new OperationPostOP(
				id++,
				'GX914',
				220,
				OperatingUnit.Gynekologi,
				Urgency.Elective,
				37,
				4,
				120
			),
			new OperationPostOP(
				id++,
				'GRA12',
				120,
				OperatingUnit.Gynekologi,
				Urgency.Elective,
				28,
				4,
				128 + 220
			),
		],
		kir: [
			new OperationPostOP(
				id++,
				'KRB11',
				120,
				OperatingUnit.Kirurgi,
				Urgency.Elective,
				31,
				3
			),
			new OperationPostOP(
				id++,
				'KXX88',
				220,
				OperatingUnit.Kirurgi,
				Urgency.Urgent,
				55,
				3
			),
			new OperationPostOP(
				id++,
				'KBB81',
				120,
				OperatingUnit.Kirurgi,
				Urgency.Urgent,
				49,
				4
			),
		],
		ort: [
			new OperationPostOP(
				id++,
				'OR001',
				120,
				OperatingUnit.Ortopedi,
				Urgency.Urgent,
				33,
				3
			),
			new OperationPostOP(
				id++,
				'OR002',
				220,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				65,
				5
			),
			new OperationPostOP(
				id++,
				'OX010',
				120,
				OperatingUnit.Ortopedi,
				Urgency.Elective,
				27,
				4
			),
		],
		uro: [
			new OperationPostOP(
				id++,
				'UU022',
				110,
				OperatingUnit.Urologi,
				Urgency.Urgent,
				30,
				5
			),
			new OperationPostOP(
				id++,
				'UR102',
				210,
				OperatingUnit.Urologi,
				Urgency.Elective,
				31,
				3
			),
			new OperationPostOP(
				id++,
				'UA021',
				71,
				OperatingUnit.Urologi,
				Urgency.Elective,
				78,
				2
			),
		],
		break: [
			new OperationPostOP(
				id++,
				'ZZ999',
				60,
				OperatingUnit.Underhall,
				Urgency.Elective
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				90,
				OperatingUnit.Underhall,
				Urgency.Elective
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				120,
				OperatingUnit.Underhall,
				Urgency.Elective
			),
		],
	};
}

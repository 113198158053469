import {
	Box,
	Typography,
	SxProps,
	Theme,
	Tooltip,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Checkbox,
} from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { AppThemeContext } from '../../../../AppTheme';
import { DBReferral } from './Types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import chroma from 'chroma-js';

const BOX_WIDTH = 12;
const BOX_HEIGHT = 12;
const MAX_PER_ROW = 20;
const NUM_REFERRALS = 390;

interface ReferralSelectorProps {
	referrals: Array<DBReferral>;
	setReferrals: (referrals: Array<DBReferral>) => void;
	filterByDoctors: boolean;
	setFilterByDoctors: (val: boolean) => void;
	filterByDoctorsCheckboxDisabled: boolean;
	translationBase: string;
	sx?: SxProps<Theme>;
}

type BarType = {
	type: string;
	pr1: number;
	pr2: number;
	pr3: number;
	pr4: number;
	pr5: number;
};

const getGraphData: (referrals: Array<DBReferral>) => Array<BarType> = (
	referrals: Array<DBReferral>
) => {
	const types: { [name: string]: BarType } = {};

	for (const referral of referrals) {
		if (!Object.keys(types).some((type) => type === referral.referralType))
			types[referral.referralType] = {
				type: referral.referralType,
				pr1: 0,
				pr2: 0,
				pr3: 0,
				pr4: 0,
				pr5: 0,
			};

		if (referral.priority === 'pr1') types[referral.referralType].pr1 += 1;
		else if (referral.priority === 'pr2') types[referral.referralType].pr2 += 1;
		else if (referral.priority === 'pr3') types[referral.referralType].pr3 += 1;
		else if (referral.priority === 'pr4') types[referral.referralType].pr4 += 1;
		else if (referral.priority === 'pr5') types[referral.referralType].pr5 += 1;
	}

	return Object.values(types).sort((a, b) => {
		if (a.type < b.type) return -1;
		if (a.type > b.type) return 1;
		return 0;
	});
};

export const getColor = (palette: Array<string>, priority: string) => {
	return chroma(palette[Number(priority.substring(2)) - 1])
		.brighten(0.25)
		.hex();
};

export const getPriorityName = (priority: string) => {
	let name = '';
	if (priority === 'pr1') name = 'Inom 1 dag';
	else if (priority === 'pr2') name = 'Inom 2 dagar';
	else if (priority === 'pr3') name = 'Inom 7 dagar';
	else if (priority === 'pr4') name = 'Inom 14 dagar';
	else if (priority === 'pr5') name = 'Inom 90 dagar';
	return name;
};

export default function ReferralSelector(props: ReferralSelectorProps) {
	const { paletteColors } = useContext(AppThemeContext);
	const graphData = useMemo<Array<BarType>>(
		() => getGraphData(props.referrals),
		[props.referrals]
	);

	const [queueType, setQueueType] = useState('');

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const palette = chroma
		.scale([
			paletteColors.red.light,
			paletteColors.yellow.dark,
			paletteColors.green.light,
		])
		.mode('lch')
		.colors(5);

	useEffect(() => {
		if (queueType !== '') {
			axios
				.get<Array<DBReferral>>(
					`/simulation/klinfys/queue?queueType=${queueType}`
				)
				.then((res) => {
					props.setReferrals(
						res.data.map((item) => ({
							...item,
							deadline: new Date(item.deadline),
							incoming: new Date(item.incoming),
						}))
					);
				});
		} else {
			props.setReferrals([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queueType]);

	const excessReferrals = props.referrals.length - NUM_REFERRALS;
	return (
		<>
			<Typography variant="subtitle1">{t('referrals')}</Typography>
			<Accordion
				disableGutters
				expanded={queueType !== '' && graphData.length > 0}
				elevation={0}
				sx={{ border: '1px solid #CCC', borderRadius: '4px' }}
			>
				<AccordionSummary>
					<FormControl>
						<RadioGroup
							row={true}
							value={queueType}
							onChange={(e) => setQueueType(e.target.value)}
							defaultValue="aktuella"
							name="referral queue type"
						>
							<FormControlLabel
								value=""
								control={<Radio />}
								label={t('none').toString()}
							/>
							<FormControlLabel
								value="ORIGINAL"
								control={<Radio />}
								label={t('actual').toString()}
							/>
							<FormControlLabel
								value="DIVERSE"
								control={<Radio />}
								label={t('diverse').toString()}
							/>
						</RadioGroup>
					</FormControl>
					<Box sx={{ ml: 'auto', mt: '2px' }}>
						<Tooltip title={t('filter tooltip').toString()}>
							<Box
								sx={{
									display: 'inline',
									color: props.filterByDoctorsCheckboxDisabled
										? 'rgba(0, 0, 0, 0.26)'
										: 'inherit',
								}}
							>
								<Typography sx={{ display: 'inline' }} variant="subtitle1">
									{t('interpret filter')}
								</Typography>
								<HelpOutline
									sx={{ mt: '-3px', mr: '5px', verticalAlign: 'middle' }}
								/>
							</Box>
						</Tooltip>
						<Checkbox
							disabled={props.filterByDoctorsCheckboxDisabled}
							checked={props.filterByDoctors}
							onChange={() => props.setFilterByDoctors(!props.filterByDoctors)}
							sx={{ mt: '-2px' }}
						/>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'min-content 1fr',
						}}
					>
						<Box>
							<Typography variant="subtitle1">{t('referrals')}</Typography>
							<svg
								width={MAX_PER_ROW * 15}
								height={15 * MAX_PER_ROW}
								style={{ margin: '0.25rem' }}
							>
								{props.referrals.slice(0, NUM_REFERRALS).map((item, aIndex) => {
									const deadline = new Date(item.deadline);
									const time = deadline.toLocaleTimeString();
									return (
										<Tooltip
											key={item.id}
											placement="top"
											title={
												<>
													<Typography>{`Deadline: ${deadline.toLocaleDateString()} ${time.substring(
														0,
														time.length - 3
													)}`}</Typography>
													<Typography>{`Type: ${item.referralType}`}</Typography>
												</>
											}
										>
											<rect
												width={BOX_WIDTH}
												height={BOX_HEIGHT}
												x={(aIndex % MAX_PER_ROW) * 15}
												y={Math.floor(aIndex / MAX_PER_ROW) * 15}
												fill={getColor(palette, item.priority)}
												fillOpacity={item.id === '' ? 0.3 : 1.0}
												stroke={getColor(palette, item.priority)}
											/>
										</Tooltip>
									);
								})}
							</svg>
							<Typography>
								{t('total referrals', {
									count: props.referrals.length,
								})}
								{excessReferrals > 0
									? `, ${t('not shown referrals', {
											count: excessReferrals,
									  })}`
									: ''}
								.
							</Typography>
						</Box>
						<Box sx={{ height: 430 }}>
							<ResponsiveBar
								data={graphData}
								groupMode="grouped"
								keys={['pr1', 'pr2', 'pr3', 'pr4', 'pr5']}
								indexBy="type"
								margin={{ top: 35, right: 50, bottom: 100, left: 60 }}
								padding={0.3}
								valueScale={{ type: 'linear' }}
								colors={(bar) => getColor(palette, bar.id.toString())}
								borderColor={{
									from: 'color',
									modifiers: [['darker', 1.6]],
								}}
								axisBottom={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 45,
								}}
								axisLeft={null}
								axisRight={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: t('count'),
									legendPosition: 'middle',
									legendOffset: 40,
								}}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor={{
									from: 'color',
									modifiers: [['darker', 1.6]],
								}}
								tooltipLabel={(val) => getPriorityName(val.id.toString())}
							/>
						</Box>
					</div>
				</AccordionDetails>
			</Accordion>
		</>
	);
}

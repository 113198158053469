import { gql } from '@apollo/client';

export const GET_SIMULATION_INPUT = gql`
	{
		staff {
			id
			name
			staffqualifications {
				referraltype {
					abbrev
					bma
				}
			}
			profession {
				title
			}
		}
		room(order_by: { id: asc }) {
			id
			name
			abbrev
			roomreferraltypes {
				referraltype {
					abbrev
				}
			}
		}
		referraltype {
			abbrev
			interpret
		}
	}
`;

import { useState } from 'react';
import {
	Box,
	Paper,
	Tabs,
	Tab,
	Typography,
	Chip,
	Tooltip,
	styled,
	TabsProps,
} from '@mui/material';
import { PageBox } from '../../../common/Components';
import { useMutation, useQuery } from '@apollo/client';
import { ROOM_QUERY, CREATE_ROOM, DELETE_ROOM, UPDATE_ROOM } from './Queries';
import AddIcon from '@mui/icons-material/Add';
import { Equipment, ReferralNameAndAbbr, Room } from './Types';
import { NewRoom } from './NewRoom';
import { TabPanel } from './RoomPanel';
import { useTranslation } from 'react-i18next';

const StyledTabs = styled((props: TabsProps) => (
	<Tabs
		sx={{ borderRight: 1, borderColor: 'divider', flexShrink: 0 }}
		orientation="vertical"
		{...props}
	/>
))(({ theme }) => ({
	'&. MuiTab-root': {
		alignItems: 'flex-start',
	},
	'& .MuiTab-textColorPrimary.Mui-selected': {
		color: theme.palette.common.white,
	},
	'& .Mui-selected': {
		backgroundColor: theme.palette.primary.main,
	},
	'& .MuiButtonBase-root': {
		alignItems: 'flex-start',
		textTransform: 'none',
	},
}));

/**
 * Main component, displays Rooms and machiens
 * @returns
 */
function RoomCP(props: { translationBase: string }) {
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [rooms, setRooms] = useState<Array<Room>>([]);
	const [equipment, setEquipment] = useState<Array<Equipment>>([]);
	const [referralTypes, setReferralTypes] = useState<
		Array<ReferralNameAndAbbr>
	>([]);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.room`,
	});

	useQuery<{
		room: Array<Room>;
		equipment: Array<Equipment>;
		referraltype: Array<{
			id: number;
			abbrev: string;
			name: string;
		}>;
	}>(ROOM_QUERY, {
		onCompleted: (data) => {
			setRooms(data.room);
			setEquipment(data.equipment);
			setReferralTypes(data.referraltype);
		},
		fetchPolicy: 'no-cache',
	});

	const handleCreateRoom = (newRoom: Room) => {
		createRoomQuery({
			variables: {
				name: newRoom.name,
				abbrev: newRoom.abbrev,
			},
		});
	};

	const [createRoomQuery] = useMutation<{
		insert_room_one: Room;
	}>(CREATE_ROOM, {
		onCompleted: (data) => {
			const addedRoom = {
				...data.insert_room_one,
				roomequipments: [],
				roomreferraltypes: [],
			};
			setRooms([...rooms, addedRoom]);
		},
	});

	function handleDeleteRoom(id: number) {
		deleteRoomQuery({
			variables: {
				id: id,
			},
		});
	}

	const [deleteRoomQuery] = useMutation<{
		delete_room_by_pk: { id: Number };
	}>(DELETE_ROOM, {
		onCompleted: (data) => {
			const currentRooms = [...rooms];
			const index = currentRooms.findIndex(
				(item) => item.id === data.delete_room_by_pk.id
			);
			if (index !== -1) {
				currentRooms.splice(index, 1);
				setRooms(currentRooms);
			}
		},
	});

	function handleUpdateRoom(room: Room) {
		updateRoomQuery({
			variables: {
				id: room.id,
				abbrev: room.abbrev,
				name: room.name,
			},
		});
	}

	const [updateRoomQuery] = useMutation<{
		update_room_by_pk: Room;
	}>(UPDATE_ROOM, {
		onCompleted: (data) => {
			const currentRooms = [...rooms];
			const index = currentRooms.findIndex(
				(item) => item.id === data.update_room_by_pk.id
			);
			if (index !== -1) {
				currentRooms.splice(index, 1, data.update_room_by_pk);
				setRooms(currentRooms);
			}
		},
	});
	return (
		<PageBox pageTitle={t('room')}>
			<Paper
				sx={{
					border: 1,
					borderColor: 'divider',
					display: 'flex',
					width: '100%',
				}}
			>
				<StyledTabs
					value={selectedTab}
					onChange={(_e, value) => setSelectedTab(value)}
					sx={{
						display: 'inline-block',
						maxHeight: '600px',
						overflowY: 'auto',
					}}
				>
					{rooms.map((item) => (
						<Tab disableRipple key={item.id} label={item.name} />
					))}
					<Tab
						disableRipple
						key={rooms.length}
						label={<AddIcon sx={{ margin: 'auto' }} />}
					/>
				</StyledTabs>

				{rooms.map((item, index) => (
					<TabPanel
						key={item.id}
						selectedTab={selectedTab}
						translationBase={`${props.translationBase}.room`}
						index={index}
						room={item}
						deleteRoom={handleDeleteRoom}
						updateRoom={handleUpdateRoom}
						referrals={referralTypes}
					/>
				))}
				<NewRoom
					hidden={selectedTab !== rooms.length}
					translationBase={`${props.translationBase}.room`}
					availableEquipment={equipment}
					referrals={referralTypes}
					handleCreateRoom={handleCreateRoom}
				/>
			</Paper>

			<Typography sx={{ marginTop: '2rem' }} variant="h4">
				{t('equipment')}
			</Typography>
			<Box>
				{equipment.map((item, index) => (
					<Tooltip key={index} title="Descriptions has not yet been written">
						<Chip
							sx={{ marginRight: '1rem', marginBottom: '0.5rem' }}
							label={item.name}
						/>
					</Tooltip>
				))}
			</Box>
		</PageBox>
	);
}

export default RoomCP;

import { gql } from '@apollo/client';

export const GET_REFERRALS_ARRIVAL_AND_DEADLINE = gql`
	query ($a_from: date, $a_to: date, $d_from: date, $d_to: date) {
		arrivals: arrivalreferraldaypivotview(
			where: {
				arrivalDate: { _gte: $a_from }
				_and: { arrivalDate: { _lte: $a_to } }
			}
		) {
			date: arrivalDate
			pr1
			pr2
			pr3
			pr4
			pr5
		}
		deadlines: deadlinereferradaypivotview(
			where: {
				deadlineDate: { _gte: $d_from }
				_and: { deadlineDate: { _lte: $d_to } }
			}
		) {
			date: deadlineDate
			pr1
			pr2
			pr3
			pr4
			pr5
		}
	}
`;

export const GET_PRIORITIES = gql`
	{
		priority(order_by: { id: asc }) {
			id
			name
		}
	}
`;

import { CssBaseline, PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createContext, useMemo, useState } from 'react';

const paletteColors: PaletteColors = {
	red: { light: '#EF522E', dark: '#92140C' },
	yellow: { light: '#F1F1E6', dark: '#EEC170' },
	green: { light: '#00B591', dark: '#055E60' },
	blue: { light: '#595e66', dark: '#111d4a' },
	purple: { light: '#DB819E', dark: '#B17BAF' },
};
interface ColorVersion {
	dark: string;
	light: string;
}

interface PaletteColors {
	red: ColorVersion;
	yellow: ColorVersion;
	green: ColorVersion;
	blue: ColorVersion;
	purple: ColorVersion;
}

interface IThemeContext {
	mode: PaletteMode;
	colorMode: (mode: PaletteMode) => void;
	paletteColors: PaletteColors;
}

export const AppThemeContext = createContext<IThemeContext>({
	mode: 'light',
	colorMode: () => {},
	paletteColors: paletteColors,
});

export const AppThemeProvider = (props: { children: React.ReactNode }) => {
	const [mode, setMode] = useState<PaletteMode>('light');

	const colorMode = useMemo(() => (mode: PaletteMode) => setMode(mode), []);

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					primary: {
						main:
							mode === 'light'
								? paletteColors.green.dark
								: paletteColors.green.light,
					},
					secondary: {
						main: paletteColors.red.dark,
					},
					mode: mode,
				},
				components: {
					MuiCssBaseline: {
						styleOverrides: {
							body: {
								backgroundColor:
									mode === 'light' ? '#fbfafa' : 'rgb(66, 66, 66)',
							},
						},
					},
					MuiAutocomplete: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiChip: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiTextField: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiSelect: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiTable: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiTableCell: {
						defaultProps: {
							size: 'small',
						},
					},
				},
			}),
		[mode]
	);

	return (
		<AppThemeContext.Provider
			value={{
				mode,
				colorMode,
				paletteColors,
			}}
		>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{props.children}
			</ThemeProvider>
		</AppThemeContext.Provider>
	);
};

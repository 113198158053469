import { gql } from '@apollo/client';

export const REFERRAL_TYPES_QUERY = gql`
	{
		referraltype {
			id
			name
			abbrev
			bma
			doctor
			interpret
		}
	}
`;

export const ADD_REFERRAL_TYPE = gql`
	mutation (
		$name: String!
		$abbrev: String!
		$bma: interval!
		$doctor: interval!
		$interpret: interval!
	) {
		insert_referraltype_one(
			object: {
				abbrev: $abbrev
				bma: $bma
				doctor: $doctor
				interpret: $interpret
				name: $name
			}
		) {
			id
			name
			abbrev
			bma
			doctor
			interpret
		}
	}
`;

export const DELETE_REFERRAL_TYPE = gql`
	mutation ($id: Int!) {
		delete_referraltype_by_pk(id: $id) {
			id
		}
	}
`;

export const EDIT_REFERRAL_TYPE = gql`
	mutation (
		$id: Int!
		$name: String!
		$abbrev: String!
		$bma: interval!
		$doctor: interval!
		$interpret: interval!
	) {
		update_referraltype_by_pk(
			pk_columns: { id: $id }
			_set: {
				abbrev: $abbrev
				name: $name
				doctor: $doctor
				bma: $bma
				interpret: $interpret
			}
		) {
			id
			name
			abbrev
			bma
			doctor
			interpret
		}
	}
`;

import { gql } from '@apollo/client';

export const DAILY_PLANNING_ROOM_QUERY = gql`
	{
		room {
			id
			name
		}
		referraltype {
			abbrev
			bma
			id
		}
	}
`;

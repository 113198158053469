export function GetIntervalString(
	hours: number = 0,
	minutes: number = 0,
	seconds: number = 0
): string {
	let totalMinutes = minutes + Math.floor(seconds / 60);
	let totalHours = hours + Math.floor(totalMinutes / 60);

	totalMinutes %= 60;

	return `${totalHours}:${totalMinutes}:${seconds % 60}`;
}

export function GetIntervalInMinutes(intervall: string): number {
	const parts = intervall.split(':').map((number) => Number(number));

	if (parts.length !== 3) return 0;

	return parts[0] * 60 + parts[1] + Math.floor(parts[2] / 60);
}

export function padSingleDigit(val: number): string {
	return val < 10 ? `0${val}` : val.toString();
}

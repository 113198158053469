import {
	Box,
	TextField,
	Typography,
	Chip,
	Tooltip,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralNameAndAbbr, Room } from './Types';

export interface TabPanelProps {
	index: number;
	selectedTab: number;
	translationBase: string;
	room: Room;
	referrals: Array<ReferralNameAndAbbr>;
	deleteRoom: (id: number) => void;
	updateRoom: (updatedRoom: Room) => void;
}

export function TabPanel(props: TabPanelProps) {
	const { selectedTab, index } = props;
	const [roomCopy, setRoomCopy] = useState<Room>({ ...props.room });
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const disabled =
		roomCopy.abbrev === props.room.abbrev && roomCopy.name === props.room.name;

	return (
		<div
			role="tabpanel"
			hidden={selectedTab !== index}
			style={{
				margin: '1rem',
				height: 'inherit',
				width: 'inherit',
			}}
		>
			{selectedTab === index && props.referrals && props.referrals.length > 0 && (
				<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<Box sx={{ flex: '1' }}>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: '1fr 1fr',
								gap: '1rem',
							}}
						>
							<TextField
								label={t('name')}
								value={roomCopy.name}
								onChange={(e) =>
									setRoomCopy({ ...roomCopy, name: e.target.value })
								}
							/>
							<TextField
								label={t('abbreviation')}
								value={roomCopy.abbrev}
								onChange={(e) =>
									setRoomCopy({ ...roomCopy, abbrev: e.target.value })
								}
							/>
						</Box>
						<Typography variant="subtitle1">{t('available for')}</Typography>
						<Box
							sx={{
								'& .MuiChip-root': {
									marginRight: '1rem',
									marginBottom: '0.5rem',
								},
							}}
						>
							{roomCopy.roomreferraltypes.map((item, index) => (
								<Chip
									key={index}
									label={
										props.referrals.find(
											(referral) => referral.id === item.referraltype.id
										)?.name || ''
									}
								/>
							))}
						</Box>

						<Typography sx={{ marginTop: '1rem' }} variant="subtitle1">
							{t('equipment')}
						</Typography>
						<Box sx={{ '& .MuiChip-root': { marginRight: '1rem' } }}>
							{roomCopy.roomequipments.map(
								(item, index) =>
									item.equipment && (
										<Tooltip key={index} title={item.equipment.name}>
											<Chip label={item.equipment.abbrev} />
										</Tooltip>
									)
							)}
						</Box>
					</Box>

					<Box>
						<Box sx={{ float: 'left' }}>
							<Button
								color="primary"
								onClick={() => {
									props.updateRoom(roomCopy);
								}}
								disabled={disabled}
							>
								{t('update room')}
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setRoomCopy({ ...props.room });
								}}
								disabled={disabled}
							>
								{t('reset')}
							</Button>
						</Box>
						<Box sx={{ float: 'right' }}>
							<Button color="error" onClick={() => setDeleteDialogOpen(true)}>
								{t('remove room')}
							</Button>
						</Box>
					</Box>
				</Box>
			)}
			<Dialog
				open={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
			>
				<DialogTitle>
					{`${t('verify removal of room')}: ${props.room.name}`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('remove room text')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => setDeleteDialogOpen(false)}>
						{t('cancel')}
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							props.deleteRoom(props.room.id);
							setDeleteDialogOpen(false);
						}}
					>
						{t('remove')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

import { gql } from '@apollo/client';

export const GET_WORK_SCHEDULE = gql`
	query ($staffid: Int, $from: date, $to: date) {
		workschedule: get_staffworkschedule(
			args: { p_staffid: $staffid, p_from: $from, p_to: $to }
		) {
			end: dayend
			start: daystart
		}
	}
`;

export const STAFF_QUERY = gql`
	{
		staffqualifications {
			referraltypeid
			staffid
		}
		staff {
			id
			name
			abbrev
			service
			professionid
		}
		profession {
			id
			title
		}
		referraltype {
			id
			abbrev
		}
	}
`;

export const STAFF_CREATE = gql`
	mutation (
		$name: String!
		$abbrev: String!
		$professionid: Int!
		$service: Int!
		$qualifications: _int4
	) {
		insertstaffwithqualification(
			args: {
				name: $name
				abbrev: $abbrev
				service: $service
				professionid: $professionid
				qualifications: $qualifications
			}
		) {
			staffid
		}
	}
`;

export const STAFF_EDIT = gql`
	mutation (
		$id: Int!
		$name: String!
		$abbrev: String!
		$professionid: Int!
		$service: Int!
		$qualificationsToRemove: [Int!]!
		$qualificationsToAdd: [staffqualifications_insert_input!]!
	) {
		update_staff_by_pk(
			pk_columns: { id: $id }
			_set: {
				name: $name
				abbrev: $abbrev
				professionid: $professionid
				service: $service
			}
		) {
			id
			name
			abbrev
			service
			professionid
		}
		delete_staffqualifications(
			where: {
				referraltypeid: { _in: $qualificationsToRemove }
				staffid: { _eq: $id }
			}
		) {
			returning {
				referraltypeid
			}
		}
		insert_staffqualifications(objects: $qualificationsToAdd) {
			returning {
				referraltypeid
			}
		}
	}
`;

export const STAFF_DELETE = gql`
	mutation ($id: Int!) {
		delete_staffqualifications(where: { staffid: { _eq: $id } }) {
			affected_rows
		}
		delete_staff_by_pk(id: $id) {
			id
		}
	}
`;

import { gql } from '@apollo/client';

export const OP_ROOM_QUERY = gql`
	{
		rooms: op_Room {
			name
			id
			roomTypes: RoomOpTypes {
				typeId: optypeid
			}
			roomEquipment: RoomEquipments {
				equipmentid
			}
			RoomOpRoomTypes {
				oproomtypeid
			}
		}
		types: op_OpTypes {
			id
			name
		}
		roomTypes: op_OpRoomType {
			id
			name
		}
		equipment: op_Equipment {
			id
			name
		}
	}
`;


/**
 * Converts total number of minutes from midnight to string format hh:mm
 * @param minutes number
 * @param offset number
 */
export const minutesToHM = (minutes: number, offset: number = 8 * 60): string => {
	const h = Math.floor((minutes + offset) / 60);
	const m = Math.floor((minutes + offset) % 60);
	return (h < 10 ? `0${h}` : h.toString()) + ':' + (m < 10 ? `0${m}` : m.toString())
}
import {
	Box,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
	FormControl,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Slider,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { PageBox } from '../../../common/Components';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
	OP_STAFF_CREATE,
	OP_STAFF_DELETE,
	OP_STAFF_EDIT,
	OP_STAFF_QUERY,
} from './Queries';
import { Row } from './StaffRow';
import { useTranslation } from 'react-i18next';
import { OPStaff, OPStaffQueryType } from './Types';

function AddUserDialog(props: {
	addUser: (newStaff: OPStaff) => void;
	open: boolean;
	setOpen: (open: boolean) => void;
	professionOptions: Array<{ id: number; title: string }>;
	translationBase: string;
}) {
	const [newStaff, setNewStaff] = useState<OPStaff>({
		id: -1,
		name: '',
		abbrev: '',
		professionid: -1,
		service: 0,
	});

	useEffect(() => {
		if (props.open)
			setNewStaff({
				id: -1,
				name: '',
				abbrev: '',
				professionid: -1,
				service: 0,
			});
	}, [props.open]);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog
			open={props.open}
			onClose={() => props.setOpen(false)}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>{t('add new staff')}</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					autoFocus
					margin="dense"
					label={t('name')}
					variant="standard"
					sx={{ mb: '1rem' }}
					onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
				/>

				<TextField
					fullWidth
					margin="dense"
					label={t('abbreviation')}
					variant="standard"
					sx={{ mb: '1rem' }}
					onChange={(e) => setNewStaff({ ...newStaff, abbrev: e.target.value })}
					inputProps={{ maxLength: 8 }}
				/>

				<FormControl variant="standard" sx={{ mb: '1rem' }} fullWidth>
					<InputLabel>{t('profession')}</InputLabel>
					<Select
						onChange={(event) =>
							setNewStaff({
								...newStaff,
								professionid: event.target.value as number,
							})
						}
						value={newStaff.professionid}
					>
						<MenuItem value={-1}>{t('choose a profession')}</MenuItem>
						{props.professionOptions.map((profession) => {
							return (
								<MenuItem key={profession.id} value={profession.id}>
									{t(profession.title)}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<Typography variant="body2">{t('degree of service')}</Typography>
				<Box sx={{ mx: '0.125rem' }}>
					<Slider
						marks={[0, 25, 50, 75, 100].map((val) => ({
							value: val,
							label: `${val}%`,
						}))}
						value={newStaff.service}
						step={null}
						onChange={(_, value) => {
							if (typeof value === 'number')
								setNewStaff({ ...newStaff, service: value });
						}}
						valueLabelDisplay="auto"
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.setOpen(false)}>{t('cancel')}</Button>
				<Button
					disabled={
						newStaff.name === '' ||
						newStaff.abbrev === '' ||
						newStaff.professionid === -1
					}
					onClick={() => {
						props.addUser(newStaff);
						props.setOpen(false);
					}}
				>
					{t('add new staff')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const tableFilterFunction = (
	allStaff: Array<OPStaff>,
	professionFilter: Array<number>
) => {
	const res = allStaff.filter((staff) =>
		professionFilter.includes(staff.professionid)
	);

	return res;
};

function StaffSO(props: { translationBase: string }) {
	const [staff, setStaff] = useState<Array<OPStaff>>([]);
	const [professions, setProfessions] = useState<
		Array<{ id: number; title: string }>
	>([]);

	const [professionSelected, setProfessionSelected] = useState<Array<number>>(
		[]
	);

	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.staff`,
	});

	useQuery<OPStaffQueryType>(OP_STAFF_QUERY, {
		onCompleted: (data) => {
			setStaff(data.op_Staff);
			setProfessions(data.op_Profession);
			setProfessionSelected(
				data.op_Profession.map((profession) => profession.id)
			);
		},
		fetchPolicy: 'no-cache',
	});

	const [createStaffQuery] = useMutation<{
		insert_op_Staff_one: OPStaff;
	}>(OP_STAFF_CREATE, {
		onCompleted: (data) => {
			const addedStaff = {
				...data.insert_op_Staff_one,
			};
			setStaff([...staff, addedStaff]);
		},
	});

	const [updateStaffQuery] = useMutation<{
		update_op_Staff_by_pk: OPStaff;
	}>(OP_STAFF_EDIT, {
		onCompleted: (data) => {
			const currentStaff = [...staff];
			const index = currentStaff.findIndex(
				(item) => item.id === data.update_op_Staff_by_pk.id
			);

			if (index !== -1) {
				currentStaff.splice(index, 1, {
					id: data.update_op_Staff_by_pk.id,
					name: data.update_op_Staff_by_pk.name,
					abbrev: data.update_op_Staff_by_pk.abbrev,
					service: data.update_op_Staff_by_pk.service,
					professionid: data.update_op_Staff_by_pk.professionid,
				});
				setStaff(currentStaff);
			}
		},
	});

	const [deleteStaffQuery] = useMutation<{
		delete_op_Staff_by_pk: { id: number };
	}>(OP_STAFF_DELETE, {
		onCompleted: (data) => {
			setStaff(staff.filter((s) => s.id !== data.delete_op_Staff_by_pk.id));
		},
	});

	return (
		<PageBox
			pageTitle={t('staff view')}
			rightContent={
				<Button variant="outlined" onClick={() => setAddUserDialogOpen(true)}>
					{t('add staff')}
				</Button>
			}
		>
			<AddUserDialog
				open={addUserDialogOpen}
				setOpen={(open) => setAddUserDialogOpen(open)}
				addUser={(newStaff) =>
					createStaffQuery({
						variables: {
							name: newStaff.name,
							abbrev: newStaff.abbrev,
							professionid: newStaff.professionid,
							service: newStaff.service,
						},
					})
				}
				professionOptions={professions}
				translationBase={`${props.translationBase}.staff`}
			/>
			<Paper
				sx={{
					marginBottom: 2,
					paddingTop: 1,
					paddingLeft: 1,
					paddingRight: 1,
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<FormControl>
						<FormGroup aria-label="Profession" row>
							{professions.map((profession) => {
								return (
									<FormControlLabel
										key={profession.id}
										value={profession.id}
										control={<Checkbox />}
										checked={professionSelected.includes(profession.id)}
										onChange={() => {
											const selectedProfessionsCopy = [...professionSelected];
											const index = selectedProfessionsCopy.findIndex(
												(selectedProfession) =>
													selectedProfession === profession.id
											);
											if (index === -1)
												selectedProfessionsCopy.push(profession.id);
											else selectedProfessionsCopy.splice(index, 1);

											setProfessionSelected(selectedProfessionsCopy);
										}}
										labelPlacement="start"
										label={
											<Typography variant="body2">
												{t(profession.title)}
											</Typography>
										}
									/>
								);
							})}
						</FormGroup>
					</FormControl>
				</Box>
			</Paper>

			<TableContainer component={Paper}>
				<Table
					sx={{
						'.MuiTableCell-head': {
							backgroundColor: (t) => t.palette.primary.main,
						},
						'th.MuiTableCell-head, MuiTableSortLabel-root': {
							color: '#fff',
							textTransform: 'capitalize',
							fontWeight: 700,
						},
						'tr.MuiTableRow-root:nth-of-type(4n+1)': {
							backgroundColor: (t) =>
								t.palette.mode === 'light'
									? t.palette.grey[200]
									: t.palette.grey[700],
						},
					}}
				>
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell>id</TableCell>
							<TableCell>{t('name')}</TableCell>
							<TableCell>{t('profession')}</TableCell>
							<TableCell>{t('degree of service')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{staff &&
							staff.length > 0 &&
							tableFilterFunction(staff, professionSelected).map((item) => (
								<Row
									key={item.id}
									staff={item}
									translationBase={`${props.translationBase}.staff`}
									professionOptions={professions}
									updateStaff={(newStaff) => {
										const staffToUpdate = staff.find(
											(s) => s.id === newStaff.id
										);
										if (staffToUpdate !== undefined) {
											updateStaffQuery({
												variables: {
													id: newStaff.id,
													name: newStaff.name,
													abbrev: newStaff.abbrev,
													professionid: newStaff.professionid,
													service: newStaff.service,
												},
											});
										}
									}}
									deleteStaff={() =>
										deleteStaffQuery({
											variables: {
												id: item.id,
											},
										})
									}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</PageBox>
	);
}

export default StaffSO;

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import { Box, styled, SxProps, TextField } from '@mui/material';
import {
	getWeek,
	isWithinInterval,
	isSameDay,
	startOfWeek,
	endOfWeek,
	Locale,
} from 'date-fns';
import { useTranslation } from 'react-i18next';

type CustomPickerDayProps = PickersDayProps<Date> & {
	dayIsBetween: boolean;
	isFirstDay: boolean;
	isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) =>
		prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
	...(dayIsBetween && {
		borderRadius: 0,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.primary.dark,
		},
	}),
	...(isFirstDay && {
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%',
	}),
	...(isLastDay && {
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%',
	}),
})) as React.ComponentType<CustomPickerDayProps>;

export function WeekPicker(props: {
	locale: Locale;
	onChange: (date: Date | null) => void;
	weekDate: Date;
	sx?: SxProps;
}) {
	const { t } = useTranslation();

	const renderWeekPickerDay = (
		date: Date,
		_selectedDates: Array<Date | null>,
		pickersDayProps: PickersDayProps<Date>
	) => {
		if (!props.weekDate) {
			return <PickersDay {...pickersDayProps} />;
		}

		const start = startOfWeek(props.weekDate, { locale: props.locale });
		const end = endOfWeek(props.weekDate, { locale: props.locale });

		const dayIsBetween = isWithinInterval(date, { start, end });
		const isFirstDay = isSameDay(date, start);
		const isLastDay = isSameDay(date, end);

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disableMargin
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
			/>
		);
	};

	return (
		<Box sx={{ ...props.sx }}>
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={props.locale}>
				<DatePicker
					disableMaskedInput
					value={props.weekDate}
					onChange={props.onChange}
					renderDay={renderWeekPickerDay}
					renderInput={(params) => <TextField {...params} />}
					inputFormat={`'${t('components.weekpicker.week')} '${getWeek(
						props.weekDate
					).toString()}' ${t(
						'components.weekpicker.of'
					)} '${props.weekDate.getFullYear()}`}
				/>
			</LocalizationProvider>
		</Box>
	);
}

import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
	TableRow,
	TableCell,
	IconButton,
	Chip,
	Box,
	Typography,
	Collapse,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Slider,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OPStaff } from './Types';

function DeleteUserDialog(props: {
	open: boolean;
	setOpen: (open: boolean) => void;
	deleteUser: () => void;
	name: string;
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog
			open={props.open}
			onClose={() => props.setOpen(false)}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>{`${t('are you sure that you want to delete')} ${
				props.name
			}?`}</DialogTitle>

			<DialogActions>
				<Button onClick={() => props.setOpen(false)}>{t('cancel')}</Button>
				<Button onClick={props.deleteUser}>{t('delete')}</Button>
			</DialogActions>
		</Dialog>
	);
}

export function Row(props: {
	staff: OPStaff;
	professionOptions: Array<{ id: number; title: string }>;
	translationBase: string;
	updateStaff: (newStaff: OPStaff) => void;
	deleteStaff: () => void;
}) {
	const [open, setOpen] = useState(false);
	const [localStaff, setLocalStaff] = useState<OPStaff>(props.staff);

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const staffProfession = props.professionOptions.find(
		(p) => p.id === props.staff.professionid
	)?.title;

	return (
		<>
			<DeleteUserDialog
				open={deleteDialogOpen}
				setOpen={(open) => setDeleteDialogOpen(open)}
				deleteUser={props.deleteStaff}
				name={localStaff.name}
				translationBase={props.translationBase}
			/>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						size="small"
						onClick={() => setOpen(!open)}
						data-staffid={`staff${props.staff.id}`}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell>{props.staff.id}</TableCell>
				<TableCell>{props.staff.name}</TableCell>
				<TableCell>
					<Chip
						label={staffProfession !== undefined ? t(staffProfession) : ''}
					/>
				</TableCell>
				<TableCell>
					<Typography variant="caption">{`${props.staff.service}%`}</Typography>
					<div
						style={{
							width: props.staff.service * 0.66,
							height: 2,
							backgroundColor: 'red',
						}}
					/>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell colSpan={6} sx={{ py: 0 }}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box
							sx={{
								margin: 1,
								display: 'grid',
								columnGap: (t) => t.spacing(4),
								rowGap: (t) => t.spacing(2),
								gridTemplateColumns: '1fr 1fr',
							}}
						>
							<TextField
								fullWidth
								label={t('abbreviation')}
								variant="standard"
								value={localStaff.abbrev}
								onChange={(event) =>
									setLocalStaff({
										...localStaff,
										abbrev: event.target.value,
									})
								}
							/>
							<Box>
								<TextField
									fullWidth
									id="input-staff-name"
									label={t('name')}
									variant="standard"
									value={localStaff.name}
									onChange={(event) =>
										setLocalStaff({
											...localStaff,
											name: event.target.value,
										})
									}
								/>
							</Box>
							<Box>
								<FormControl variant="standard" fullWidth>
									<InputLabel>{t('profession')}</InputLabel>
									<Select
										onChange={(event) =>
											setLocalStaff({
												...localStaff,
												professionid: event.target.value as number,
											})
										}
										value={localStaff.professionid}
									>
										{props.professionOptions.map((profession) => (
											<MenuItem key={profession.id} value={profession.id}>
												{t(profession.title)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<Box>
								<Typography variant="body2">
									{t('degree of service')}
								</Typography>
								<Slider
									arie-label="work-slider"
									marks={[0, 25, 50, 75, 100].map((val) => ({
										value: val,
										label: `${val}%`,
									}))}
									step={null}
									value={localStaff.service}
									onChange={(_, value) => {
										if (typeof value === 'number')
											setLocalStaff({ ...localStaff, service: value });
									}}
									valueLabelDisplay="auto"
									getAriaValueText={(number) => `${number}%`}
								/>
							</Box>
							<Box
								sx={{
									gridColumn: 2,
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'flex-end',
								}}
							>
								<Button color="error" onClick={() => setDeleteDialogOpen(true)}>
									{t('delete')}
								</Button>

								<Button
									disabled={
										localStaff.name === props.staff.name &&
										localStaff.abbrev === props.staff.abbrev &&
										localStaff.service === props.staff.service &&
										localStaff.professionid === props.staff.professionid
									}
									onClick={() => setLocalStaff(props.staff)}
								>
									{t('reset')}
								</Button>
								<Button
									disabled={
										localStaff.name === props.staff.name &&
										localStaff.abbrev === props.staff.abbrev &&
										localStaff.service === props.staff.service &&
										localStaff.professionid === props.staff.professionid
									}
									onClick={() => props.updateStaff(localStaff)}
								>
									{t('update')}
								</Button>
							</Box>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

import {
	Typography,
	Box,
	TextField,
	Autocomplete,
	Button,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Equipment, ReferralNameAndAbbr, Room } from './Types';

export function NewRoom(props: {
	hidden: boolean;
	translationBase: string;
	availableEquipment: Array<Equipment>;
	referrals: Array<ReferralNameAndAbbr>;
	handleCreateRoom: (room: Room) => void;
}) {
	const [newName, setNewName] = useState('');
	const [newAbbrev, setNewAbbrev] = useState('');

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Box
			role="tabpanel"
			hidden={props.hidden}
			style={{ margin: '1rem', width: '100%' }}
		>
			{!props.hidden && (
				<>
					<Typography variant="h6">{t('create new room')}</Typography>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							gap: '1rem',
						}}
					>
						<TextField
							label={t('name')}
							value={newName}
							onChange={(e) => setNewName(e.target.value)}
						/>

						<TextField
							label={t('abbreviation')}
							value={newAbbrev}
							onChange={(e) => setNewAbbrev(e.target.value)}
						/>

						<Autocomplete
							multiple
							id="tags-outlined"
							options={props.availableEquipment}
							getOptionLabel={(equipment) => equipment.name}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField {...params} label={t('equipment')} />
							)}
						/>

						<Autocomplete
							multiple
							id="tags-outlined"
							options={props.referrals}
							getOptionLabel={(referral) => referral.name}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField {...params} label={t('available for')} />
							)}
						/>

						<Button
							color="success"
							sx={{
								marginLeft: 'auto',
								alignSelf: 'end',
								gridColumn: 2,
							}}
							onClick={() => {
								props.handleCreateRoom({
									id: 0,
									abbrev: newAbbrev,
									name: newName,
									roomequipments: [],
									roomreferraltypes: [],
								});
								setNewAbbrev('');
								setNewName('');
							}}
						>
							{t('create room')}
						</Button>

						<Box />
					</Box>
				</>
			)}
		</Box>
	);
}

import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { getOffsetTimeStr, HMToX } from '../DailyPlanningSO';
import { TeamLunch } from '../Types';

export function LunchReliefPanel(props: {
	teamColors: { [name: string]: string };
	lunchTimes: Array<TeamLunch>;
	showLunchrelief: boolean;
	translationBase: string;
	index: number;
	value: number;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.lunch times panel`,
	});

	return (
		<Box
			sx={{ background: 'white', p: '1rem', pt: '0', mx: 'auto' }}
			role="tabpanel"
			hidden={props.value !== props.index}
		>
			{props.value === props.index && (
				<TableContainer component={Paper}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Team</TableCell>
								<TableCell align="right">{t('lunch relief')}</TableCell>
								<TableCell
									align="right"
									sx={{ width: '1px', whiteSpace: 'nowrap' }}
								>
									{t('lunch times')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.lunchTimes.map((team) => (
								<TableRow
									key={team.team}
									sx={{
										'&:last-child td, &:last-child th': { border: 0 },
										// backgroundColor: team.color,
										backgroundColor: props.teamColors[team.team],
										'td.team-color': {
											color:
												chroma.contrast(props.teamColors[team.team], '#fff') >
												4.5
													? '#fff'
													: '#000',
										},
									}}
								>
									<TableCell className="team-color" scope="row">
										{team.team}
									</TableCell>
									<TableCell align="right" className="team-color">
										{HMToX(team.start) === 0 && HMToX(team.end) === 0
											? t('none')
											: `${getOffsetTimeStr(
													HMToX(team.start)
											  )} - ${getOffsetTimeStr(HMToX(team.end))}`}
									</TableCell>
									<TableCell
										className="team-color"
										align="right"
										sx={{ whiteSpace: 'nowrap' }}
									>
										{HMToX(team.start) === 0 && HMToX(team.end) === 0
											? t('none')
											: `${getOffsetTimeStr(
													HMToX(team.start)
											  )} - ${getOffsetTimeStr(HMToX(team.end))}`}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	);
}

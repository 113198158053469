import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Snackbar,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageBox } from '../../../common/Components';
import { Constraint, QueryType, Staff, Team } from './Types';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { OP_TEAMS_QUERY } from './Queries';
import { Settings } from '@mui/icons-material';
import { TeamSettingsDialog } from './TeamSettingsDialog';
import axios from 'axios';
import { StaffSettingsDialog } from './StaffSettingsDialog';
import { CustomStaffDragLayer, TeamDroppable } from './TeamDroppable';

const constraintPresets: {
	[name: string]: { [name: string]: Array<Constraint> };
} = {
	ordinarie: {
		'Team 1': [
			{ name: 'ANE_SSK', min: 0, max: 1 },
			{ name: 'OP_SSK', min: 0, max: 1 },
			{ name: 'OP_USK', min: 0, max: 1 },
		],
		'Team 2': [
			{ name: 'ANE_SSK', min: 0, max: 1 },
			{ name: 'OP_SSK', min: 0, max: 1 },
			{ name: 'OP_USK', min: 0, max: 1 },
		],
		'Team 3': [
			{ name: 'ANE_SSK', min: 0, max: 1 },
			{ name: 'OP_SSK', min: 0, max: 1 },
			{ name: 'OP_USK', min: 0, max: 1 },
		],
		'Team 4': [
			{ name: 'ANE_SSK', min: 0, max: 1 },
			{ name: 'OP_SSK', min: 0, max: 1 },
			{ name: 'OP_USK', min: 0, max: 1 },
		],
		'Team 5': [
			{ name: 'ANE_SSK', min: 0, max: 1 },
			{ name: 'OP_SSK', min: 0, max: 1 },
			{ name: 'OP_USK', min: 0, max: 1 },
		],
		Dagkirurgi: [
			{ name: 'ANE_SSK', min: 0, max: 1 },
			{ name: 'ANE_USK', min: 0, max: 1 },
		],
		Samordnare: [{ name: 'COORDINATOR', min: 0, max: 1 }],
		Korridorsteam: [{ name: 'ANE_USK', min: 0, max: 1 }],
		Sökarfunktion: [{ name: 'ANE_SSK', min: 0, max: 1 }],
	},
	ingen: {
		'Team 1': [],
		'Team 2': [],
		'Team 3': [],
		'Team 4': [],
		'Team 5': [],
		Dagkirurgi: [],
		Samordnare: [],
		Korridorsteam: [],
		Sökarfunktion: [],
	},
};

function TeamsSO(props: { translationBase: string }) {
	const [teams, setTeams] = useState<Array<Team>>([
		{
			...getEmptyStaffContainer(),
		},
	]);

	const [contraintNames, setContraintNames] = useState<Array<string>>([]);
	const [selectedPreset, setSelectedPreset] = useState('ordinarie');

	const [teamSettingsOpen, setTeamSettingsOpen] = useState(false);
	const [selectedStaff, setSelectedStaff] = useState<Staff | undefined>(
		undefined
	);

	const [fade, setFade] = useState(false);
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [leftover, setLeftover] = useState(true);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.teams`,
	});

	function getEmptyStaffContainer() {
		return {
			id: -1,
			abbrev: '',
			description: '',
			isoperating: false,
			hexcolor: '#efefef',
			staff: [],
			constraints: [],
		} as Team;
	}

	useQuery<QueryType>(OP_TEAMS_QUERY, {
		onCompleted: (res) => {
			const staffContainer = {
				...getEmptyStaffContainer(),
				staff: res.op_Staff.map((s) => ({
					id: s.id,
					name: s.name,
					profession: s.Profession.abbrev,
					teamAllowedPlacement: res.op_Team
						.filter((t) =>
							s.StaffQualificationTeams.some((st) => st.teamid === t.id)
						)
						.map((t) => t.abbrev),
				})),
			};

			axios.get<Array<string>>('/model/op-constraint-names').then((cn) => {
				setContraintNames(cn.data);
				applyPreset(selectedPreset, [
					staffContainer,
					...res.op_Team.map((t) => ({ ...t, staff: [], constraints: [] })),
				]);
			});
		},
	});

	useEffect(
		() => applyPreset(selectedPreset),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedPreset]
	);

	const onDrop = (
		staffId: number,
		sourceTeamId: number,
		destinationTeamId: number
	) => {
		setFade(false);
		setTeams((cur) => {
			const copy = [...cur];

			const source =
				sourceTeamId === -1 ? copy[0] : copy.find((t) => t.id === sourceTeamId);
			const destination =
				destinationTeamId === -1
					? copy[0]
					: copy.find((t) => t.id === destinationTeamId);

			if (source !== undefined && destination !== undefined) {
				const MovedStaff = source.staff.find((s) => s.id === staffId);
				if (MovedStaff !== undefined) {
					source.staff = source.staff.filter((s) => s.id !== staffId);

					destination.staff.push(MovedStaff);
					return copy;
				}
			}
			return cur;
		});
	};

	const swapStaff = (teamIndex: number, a: number, b: number) => {
		const copy = [...teams];

		const staffCopy = copy[teamIndex].staff[a];
		copy[teamIndex].staff[a] = copy[teamIndex].staff[b];
		copy[teamIndex].staff[b] = staffCopy;

		setTeams(copy);
	};

	const applyPreset = (preset: string, newTeams?: Array<Team>) => {
		const copy = (newTeams === undefined ? teams : newTeams).slice(1);
		for (let team of copy)
			team.constraints = [...constraintPresets[preset][team.abbrev]];
		setTeams([newTeams === undefined ? teams[0] : newTeams[0], ...copy]);
	};

	const roomTeams = teams.filter((t) => t.isoperating && t.id !== -1);
	const otherTeams = teams.filter((t) => !t.isoperating && t.id !== -1);

	return (
		<DndProvider backend={HTML5Backend}>
			<PageBox pageTitle={t('teams')}>
				<CustomStaffDragLayer />
				<Box
					sx={{
						height: 'max(700px, 75vh)',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '20% 80%',
							height: 'inherit',
						}}
					>
						<TeamDroppable
							key={teams[0].id}
							team={{ ...teams[0], abbrev: t('staff') }}
							onDrop={(staffId, sourceTeamId) =>
								onDrop(staffId, sourceTeamId, teams[0].id)
							}
							swapStaff={(a, b) => swapStaff(0, a, b)}
							setSelectedStaff={(staff) => setSelectedStaff(staff)}
							fade={fade}
							sx={{ height: 'inherit' }}
							translationBase={`${props.translationBase}.teams`}
						/>

						<Box
							sx={{
								marginLeft: '0.5rem',
								display: 'grid',
								height: 'inherit',
								gridTemplateColumns: 'repeat(4, 1fr)',
								gridTemplateRows: `repeat(${Math.ceil(
									teams.length / 4
								)}, minmax(0, 1fr))`,
								columnGap: '1rem',
							}}
						>
							{roomTeams.map((team, index) => (
								<TeamDroppable
									key={team.id}
									team={team}
									onDrop={(staffId, sourceTeamId) =>
										onDrop(staffId, sourceTeamId, team.id)
									}
									swapStaff={(a, b) => swapStaff(1 + index, a, b)}
									setSelectedStaff={(staff) => setSelectedStaff(staff)}
									fade={fade}
									sx={{ height: '100%' }}
									translationBase={`${props.translationBase}.teams`}
								/>
							))}
							{roomTeams.length % 4 !== 0 &&
								[...Array(4 - (roomTeams.length % 4))].map((_empty, i) => (
									<Box
										key={`missing-${i}`}
										sx={{ display: 'flex', flexDirection: 'column' }}
									>
										<Typography variant="h5" sx={{ mt: '0.25rem' }}>
											&nbsp;
										</Typography>
										<Box
											sx={{
												border: '1px lightgrey dashed',
												borderRadius: '4px',
												flexGrow: 1,
											}}
										/>
									</Box>
								))}
							{otherTeams.map((team, index) => (
								<TeamDroppable
									key={team.id}
									team={team}
									onDrop={(staffId, sourceTeamId) =>
										onDrop(staffId, sourceTeamId, team.id)
									}
									swapStaff={(a, b) =>
										swapStaff(1 + roomTeams.length + index, a, b)
									}
									setSelectedStaff={(staff) => setSelectedStaff(staff)}
									fade={fade}
									translationBase={`${props.translationBase}.teams`}
								/>
							))}
						</Box>
					</Box>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '4fr 1fr 4fr',
							marginTop: '2rem',
						}}
					>
						<Button
							disabled={
								loading || !teams.slice(1).some((t) => t.staff.length > 0)
							}
							sx={{ ml: 'auto' }}
							onClick={() => {
								const copy = [...teams];
								for (let team of copy.slice(1))
									copy[0].staff.push(...team.staff.splice(0));
								copy[0].staff.sort((a, b) => {
									if (a.name < b.name) return -1;
									else if (a.name > b.name) return 1;
									return 0;
								});
								setTeams(copy);
							}}
						>
							{t('reset')}
						</Button>
						<Button
							disabled={loading}
							sx={{ mx: '0.25rem' }}
							onClick={() => {
								setLoading(true);
								setFade(true);
								axios
									.post<{
										staff: Array<Staff>;
										teams: Array<{
											constraints: Array<Constraint>;
											id: string;
											staff: Array<Staff>;
										}>;
									}>(
										'/model/team-assignment' +
											(leftover ? '?leftover=Korridorsteam' : ''),
										{
											staff: teams[0].staff,
											teams: teams.slice(1).map((t) => ({
												id: t.abbrev,
												staff: t.staff,
												constraints: t.constraints,
											})),
										}
									)
									.then((res) => {
										if (res.headers['solution-type'] === '2')
											setSnackbarOpen(true);

										const staffContainer = teams[0];
										staffContainer.staff = res.data.staff;

										setLoading(false);
										setTeams([
											staffContainer,
											...res.data.teams.map((t, ti) => ({
												...teams.slice(1)[ti],
												staff: t.staff,
											})),
										]);
									});
							}}
						>
							{t('distribute')}
						</Button>
						<IconButton
							disabled={loading}
							sx={{ mr: 'auto' }}
							onClick={() => setTeamSettingsOpen(true)}
						>
							<Settings />
						</IconButton>
						{loading && (
							<CircularProgress
								sx={{ gridColumn: '1 / -1', mx: 'auto', mt: '0.5rem' }}
							/>
						)}
					</Box>
				</Box>

				<TeamSettingsDialog
					teams={teams.slice(1)}
					setTeams={(newTeams) => setTeams([teams[0], ...newTeams])}
					open={teamSettingsOpen}
					setOpen={setTeamSettingsOpen}
					selectedPreset={selectedPreset}
					setSelectedPreset={setSelectedPreset}
					constraintNames={contraintNames}
					presetNames={Object.keys(constraintPresets)}
					translationBase={`${props.translationBase}.teams`}
					leftover={leftover}
					leftoverToggle={setLeftover}
				/>

				<StaffSettingsDialog
					selectedStaff={selectedStaff}
					setSelectedStaff={setSelectedStaff}
					availableTeams={teams.slice(1).map((t) => ({
						name: t.abbrev,
						hexColor: t.hexcolor,
					}))}
					setAllowedTeams={(newAllowedTeamsforStaff) => {
						const copy = [...teams];
						for (let team of copy) {
							for (let staff of team.staff) {
								if (staff.id === newAllowedTeamsforStaff.id)
									staff.teamAllowedPlacement =
										newAllowedTeamsforStaff.teamAllowedPlacement;
							}
						}
						setTeams(copy);
					}}
					translationBase={`${props.translationBase}.teams`}
				/>

				<Snackbar
					open={snackbarOpen}
					autoHideDuration={6000}
					onClose={() => setSnackbarOpen(false)}
				>
					<Alert
						onClose={() => setSnackbarOpen(false)}
						severity="error"
						elevation={2}
						sx={{ width: '100%' }}
					>
						<Typography sx={{ fontWeight: 'bold' }}>
							{t('model infeasible')}
						</Typography>
					</Alert>
				</Snackbar>
			</PageBox>
		</DndProvider>
	);
}

export default TeamsSO;

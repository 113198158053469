import { gql } from '@apollo/client';

export const GET_ROOMS = gql`
	{
		rooms: op_Room {
			id
			name
		}
	}
`;

export const GET_START_TIMES = gql`
	query ($rooms: [Int]!, $from: timestamptz!, $to: timestamptz!) {
		startTimes: op_StartOp(
			where: { start: { _gte: $from, _lte: $to }, oproomid: { _in: $rooms } }
		) {
			id
			roomId: oproomid
			start
		}
	}
`;

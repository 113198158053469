import { useTranslation } from 'react-i18next';
import { PageBox } from '../../common/Components';

function WaitListSO(props: { translationBase: string }) {
	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.waitlist`,
	});
	return <PageBox pageTitle={t('waitlist')}></PageBox>;
}

export default WaitListSO;

import { Location } from '../PostOpSO/Models';

export enum SchedulingType {
	ELECTIVE,
	EMERGENCY,
}

export enum DrawingMode {
	ALWAYS = 'always',
	AFTER_OPERATION_START = 'after operation start',
}

export type Team = {
	id: string;
	staff: Array<{ name: string; profession: string }>;
};

export type TeamLunch = {
	team: string;
	start: string;
	end: string;
};

export type Operation = {
	name: string;
	schedulingType: SchedulingType;
	start: string;
	roomStart: string;
	roomEnd: string;
	end: string;
	destinationAfterOp: Location;
	destinationAfterPostOp: Location;
	postOpDuration: number;
	teams: Array<{ start: string; end: string; id: string; staff: Array<Team> }>;
};

export type OperationRoom = {
	name: string;
	operations: Array<Operation>;
};

export type Case = {
	name: string;
	operationRooms: Array<OperationRoom>;
};

export type OpColoring = {
	teams: Array<Team>;
	lunchTeam: Team;
	operations: Array<OperationRoom>;
	lunchTimes: Array<TeamLunch>;
};

export type HistoricalDataFetch = {
	durations: Array<{
		name: string;
		ORDuration: number;
		operatorDuration: number;
	}>;
	startingTimes: Array<{
		name: string;
		ORStart: string;
		operatorStart: string;
	}>;
};

export type HistoricalData = {
	[name: string]: {
		ORDuration: number;
		operatorDuration: number;
		ORStart: string;
		operatorStart: string;
	};
};

export type Tracks = {
	maxTracks: number;
	tracks: Array<number>;
};

import { Box, TextField } from '@mui/material';
import { PostOpGraph } from '../../PostOpSO/PostOpGraph';
import { Operation } from '../Types';
import { postOpPatients } from '../../PostOpSO/PostOpSO';
import { useContext } from 'react';
import { AppThemeContext } from '../../../../../AppTheme';
import { useTranslation } from 'react-i18next';

export function PostOPPanel(props: {
	operations: Array<Operation>;
	loadLimit: number;
	setLoadLimit: (newLimit: number) => void;
	translationBase: string;
	index: number;
	value: number;
}) {
	const { paletteColors } = useContext(AppThemeContext);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.postop panel`,
	});

	return (
		<Box
			sx={{
				background: 'white',
				p: '1rem',
				pt: '0',
				mb: '2rem',
				mx: 'auto',
				height: '260px',
			}}
			role="tabpanel"
			hidden={props.value !== props.index}
		>
			{props.value === props.index && (
				<>
					<Box sx={{ display: 'flex' }}>
						<TextField
							sx={{ mx: 'auto' }}
							label={t('number of beds')}
							value={props.loadLimit}
							type="number"
							onChange={(e) => {
								const newValue = parseInt(e.target.value);
								if (newValue >= 0) props.setLoadLimit(newValue);
							}}
						/>
					</Box>
					<PostOpGraph
						data={{
							id: 'postOp',
							data: postOpPatients(props.operations, -1),
						}}
						limit={props.loadLimit}
						translationBase={props.translationBase}
						palette={[paletteColors.green.light, paletteColors.red.light]}
						t={t}
					/>
				</>
			)}
		</Box>
	);
}

import { Fragment, useContext } from 'react';
import {
	Typography,
	Box,
	Paper,
	Chip,
	IconButton,
	Tooltip,
	Checkbox,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { TimeLine } from '../../DailyPlanningCP/DailyPlanningCP';
import { Referral, Room, ScheduleType } from '../Types';
import { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../../AppTheme';
import chroma from 'chroma-js';
import { timeStr } from '../SettingsDialog';

interface RoomCalendarProps {
	updateRooms: (arr: Array<Room>) => void;
	allRooms: Array<Room>;
	translationBase: string;
	palette: RoomCalendarPalette;
	doctorColors: { [name: string]: string };
	showDoctorInterpret: boolean;
	setShowDoctorInterpret: (newVal: boolean) => void;
	index: number;
	value: number;
}

export type RoomCalendarPalette = {
	emergency: string;
	planned: string;
	scheduled: string;
	lunch: string;
};

export const CALENDARHEIGHT: number = (16 - 7) * 60 + 60;

export function contrastColor(color: string) {
	return chroma.contrast(color, '#000') < 4.5 ? '#fff' : '#000';
}

export default function RoomCalendarPanel(props: RoomCalendarProps) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const gridWidths = props.allRooms.reduce(
		(acc) => acc + ' minmax(0, 1fr) min-content',
		'min-content'
	);

	return (
		<Box
			sx={{ marginBottom: (t) => t.spacing(2) }}
			role="tabpanel"
			hidden={props.value !== props.index}
		>
			{props.value === props.index && (
				<>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="subtitle1">{t('choose rooms')}</Typography>
						<Box sx={{ marginLeft: 1 }}>
							<Checkbox
								checked={props.showDoctorInterpret}
								onChange={() =>
									props.setShowDoctorInterpret(!props.showDoctorInterpret)
								}
								sx={{ marginTop: '-4px' }}
							/>
							<Typography sx={{ display: 'inline' }} variant="subtitle1">
								{t('show interpretation times')}
							</Typography>
						</Box>
					</Box>
					<Box sx={{ display: 'grid', gridTemplateColumns: gridWidths }}>
						{props.allRooms.map((room, rIndex) => {
							return (
								<Fragment key={room.id}>
									<Box
										sx={{
											overflow: 'hidden',
											gridRow: 1,
											gridColumn: `${rIndex * 2 + 2}`,
											display: 'flex',
										}}
									>
										<Typography noWrap={true} variant="body2">
											{room.name}
										</Typography>
										<RoomReferralTooltip referralNames={room.referralTypes} />
									</Box>
									<div></div>
								</Fragment>
							);
						})}
						<Box sx={{ gridRow: '2' }}>
							<TimeLine />
						</Box>
						{props.allRooms.map((room, rIndex) => {
							if (room.active) {
								const mBMA = room.referrals.find(
									(r) => r.timeSlot && r.timeSlot === 'MORNING'
								);
								const mBMAName = mBMA?.bma?.name;
								const aBMA = room.referrals.find(
									(r) => r.timeSlot && r.timeSlot === 'AFTERNOON'
								);
								const aBMAName = aBMA?.bma?.name;
								return (
									<Fragment key={`TimeSlot-${room.id}`}>
										<Paper
											sx={{
												position: 'relative',
												gridColumn: `${2 + rIndex * 2}`,
												height: CALENDARHEIGHT,
											}}
										>
											{room.referrals
												.filter((r) => r.schedulingType !== ScheduleType.LUNCH)
												.map((referral) => {
													return (
														<ExamBlock
															referral={referral}
															key={referral.name + room.id}
															showDoctorInterpret={props.showDoctorInterpret}
															palette={props.palette}
															color={
																referral.doctor !== undefined
																	? props.doctorColors[referral.doctor]
																	: undefined
															}
															translationBase={props.translationBase}
														/>
													);
												})}
										</Paper>
										<Box
											sx={{
												// writingMode: 'vertical-rl',
												gridColumn: `${3 + rIndex * 2}`,
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												alignItems: 'center',
												my: '3rem',
											}}
										>
											<Typography
												variant="body2"
												sx={{
													writingMode: 'vertical-rl',
												}}
											>
												{mBMAName ? mBMAName : <>&nbsp;</>}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													writingMode: 'vertical-rl',
												}}
											>
												{aBMAName ? aBMAName : <>&nbsp;</>}
											</Typography>
										</Box>
									</Fragment>
								);
							} else
								return (
									<Paper
										key={`empty-${room.id}`}
										sx={{
											gridColumn: `${2 + rIndex * 2}`,
											height: CALENDARHEIGHT,
											border: '2px dashed #ccc',
											backgroundColor: 'rgba(220, 220, 220, 0.3)',
											display: 'flex',
										}}
										elevation={0}
									>
										<AddIcon
											sx={{
												color: 'gray',
												margin: 'auto',
												padding: '10%',
												cursor: 'pointer',
												fontSize: '50px',
											}}
											onClick={() => {
												const arrCopy = [...props.allRooms];
												arrCopy[rIndex].active = true;
												arrCopy.sort(
													(a, b) => Number(b.active) - Number(a.active)
												);
												props.updateRooms(arrCopy);
											}}
										/>
									</Paper>
								);
						})}
					</Box>
				</>
			)}
		</Box>
	);
}

/**
 * Room info tooltip
 * @param props
 * @returns
 */
function RoomReferralTooltip(props: { referralNames: Array<string> }) {
	return (
		<Tooltip
			title={
				<Box>
					{props.referralNames.length > 0 ? (
						<>
							<Typography align="center">Remisstyper</Typography>

							{props.referralNames.map((name) => {
								return (
									<Chip
										key={name}
										variant="outlined"
										label={name}
										sx={{
											color: (theme) => theme.palette.common.white,
											margin: '0.1rem',
										}}
									/>
								);
							})}
						</>
					) : (
						<Typography>Rummet har inga remisstyper</Typography>
					)}
				</Box>
			}
			placement="top"
		>
			<IconButton sx={{ padding: 0 }}>
				<InfoIcon />
			</IconButton>
		</Tooltip>
	);
}

type ExamBlockProps = {
	referral: Referral;
	palette: RoomCalendarPalette;
	color?: string;
	showDoctorInterpret: boolean;
	translationBase: string;
};
/**
 * Draws a draggable Referral/Exam block, (one exam/referral)
 * @param props
 * @returns
 */
function ExamBlock(props: ExamBlockProps) {
	const [clicked, setClicked] = useState<boolean>(false);
	const { paletteColors } = useContext(AppThemeContext);
	const ref = useRef(null);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	let backgroundColor = '#fff';
	let color = '#000';
	let border = '';

	if (props.color !== undefined && props.showDoctorInterpret) {
		backgroundColor = props.color;
		border = '1px solid #000';
		if (props.referral.schedulingType === ScheduleType.EMERGENCY)
			border = `1px dashed ${paletteColors.red.dark}`;
	} else {
		if (props.referral.schedulingType === ScheduleType.EMERGENCY) {
			backgroundColor = props.palette.emergency;
			border = `1px dashed ${paletteColors.red.dark}`;
			color = contrastColor(props.palette.emergency);
		} else if (props.referral.schedulingType === ScheduleType.BOOKED) {
			backgroundColor = props.palette.planned;
			color = contrastColor(props.palette.planned);
			border = '1px solid #000';
		} else if (props.referral.schedulingType === ScheduleType.LUNCH)
			backgroundColor = props.palette.lunch;
		else if (props.referral.schedulingType === ScheduleType.SCHEDULED) {
			color = contrastColor(props.palette.scheduled);
			backgroundColor = props.palette.scheduled;
			border = '1px solid #000';
		}
	}

	return (
		<Draggable
			defaultPosition={{ x: 0, y: 30 + props.referral.start }}
			axis="y"
			nodeRef={ref}
			bounds="parent"
			onStart={() => setClicked(true)}
			onStop={() => setClicked(false)}
		>
			<Tooltip
				title={
					<Box sx={{ margin: '0.25rem' }}>
						<Typography>
							{t('type')}: {props.referral.referralType}
						</Typography>
						<Typography>
							{t('start')}: {timeStr(props.referral.start)}
						</Typography>
						<Typography>
							{t('duration')} ({t('minutes')}): {props.referral.duration}
						</Typography>
						<Typography>
							{t('slut')}:{' '}
							{timeStr(props.referral.start + props.referral.duration)}
						</Typography>
						<Typography>
							{t('MLS')}:{' '}
							{props.referral.bma === undefined ? '' : props.referral.bma.name}
						</Typography>
						<Typography>
							{t('interpreter')}:{' '}
							{props.referral.doctor === undefined ? '' : props.referral.doctor}
						</Typography>
					</Box>
				}
			>
				<Paper
					elevation={0}
					ref={ref}
					sx={{
						transition: 'background-color 0.5s, color 0.5s, border 0.5s',
						backgroundColor: backgroundColor,
						color: color,
						border: border,
						position: 'absolute',
						width: '100%',
						boxSizing: 'border-box',
						height: `${props.referral.duration}px`,
						borderRadius: '3px',
						zIndex: (t) => (clicked ? t.zIndex.appBar : 0),
					}}
				>
					<Typography
						variant="caption"
						component="div"
						sx={{
							margin: '0.15rem',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}
						align="right"
					>
						{props.referral.duration >= 20 && props.referral.name}
					</Typography>
				</Paper>
			</Tooltip>
		</Draggable>
	);
}
